<template>
   <div class="wrapper">
      <header class="navbar navbar-expand-md navbar-dark bg-cyan d-print-none">
         <div class="container-xl">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
               <span class="navbar-toggler-icon"></span>
            </button>
            <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
               <a href=".">
                  <img src="../assets/logo-long.png" width="110" height="32" alt="KKN ITG" class="navbar-brand-image">
               </a>
            </h1>
            <div class="navbar-nav flex-row order-md-last">
               <div class="nav-item dropdown">

                  <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                     aria-label="Open user menu">
                     <span class="avatar avatar-sm" v-bind:style="{ 'background-image': 'url(' + foto + ')' }"></span>
                     <div class="d-none d-xl-block ps-2">
                        <div>{{ nama }}</div>
                        <div class="mt-1 small text-muted">{{ tampil_peran }}</div>
                     </div>
                  </a>

                  <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                     <a href="#" class="dropdown-item" @click="logout">Keluar</a>
                  </div>

               </div>
            </div>
         </div>
      </header>

      <div class="navbar-expand-md">
         <div class="collapse navbar-collapse" id="navbar-menu">
            <div class="navbar navbar-light">
               <div class="container-xl">
                  <ul class="navbar-nav">

                     <li class="nav-item">
                        <router-link class="nav-link" :to="{name: 'dasbor'}">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home-2"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                 <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                                 <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                                 <rect x="10" y="12" width="4" height="4"></rect>
                              </svg>
                           </span>
                           <span class="nav-link-title">
                              Beranda
                           </span>
                        </router-link>
                     </li>

                     <li class="nav-item" v-if="['ADMIN', 'BAK'].includes(peran)">
                        <router-link class="nav-link" :to="{name: 'adm-keuangan'}">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-coins"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 14c0 1.657 2.686 3 6 3s6 -1.343 6 -3s-2.686 -3 -6 -3s-6 1.343 -6 3z" /><path d="M9 14v4c0 1.656 2.686 3 6 3s6 -1.344 6 -3v-4" /><path d="M3 6c0 1.072 1.144 2.062 3 2.598s4.144 .536 6 0c1.856 -.536 3 -1.526 3 -2.598c0 -1.072 -1.144 -2.062 -3 -2.598s-4.144 -.536 -6 0c-1.856 .536 -3 1.526 -3 2.598z" /><path d="M3 6v10c0 .888 .772 1.45 2 2" /><path d="M3 11c0 .888 .772 1.45 2 2" /></svg>
                           </span>
                           <span class="nav-link-title">
                              Administrasi Keuangan
                           </span>
                        </router-link>
                     </li>

                     <li class="nav-item" v-if="peran == 'MAHASISWA'">
                        <router-link :to="{name: 'kelompok'}" class="nav-link">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-users-group"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1" /><path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M17 10h2a2 2 0 0 1 2 2v1" /><path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M3 13v-1a2 2 0 0 1 2 -2h2" /></svg>
                           </span>
                           <span class="nav-link-title">
                              Kelompok
                           </span>
                        </router-link>
                     </li>

                     <li class="nav-item" v-if="peran == 'DOSEN' || peran == 'MAHASISWA'">
                        <router-link :to="{name: 'profil'}" class="nav-link">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                 <circle cx="12" cy="7" r="4"></circle>
                                 <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                              </svg>
                           </span>
                           <span class="nav-link-title">
                              Profil
                           </span>
                        </router-link>
                     </li>

                     <li class="nav-item" v-if="['ADMIN', 'LPPM', 'JURUSAN', 'PRODI'].includes(peran)">
                        <router-link :to="{name: 'dosen'}" class="nav-link">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                 <circle cx="12" cy="7" r="4"></circle>
                                 <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                              </svg>
                           </span>
                           <span class="nav-link-title">
                              Dosen
                           </span>
                        </router-link>
                     </li>

                     <li class="nav-item" v-if="peran == 'DOSEN' || peran == 'MAHASISWA'">
                        <router-link :to="{name: 'logbook'}" class="nav-link">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg"
                                 class="icon icon-tabler icon-tabler-history-toggle" width="24" height="24"
                                 viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                                 stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                 <path d="M12 8v4l3 3"></path>
                                 <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
                                 <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
                                 <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
                                 <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
                                 <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
                                 <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
                                 <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
                                 <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
                              </svg>
                           </span>
                           <span class="nav-link-title">
                              Log Book
                           </span>
                        </router-link>
                     </li>

                     <li class="nav-item" v-if="['ADMIN', 'LPPM', 'JURUSAN', 'PRODI', 'DOSEN'].includes(peran)">
                        <router-link :to="{name: 'peserta'}" class="nav-link">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                 <circle cx="9" cy="7" r="4"></circle>
                                 <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                                 <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                 <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                              </svg>
                           </span>
                           <span class="nav-link-title">
                              Kelompok
                           </span>
                        </router-link>
                     </li>

                     <li class="nav-item" v-if="peran == 'DOSEN'">
                        <router-link :to="{name: 'penilaian'}" class="nav-link">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                 <path
                                    d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z">
                                 </path>
                              </svg>
                           </span>
                           <span class="nav-link-title">
                              Penilaian
                           </span>
                        </router-link>
                     </li>

                     <!-- <li class="nav-item" v-if="peran == 'ADMIN'">
                        <router-link :to="{name: 'jurusan'}" class="nav-link">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-skyscraper" width="52" height="52" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                 <line x1="3" y1="21" x2="21" y2="21" />
                                 <path d="M5 21v-14l8 -4v18" />
                                 <path d="M19 21v-10l-6 -4" />
                                 <line x1="9" y1="9" x2="9" y2="9.01" />
                                 <line x1="9" y1="12" x2="9" y2="12.01" />
                                 <line x1="9" y1="15" x2="9" y2="15.01" />
                                 <line x1="9" y1="18" x2="9" y2="18.01" />
                              </svg>
                           </span>
                           <span class="nav-link-title">
                              Jurusan
                           </span>
                        </router-link>
                     </li> -->

                     <li class="nav-item" v-if="peran == 'ADMIN' || peran == 'LPPM'">
                        <router-link :to="{name: 'pengumuman'}" class="nav-link">
                           <span class="nav-link-icon d-md-none d-lg-inline-block">
                              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-info-square"
                                 width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                 fill="none" stroke-linecap="round" stroke-linejoin="round">
                                 <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                 <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                 <rect x="4" y="4" width="16" height="16" rx="2"></rect>
                                 <polyline points="11 12 12 12 12 16 13 16"></polyline>
                              </svg>
                           </span>
                           <span class="nav-link-title">
                              Pengumuman
                           </span>
                        </router-link>
                     </li>

                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>

   <ShowModal modal_id="modalGantiPassword" modal_name="Ganti Password">
      <form @submit.prevent="formPassword">
         <div class="row mb-1">
            <label for="password" class="col-form-label col-md-5">Password Baru</label>
            <div class="col-md-7">
               <input type="password" id="password" v-model="password_baru" class="form-control" required>
            </div>
         </div>
         <div class="row mb-1">
            <label for="ulangi_password" class="col-form-label col-md-5">Ualngi Password Baru</label>
            <div class="col-md-7">
               <input type="password" id="ulangi_password" v-model="ulangi_password_baru" class="form-control" required>
            </div>
         </div>

         <div class="row mt-3">
            <div class="col-md-5"></div>
            <div class="col-md-7">
               <input type="submit" value="Simpan" class="btn btn-primary">
            </div>
         </div>
      </form>
   </ShowModal>

   <ShowModal modal_id="modalGantiFoto" modal_name="Ganti Foto">
      <form @submit.prevent="formFoto">
         <div class="row mb-1">
            <label for="fileFoto" class="col-form-label col-md-5">Foto</label>
            <div class="col-md-7">
               <input type="file" id="fileFoto" class="form-control" required accept=".png, .jpg, .jpeg">
            </div>
         </div>

         <div class="row mt-3">
            <div class="col-md-5"></div>
            <div class="col-md-7">
               <input type="submit" value="Simpan" class="btn btn-primary">
            </div>
         </div>
      </form>
   </ShowModal>
</template>

<script>
   import axios from 'axios'
   import ShowModal from '@/components/modal/ShowModal.vue'

   export default {
      name: 'Navbar',
      components: {
         ShowModal
      },
      data() {
         return {
            token: sessionStorage.getItem('token'),
            peran: sessionStorage.getItem('peran'),
            nama: sessionStorage.getItem('nama'),
            foto: sessionStorage.getItem('foto'),
            tampil_peran: '-',
            password_baru: '',
            ulangi_password_baru: '',
         }
      },
      beforeMount() {
         switch (this.peran) {
            case 'MAHASISWA':
               this.tampil_peran = 'Mahasiswa'
               break;
            case 'DOSEN':
               this.tampil_peran = 'Dosen'
               break;
            case 'JURUSAN':
               this.tampil_peran = 'Jurusan'
               break;
            case 'PRODI':
               this.tampil_peran = 'Program Studi'
               break;
            default:
               this.tampil_peran = 'Admin'
         }
      },
      methods: {
         logout() {
            Swal.fire({
               icon: 'question',
               text: 'Keluar dari sistem KKN ITG ?',
               showCancelButton: true,
               cancelButtonText: 'Batal',
            }).then(res => {
               if (res.isConfirmed) {
                  // axios.post('auth/logout', {headers: {'Authorization' : `Bearer ${this.token}`}})
                  //   .then( res => {
                  //     if( res.data.status ) {
                  //       sessionStorage.setItem('token', '-')
                  //       sessionStorage.setItem('isLogin', '-')
                  //       sessionStorage.setItem('peran', '-')
                  //       window.location.reload()
                  //     }
                  //   })
                  sessionStorage.setItem('token', '-')
                  sessionStorage.setItem('isLogin', '-')
                  sessionStorage.setItem('peran', '-')
                  sessionStorage.setItem('nama', '-')
                  sessionStorage.setItem('foto', '-')
                  window.location.href = '/'
               }
            })
         },
         async formPassword() {
            if (this.password_baru != this.ulangi_password_baru) {
               this.$toast.error('Password tidak sama!')
               this.password_baru = ''
               this.ulangi_password_baru = ''
            } else {
               let formData = new FormData()

               formData.append('password', this.password_baru)

               axios.post('auth/resetPassword', formData, {
                     headers: {
                        'Authorization': `Bearer ${this.token}`
                     }
                  })
                  .then(res => {
                     this.$toast.success('Password berhasil diganti!')
                  })
            }
         },
         async formFoto() {
            let formData = new FormData()
            let file = document.querySelector('#fileFoto');

            formData.append('file', file.files[0])

            axios.post('auth/uploadFoto', formData, {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  this.$toast.success('Foto berhasil diganti!')
                  sessionStorage.setItem('foto', res.data.foto)
                  window.location.href = 'http://kkn.itg.ac.id/'
                  // window.location.href = 'http://localhost:8080/'
               })
         }
      }
   }
</script>

<style>
.nav-link.active {
   background-color: rgba(0, 0, 0, .08);
}
</style>