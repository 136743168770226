<template>
  <div class="card-footer d-flex flex-column flex-md-row align-items-center">
      <p class="m-0 text-muted">Menampilkan <span>{{ info.firstItem }}</span> sampai <span>{{ info.lastItem }}</span> dari <span>{{ info.total }}</span> data</p>
      <ul class="pagination m-0 ms-auto mt-3 mt-md-0">
          <span v-for="i in info.paginate" :key="i">
              <li class="page-item" :class="i == info.currentPage ? 'active' : ''"><a class="page-link" href="#" @click="getData(i)">{{ i }}</a></li>
          </span>
      </ul>
  </div>
</template>

<script>
export default {
  name: 'InfoTable',
  props: ['info'],
  methods: {
    getData(page) {
      this.$parent.getData(page);
    }
  }
}
</script>

<style>

</style>