<template>
  <div class="card-body" :class="warna">
    <small>{{ judul }}</small>
    <h3>{{ deskripsi }}</h3>
  </div>
</template>

<script>
  export default {
    name: 'MenuHeader',
    props: ['warna', 'judul', 'deskripsi']
  }
</script>

<style>

</style>