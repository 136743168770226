<template>
  <menu-header :warna="warna" :judul="judul" :deskripsi="deskripsi" />
  
  <div class="card">
    <BodyTable>
      <template v-slot:thead>
        <tr class="text-center">
          <th>Laporan</th>
          <th>Tanggal</th>
          <th>Bukti Kegiatan</th>
          <th>Deskripsi Kegiatan</th>
          <th>Status</th>
          <th>Aksi</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="(data, index) in lis_data" :key="index">
          <td>{{ data.judul }}</td>
          <td>{{ data.tanggal }}</td>
          <td class="text-center">
            <span v-if="data.catatan != null">
              <a :href="data.file" class="btn btn-secondary" target="_blank">Lihat</a>
            </span>
          </td>
          <td class="text-wrap">{{ data.catatan }}</td>
          <td class="text-center">
            <StatusSuccess v-if="status_validasi(data)" />
            <StatusWarning v-else-if="data.catatan != null" />
            <StatusMuted v-else />
          </td>
          <td class="text-center">
            <div class="d-flex gap-2" v-if="data.catatan != null && !status_validasi(data)">
              <button class="btn btn-success" @click="verifikasiTahap2(data.id, 1)">Terima</button>
              <button class="btn btn-danger" @click="verifikasiTahap2(data.id, 0)">Tolak</button>
            </div>
          </td>
        </tr>
      </template>
    </BodyTable>
  </div>
</template>

<script>
  import axios from 'axios'
  import Alert from '@/components/alert/Alert.vue'
  import BodyTable from '@/components/table/BodyTable.vue'
  import StatusSuccess from '@/components/status/Success.vue'
  import StatusWarning from '@/components/status/Warning.vue'
  import StatusMuted from '@/components/status/Muted.vue'

  export default {
    name: 'PesertaLaporanHarian',
    props: ['judul', 'deskripsi', 'mahasiswa', 'warna'],
    components: {
      Alert,
      BodyTable,
      StatusSuccess,
      StatusWarning,
      StatusMuted
    },
    data() {
      return {
        token: sessionStorage.getItem('token'),
        lis_data: {},
        sisa: 0,
        peran: sessionStorage.getItem('peran')
      }
    },
    created() {
      this.getData()
    },
    methods: {
      async getData() {
        await axios.get(`laporan/harian/mhs/${this.mahasiswa.nim}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.lis_data = res.data.data
            this.sisa = this.lis_data.length ? 31 - this.lis_data.length : 31;
          })
      },
      status_validasi(laporan) {
        let status = false;
        switch (this.peran) {
          case 'DOSEN':
            status = laporan.verifikasi_dosen != null;
            break;
          case 'PRODI':
            status = laporan.verifikasi_prodi != null;
            break;
          case 'LPPM':
            status = laporan.verifikasi_lppm != null;
            break;
        }

        return status;
      },
      async verifikasi(id) {
        let formData = new FormData()
        formData.append('id', id)
        
        await axios.post(`laporan/harian/verifikasi`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Laporan harian berhasil diverifikasi')
              this.getData()

              this.$emit('update-data', true);
            }
          })
      },
      async verifikasiBatal(id) {
        let formData = new FormData()
        formData.append('id', id)
        
        await axios.post(`laporan/harian/verifikasi/batal`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Laporan harian batal diverifikasi')
              this.getData()

              this.$emit('update-data', true);
            }
          })
      },
      async verifikasiTahap2(id, status) {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('status', status)
        formData.append('peran', this.peran)
        
        await axios.post(`laporan/harian/verifikasi`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Laporan harian berhasil diverifikasi')
              this.getData()

              this.$emit('update-data', true);
            }
          })
      },
    }
  }
</script>

<style>

</style>