<template>
  <AdminDasbor v-if="['ADMIN', 'LPPM', 'JURUSAN', 'PRODI'].includes(peran)" />
  <DosenDasbor v-else-if="peran == 'DOSEN'" />
  <MahasiswaDasbor v-else-if="peran == 'MAHASISWA'" />
</template>

<script>
  import AdminDasbor from '@/views/admin/DasborView.vue'
  import DosenDasbor from '@/views/dosen/DasborView.vue'
  import MahasiswaDasbor from '@/views/mahasiswa/DasborView.vue'

  export default {
    name: 'Dasbor',
    components: {
      AdminDasbor,
      DosenDasbor,
      MahasiswaDasbor
    },
    data() {
      return {
        token: sessionStorage.getItem('token'),
        peran: sessionStorage.getItem('peran')
      }
    },
  }
</script>

<style>

</style>