<template>
  <MenuHeader judul="Tahap 3" deskripsi="Laporan Mingguan" :mahasiswa="mahasiswa" :warna="warna" />
  <div class="card">
    <table-body>
      <template v-slot:thead>
        <tr class="text-center">
          <th style="width: 150px">Laporan</th>
          <th style="width: 150px">Tanggal</th>
          <th>URL Kegiatan</th>
          <th>Deskripsi Kegiatan</th>
          <th style="width: 150px">Status</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="(data, index) in lis_data" :key="index">
          <td>{{ data.judul }}</td>
          <td>{{ data.tanggal }}</td>
          <td>
            <a :href="data.url" target="_blank" class="btn btn-secondary">Lihat</a><br>{{ data.url }}
          </td>
          <td>
            {{ data.catatan }}
          </td>
          <td class="text-center">
            <StatusSuccess v-if="data.status == 'TERVERIFIKASI'" />
            <StatusWarning v-else-if="data.status == 'MENUNGGU'" />
          </td>
        </tr>

        <tr v-for="i in sisa" :key="i">
          <td>Minggu ke-{{ lis_data.length ? lis_data.length + i : i }}</td>
          <td class="text-center">-</td>
          <td class="text-center">-</td>
          <td class="text-center">-</td>
          <td class="text-center">
            <StatusMuted />
          </td>
        </tr>
      </template>
    </table-body>
  </div>
</template>

<script>
  import MenuHeader from '@/components/menu/Header.vue'
  import Alert from '@/components/alert/Alert.vue'
  import axios from 'axios'
  import StatusSuccess from '@/components/status/Success.vue'
  import StatusWarning from '@/components/status/Warning.vue'
  import StatusMuted from '@/components/status/Muted.vue'

  export default {
    name: 'LaporanMingguan',
    props: ['mahasiswa', 'warna', 'token'],
    components: {
      MenuHeader,
      Alert,
      StatusMuted,
      StatusSuccess,
      StatusWarning,
    },
    data() {
      return {
        lis_data: {},
        sisa: 0
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      async getData() {
        await axios.get(`laporan/mahasiswa/${this.mahasiswa['nim']}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.lis_data = res.data.data
            this.sisa = 4 - this.lis_data.length
          })
      },
    }
  }
</script>

<style>

</style>