<template>
    
    <div class="card">
        <div class="card-body">
            <input type="text" class="form-control mb-3" placeholder="Pencarian berdasarkan nama" v-model="filter_nama" @keyup="getData" />

            <!-- table -->
            <table class="table table-bordered table-hover w-100" id="tableDosen">
                <thead>
                    <tr>
                        <th style="width: 20px">No.</th>
                        <th>Nama</th>
                        <th>Program Studi</th>
                        <th>Status</th>
                        <th>Aksi</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(data, index) in lis_data" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ data.nama }}<br><span class="text-muted">{{ data.nim }}</span></td>
                        <td>{{ data.program_studi }}</td>
                        <td>{{ data.status }}</td>
                        <td>
                            <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalDetailMahasiswa" @click="showData(data.nim)">Detail</button>
                        </td>
                    </tr>
                </tbody>
            </table>


            <div class="modal fade" id="modalDetailMahasiswa" tabindex="-1" aria-labelledby="modalDetail" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalGantiPasswordLabel">Detail Mahasiswa</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <div class="alert alert-warning" v-if="! modal_detail">
                                Tunggu sampai data berhasil diload
                            </div>

                            <div v-else>
                                <div class="alert alert-success" v-if="mahasiswa.status == 'TERVERIFIKASI'">
                                    Formulir KKN Mahasiswa telah terverifikasi
                                </div>
            
                                <div class="alert alert-warning" v-else>
                                    Formulir KKN Mahasiswa belum diverifikasi
                                </div>
            
                                <div class="row mb-1">
                                    <label for="nim" class="col-form-label col-xl-3 col-md-4">NIM</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="text" name="nim" id="nim" class="form-control w-auto" readonly v-model="mahasiswa.nim">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="nama" class="col-form-label col-xl-3 col-md-4">Nama Lengkap</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="text" name="nama" id="nama" class="form-control" readonly v-model="mahasiswa.nama">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="tempat_lahir" class="col-form-label col-xl-3 col-md-4">Tempat Lahir</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="text" name="tempat_lahir" id="tempat_lahir" class="form-control" required v-model="mahasiswa.tempat_lahir">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="tanggal_lahir" class="col-form-label col-xl-3 col-md-4">Tanggal Lahir</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="date" name="tanggal_lahir" id="tanggal_lahir" class="form-control" required v-model="mahasiswa.tanggal_lahir">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="agama" class="col-form-label col-xl-3 col-md-4">Agama</label>
                                    <div class="col-xl-9 col-md-8">
                                        <select name="agama" id="agama" class="form-select" v-model="mahasiswa.agama">
                                            <option v-for="(agama, index) of lis_agama" :key="index">{{ agama }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="jenis_kelamin" class="col-form-label col-xl-3 col-md-4">Jenis Kelamin</label>
                                    <div class="col-xl-9 col-md-8">
                                        <select id="jenis_kelamin" class="form-select" v-model="mahasiswa.jenis_kelamin">
                                            <option v-for="(kelamin, index) of lis_kelamin" :key="index">{{ kelamin }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="prodi" class="col-form-label col-xl-3 col-md-4">Program Studi</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="text" id="prodi" class="form-control" readonly v-model="mahasiswa.program_studi">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="semester" class="col-form-label col-xl-3 col-md-4">Semester</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="text" name="semester" id="semester" class="form-control w-auto" readonly v-model="mahasiswa.semester">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="ipk" class="col-form-label col-xl-3 col-md-4">IPK</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="text" name="ipk" id="ipk" class="form-control w-auto" readonly v-model="mahasiswa.ipk">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="no_hp" class="col-form-label col-xl-3 col-md-4">No. HP / WA</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="text" name="no_hp" id="no_hp" class="form-control" v-model="mahasiswa.no_hp">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="email" class="col-form-label col-xl-3 col-md-4">Email</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="email" id="email" class="form-control" readonly v-model="mahasiswa.email">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="alamat" class="col-form-label col-xl-3 col-md-4">Alamat</label>
                                    <div class="col-xl-9 col-md-8">
                                        <textarea id="alamat" rows="3" class="form-control" v-model="mahasiswa.alamat"></textarea>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <label for="ukuran_kameja" class="col-form-label col-xl-3 col-md-4">Ukuran Kameja</label>
                                    <div class="col-xl-9 col-md-8">
                                        <select name="ukuran_kameja" id="ukuran_kameja" class="form-select" v-model="mahasiswa.ukuran_kameja">
                                            <option v-for="(ukuran, index) of lis_ukuran" :key="index">{{ ukuran }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label for="sks" class="col-form-label col-xl-3 col-md-4">Jumlah SKS</label>
                                    <div class="col-xl-9 col-md-8">
                                        <input type="text" name="sks" id="sks" class="form-control w-auto" readonly v-model="mahasiswa.sks">
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label class="col-form-label col-xl-3 col-md-4">Formulir</label>
                                    <div class="col-xl-9 col-md-8">
                                        <div class="mb-2">
                                            File 1 :
                                            <br> 
                                            <a :href="mahasiswa.file_1" target="_blank" class="btn btn-secondary" v-if="mahasiswa.file_1">Lihat</a>
                                        </div>
                                        <div class="mb-2">
                                            File 2 : 
                                            <br>
                                            <a :href="mahasiswa.file_2" target="_blank" class="btn btn-secondary" v-if="mahasiswa.file_2">Lihat</a>
                                        </div>
                                        <div class="mb-2">
                                            File 3 : 
                                            <br>
                                            <a :href="mahasiswa.file_3" target="_blank" class="btn btn-secondary" v-if="mahasiswa.file_3">Lihat</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label class="col-form-label col-xl-3 col-md-4">Verifikasi</label>
                                    <div class="col-xl-9 col-md-8">
                                        <button class="btn btn-success" @click="verifikasi">Verifikasi Dokumen</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import axios from 'axios'

    export default {
        name: 'Mahasiswa',
        data() {
            return {
                token        : sessionStorage.getItem('token'),
                lis_data     : [],
                lis_status   : ['TERVERIFIKASI', 'MENUNGGU'],
                lis_agama    : ['Islam', 'Kristen'],
                lis_kelamin  : ['L', 'P'],
                lis_ukuran   : ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
                filter_status: '',
                filter_nama  : '',
                modal_detail : false,
                mahasiswa    : {
                    nim          : '',
                    nama         : '',
                    tempat_lahir : '',
                    tanggal_lahir: '',
                    agama        : '',
                    jenis_kelamin: '',
                    program_studi: '',
                    semester     : '',
                    ipk          : '',
                    no_hp        : '',
                    email        : '',
                    alamat       : '',
                    ukuran_kameja: '',
                    sks          : '',
                },
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            async getData() {
                await axios.get(`mahasiswa?nama=${this.filter_nama}`, {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        this.lis_data = res.data.data;
                    }) 
            },
            async showData(nim) {
                this.modal_detail = false
                await axios.get(`mahasiswa/show/${nim}`, {headers: {'Authorization' : `Bearer ${this.token}`}})
                    .then( res => {
                        setTimeout(() => {
                            this.mahasiswa        = res.data.data
                            this.modal_detail = true
                        }, 1000);
                    })
            },
            async verifikasi() {
                let formData = new FormData()
                formData.append('verifikasi', 'LPPM')

                await axios.post(`verifikasi/mahasiswa/${this.mahasiswa.id_kkn}`, formData, {headers: {'Authorization' : `Bearer ${this.token}`}})
                    .then( res => {
                        if( res.data.status ) {
                            Swal.fire({
                                icon : 'success',
                                text : 'Formulir Mahasiswa berhasil di verifikasi',
                                timer: 5000
                            })
                        }
                    })
            }
        }
    }
</script>

<style>

</style>