<template>
   <div class="alert alert-info" v-if="loading">
      Tunggu sampa data berhasil diload
   </div>

   <div v-else>
      <h3>Dosen Pembimbing Lapangan</h3>
      <div class="row">
         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-blue" judul="Formulir" :jumlah="jumlah_1" :total="total_dosen"
               :persen="(jumlah_1 / total_dosen) * 100">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail1">Detail</a>
            </infografis-rekapan>
         </div>
   
         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-purple" judul="Kunjungan" :jumlah="jumlah_2" :total="total_dosen"
               :persen="(jumlah_2 / total_dosen) * 100">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail2">Detail</a>
            </infografis-rekapan>
         </div>
      </div>
   
      <h3 class="mt-2">Peserta KKN</h3>
      <div class="row">
         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-blue" judul="Formulir, Proker & Survei" :jumlah="jumlah_3" :total="total_peserta"
               :persen="(jumlah_3 / total_peserta) * 100" :data="lis_data1">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail3">Detail</a>
            </infografis-rekapan>
         </div>
   
         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-purple" judul="Laporan Mingguan" :jumlah="jumlah_4" :total="total_peserta"
               :persen="(jumlah_4 / total_peserta) * 100" :data="lis_data2">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail4">Detail</a>
            </infografis-rekapan>
         </div>
   
         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-pink" judul="Laporan Akhir" :jumlah="jumlah_5" :total="total_peserta"
               :persen="(jumlah_5 / total_peserta) * 100" :data="lis_data3">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail5">Detail</a>
            </infografis-rekapan>
         </div>
   
         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-red" judul="Penilaian" :jumlah="jumlah_6" :total="total_peserta"
               :persen="(jumlah_6 / total_peserta) * 100" :data="lis_data4">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail6">Detail</a>
            </infografis-rekapan>
         </div>
      </div>
   </div>

   <modal-show 
      modal_id="detail1" 
      modal_name="Detail Formulir" 
      modal_size="modal-lg modal-dialog-scrollable">

      <Detail1ViewVue :data="lis_data_dosen" />
   </modal-show>

   <modal-show 
      modal_id="detail2" 
      modal_name="Detail Kunjungan Dosen" 
      modal_size="modal-lg modal-dialog-scrollable">

      <Detail2ViewVue :data="lis_data_dosen" />
   </modal-show>

   <modal-show
      modal_id="detail3"
      modal_name="Detail Formulir, Proker & Survei Mahasiswa"
      modal_size="modal-lg modal-dialog-scrollable">

      <Detail3ViewVue :data="lis_data_mahasiswa" />
   </modal-show>

   <modal-show
      modal_id="detail4"
      modal_name="Detail Laporan Mingguan"
      modal_size="modal-lg modal-dialog-scrollable">

      <Detail4ViewVue :data="lis_data_mahasiswa" />
   </modal-show>

   <modal-show
      modal_id="detail5"
      modal_name="Detail Laporan Akhir"
      modal_size="modal-lg modal-dialog-scrollable">

      <Detail5ViewVue :data="lis_data_mahasiswa" />
   </modal-show>

   <modal-show
      modal_id="detail6"
      modal_name="Detail Penilaian"
      modal_size="modal-lg modal-dialog-scrollable">

      <Detail6ViewVue :data="lis_data_mahasiswa" />
   </modal-show>
</template>

<script>
   import axios from 'axios'
   import Detail1ViewVue from './dasbor/Detail1View.vue'
   import Detail2ViewVue from './dasbor/Detail2View.vue'
   import Detail3ViewVue from './dasbor/Detail3View.vue'
   import Detail4ViewVue from './dasbor/Detail4View.vue'
   import Detail5ViewVue from './dasbor/Detail5View.vue'
   import Detail6ViewVue from './dasbor/Detail6View.vue'

   export default {
      name: 'AdminDasbor',
      components: {
         Detail1ViewVue,
         Detail2ViewVue,
         Detail3ViewVue,
         Detail4ViewVue,
         Detail5ViewVue,
         Detail6ViewVue,
      },
      data() {
         return {
            token: sessionStorage.getItem('token'),
            foto: sessionStorage.getItem('foto'),
            lis_pengumuman: {},
            lis_data_dosen: {},
            lis_data_mahasiswa: {},
            total_dosen: 0,
            total_peserta: 0,
            jumlah_1: 0,
            jumlah_2: 0,
            jumlah_3: 0,
            jumlah_4: 0,
            jumlah_5: 0,
            jumlah_6: 0,
            loading: true
         }
      },
      mounted() {
         this.getDataDosen()
         this.getDataMahasiswa()
      },
      methods: {
         getDataDosen() {
            axios.get('dosen/list3', {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  this.lis_data_dosen = res.data.data
                  this.total_dosen = this.lis_data_dosen.length

                  this.lis_data_dosen.forEach(e => {
                     // formulir
                     let jumlah = 0
                     jumlah += e.file_1 == '-' ? 0 : (1 / 4)
                     jumlah += e.file_2 == '-' ? 0 : (1 / 4)
                     jumlah += e.file_3 == '-' ? 0 : (1 / 4)
                     jumlah += e.file_4 == '-' ? 0 : (1 / 4)

                     this.jumlah_1 += jumlah

                     // kunjungan
                     jumlah = 0
                     jumlah += e.kunjungan[0] == '-' ? 0 : (1 / 4)
                     jumlah += e.kunjungan[1] == '-' ? 0 : (1 / 4)
                     jumlah += e.kunjungan[2] == '-' ? 0 : (1 / 4)
                     jumlah += e.kunjungan[3] == '-' ? 0 : (1 / 4)

                     this.jumlah_2 += jumlah
                  });
               })
         },
         getDataMahasiswa() {
            axios.get('kelompok/rekapan', {
               headers: {
                  'Authorization': `Bearer ${this.token}`
               }
            })
            .then( res => {
               this.lis_data_mahasiswa = res.data.data
               this.total_peserta = this.lis_data_mahasiswa.length

                  this.lis_data_mahasiswa.forEach(e => {
                     // jumlah_3
                     if (e.formulir == 'TERVERIFIKASI') {
                        this.jumlah_3 += (1 / 3);
                     }

                     if (e.proker == 'TERVERIFIKASI') {
                        this.jumlah_3 += (1 / 3);
                     }

                     if (e.survei == 'TERVERIFIKASI') {
                        this.jumlah_3 += (1 / 3);
                     }

                     // jumlah_4
                     if (e.logbook[0] == 'TERVERIFIKASI') {
                        this.jumlah_4 += (1 / 4);
                     }

                     if (e.logbook[1] == 'TERVERIFIKASI') {
                        this.jumlah_4 += (1 / 4);
                     }

                     if (e.logbook[2] == 'TERVERIFIKASI') {
                        this.jumlah_4 += (1 / 4);
                     }

                     if (e.logbook[3] == 'TERVERIFIKASI') {
                        this.jumlah_4 += (1 / 4);
                     }

                     // jumlah_5
                     if (e.laporan_akhir == 'TERVERIFIKASI') {
                        this.jumlah_5 += (1 / 3)
                     }

                     if (e.jurnal == 'TERVERIFIKASI') {
                        this.jumlah_5 += (1 / 3)
                     }

                     if (e.artikel_berita == 'TERVERIFIKASI') {
                        this.jumlah_5 += (1 / 3)
                     }

                     // jumlah_6
                     if (e.penilaian_status == 'TERVERIFIKASI') {
                        this.jumlah_6 += 1
                     }
                  });
               
               this.loading = false
            })
         }
      }
   }
</script>

<style>

</style>