import {
    createRouter,
    createWebHistory
} from 'vue-router'

import DasborView from '../views/DasborView.vue'
import MahasiswaView from '../views/formulirAdmin/MahasiswaView.vue'
import PengumumanView from '../views/admin/PengumumanView.vue'
import PesertaDetail from '../views/admin/PesertaDetailView.vue'
import PesertaPenilaian from '../views/admin/PesertaPenilaianView.vue'
import BukuCatatan from '../views/BukuCatatanView.vue'
import LogBookView from '../views/LogBookView.vue'
import PesertaView from '@/views/PesertaView.vue'
import FormulirView from '../views/FormulirView.vue'
import CekMahasiswaView from '../views/pelaporan/CekMahasiswaView.vue'
import LogBookAdminDosenView from '@/views/logbook/admin/DosenView.vue'
import LogBookAdminMahasiswaView from '@/views/logbook/admin/MahasiswaView.vue'
import AdminPenilaianView from '@/views/admin/PenilaianView.vue'
import DosenView from '@/views/admin/DosenView.vue'
import ProfilView from '@/views/ProfilView.vue'
import PenilaianView from '@/views/PenilaianView.vue'
import User from '@/views/admin/UserView.vue'
import Jurusan from '@/views/admin/JurusanView.vue'

const routes = [{
        path: '/',
        name: 'dasbor',
        component: DasborView,
    },
    {
        path: '/a2Vsb21wb2s=',
        name: 'kelompok',
        component: ()=> import('@/views/mahasiswa/KelompokView.vue')
    },
    {
        path: '/Zm9ybXVsaXI=',
        name: 'formulir',
        component: FormulirView,
    },
    {
        path: '/Zm9ybXVsaXI=',
        name: 'peserta',
        component: PesertaView,
    },
    {
        path: '/Zm9ybXVsaXI=/detail',
        name: 'peserta-detail',
        component: PesertaDetail
    },
    {
        path: '/Zm9ybXVsaXI=/penilaian',
        name: 'peserta-penilaian',
        component: PesertaPenilaian
    },
    {
        path: '/bWFoYXNpc3dh',
        name: 'mahasiswa',
        component: MahasiswaView,
    },
    {
        path: '/YWRtS2V1YW5nYW4=',
        name: 'adm-keuangan',
        component: ()=> import('@/views/admin/AdministrasiKeuangan.vue')
    },
    {
        path: '/ZGVzYQ==',
        name: 'desa',
        component: ()=> import('../views/admin/DesaView.vue'),
    },
    {
        path: '/ZG9zZW4=',
        name: 'dosen',
        component: DosenView,
    },
    {
        path: '/cGVuZ3VtdW1hbg==',
        name: 'pengumuman',
        component: PengumumanView,
    },
    {
        path: '/YnVrdUNhdGF0YW4=',
        name: 'bukuCatatan',
        component: BukuCatatan,
    },
    {
        path: '/bG9nYm9vaw==',
        name: 'logbook',
        component: LogBookView,
    },
    {
        path: '/cGVsYXBvcmFuQ2Vr',
        name: 'pelaporanCek',
        component: CekMahasiswaView,
    },
    {
        path: '/bG9nYm9vaw==/dosen',
        name: 'LogBookAdminDosen',
        component: LogBookAdminDosenView
    },
    {
        path: '/bG9nYm9vaw==/mahasiswa',
        name: 'LogBookAdminMahasiswa',
        component: LogBookAdminMahasiswaView
    },
    {
        path: '/cGVuaWxhaWFu',
        name: 'penilaian',
        component: PenilaianView
    },
    {
        path: '/profil',
        name: 'profil',
        component: ProfilView
    },
    {
        path: '/user',
        name: 'user',
        component: User
    },
    {
        path: '/jurusan',
        name: 'jurusan',
        component: Jurusan
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(),
    routes,
    linkActiveClass: 'active',
})

export default router