<template>
  <div class="col-md-6">
    <div class="card mb-3">
      <div class="card-stamp">
        <div class="card-stamp-icon bg-yellow">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
            stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
            <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
          </svg>
        </div>
      </div>
      <div class="card-body">
        <h3 class="card-title">{{ data.judul }}</h3>
        <p class="text-muted">{{ data.isi }}</p>
        <a :href="data.file_pendukung" target="_blank" class="btn btn-light">Lihat File</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Pengumuman',
    props: ['data']
  }
</script>

<style>

</style>