<template>
  <MenuHeader judul="Tahap 4" deskripsi="Laporan Akhir KKN Tematik" :mahasiswa="mahasiswa" :warna="warna" />
  <div class="card">
    <table-body>
      <template v-slot:thead>
        <tr class="text-center">
          <th>Jenis File</th>
          <th>Bukti Upload</th>
          <th>Status</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr>
          <td>Laporan Akhir</td>
          <td class="text-center">
            <a :href="data.laporan_file" target="_blank" class="btn btn-secondary" v-if="data.laporan_file != '-'">Lihat
              File</a>
            <span v-else >-</span>
          </td>
          <td class="text-center">
            <MutedVue v-if="data.laporan_file == '-'" />
            <WarningVue v-else-if="data.laporan_status == 'MENUNGGU'" />
            <SuccessVue v-else />
          </td>
        </tr>
        <tr>
          <td>Jurnal</td>
          <td class="text-center">
            <a :href="data.jurnal_file" target="_blank" class="btn btn-secondary" v-if="data.jurnal_file != '-'">Lihat
              File</a>
            <span v-else >-</span>
          </td>
          <td class="text-center">
            <MutedVue v-if="data.jurnal_file == '-'" />
            <WarningVue v-else-if="data.jurnal_status == 'MENUNGGU'" />
            <SuccessVue v-else />
          </td>
        </tr>
        <tr>
          <td>Artikel Berita</td>
          <td class="text-center">
            <a :href="data.artikel_file" target="_blank" class="btn btn-secondary" v-if="data.artikel_file != '-'">Lihat
              File</a>
            <span v-else >-</span>
          </td>
          <td class="text-center">
            <MutedVue v-if="data.artikel_file == '-'" />
            <WarningVue v-else-if="data.artikel_status == 'MENUNGGU'" />
            <SuccessVue v-else />
          </td>
        </tr>
      </template>
    </table-body>
  </div>
</template>

<script>
  import MenuHeader from '@/components/menu/Header.vue'
  import Alert from '@/components/alert/Alert.vue'
  import SuccessVue from '@/components/status/Success.vue'
  import WarningVue from '@/components/status/Warning.vue'
  import MutedVue from '@/components/status/Muted.vue'
  import axios from 'axios'

  export default {
    name: 'LaporanAkhir',
    props: ['mahasiswa', 'warna', 'token'],
    components: {
      MenuHeader,
      Alert,
      SuccessVue,
      WarningVue,
      MutedVue
    },
    data() {
      return {
        data: {}
      }
    },
    created() {
      this.getData()
    },
    methods: {
      async getData() {
        await axios.get(`laporan/akhir/mhs/${this.mahasiswa['nim']}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.data = res.data.data
          })
      },
    }
  }
</script>

<style>

</style>