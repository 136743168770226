<template>
  <menu-header :warna="warna" :judul="judul" :deskripsi="deskripsi" />

  <div class="card">

    <BodyTable>
      <template v-slot:thead>
        <tr class="text-center">
          <th style="width: 150px">Laporan</th>
          <th style="width: 150px">Tanggal</th>
          <th>URL Kegiatan</th>
          <th>Deskripsi Kegiatan</th>
          <th style="width: 150px">Status</th>
          <th class="px-0" style="width: 80px">Aksi</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="(data, index) in lis_data" :key="index">
          <td>{{ data.judul }}</td>
          <td>{{ data.tanggal }}</td>
          <td>
            <a :href="data.url" target="_blank" class="btn btn-secondary">Lihat</a><br>
          </td>
          <td class="text-wrap">
            {{ data.catatan }}
          </td>
          <td class="text-center">
            <StatusSuccess v-if="status_validasi(data)" />
            <StatusWarning v-else-if="data.catatan != null" />
            <StatusMuted v-else />
          </td>
          <td class="text-center">
            <div class="d-flex gap-2" v-if="data.catatan != null && !status_validasi(data)">
              <button class="btn btn-success" @click="verifikasiTahap3(data.id, 1)">Terima</button>
              <button class="btn btn-danger" @click="verifikasiTahap3(data.id, 0)">Tolak</button>
            </div>
          </td>
        </tr>

        <tr v-for="i in sisa" :key="i">
          <td>Minggu ke-{{ lis_data.length ? lis_data.length + i : i }}</td>
          <td class="text-center">-</td>
          <td class="text-center">-</td>
          <td class="text-center">-</td>
          <td class="text-center">
            <StatusMuted />
          </td>
          <td class="text-center">-</td>
        </tr>
      </template>
    </BodyTable>
  </div>
</template>

<script>
  import axios from 'axios'
  import Alert from '@/components/alert/Alert.vue'
  import BodyTable from '@/components/table/BodyTable.vue'
  import StatusSuccess from '@/components/status/Success.vue'
  import StatusWarning from '@/components/status/Warning.vue'
  import StatusMuted from '@/components/status/Muted.vue'

  export default {
    name: 'PesertaLaporanMingguan',
    props: ['judul', 'deskripsi', 'mahasiswa', 'warna', 'token'],
    components: {
      Alert,
      BodyTable,
      StatusMuted,
      StatusSuccess,
      StatusWarning,
    },
    data() {
      return {
        lis_data: {},
        sisa: 0,
        peran: sessionStorage.getItem('peran')
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      async getData() {
        await axios.get(`laporan/mahasiswa/${this.mahasiswa['nim']}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.lis_data = res.data.data
            this.sisa = 4 - this.lis_data.length
          })
      },
      status_validasi(laporan) {
        let status = false;
        switch (this.peran) {
          case 'DOSEN':
            status = laporan.verifikasi_dosen != null;
            break;
          case 'PRODI':
            status = laporan.verifikasi_prodi != null;
            break;
          case 'LPPM':
            status = laporan.verifikasi_lppm != null;
            break;
        }

        return status;
      },
      async verifikasi(id) {
        await axios.post(`laporan/mahasiswa/verifikasi`, {
            id
          }, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$emit('update-data', true)
              this.getData()
              this.$toast.success('laporan mingguan berhasil diverifikasi')
            }
          })
      },
      async verifikasiBatal(id) {
        await axios.post(`laporan/mahasiswa/verifikasi/batal`, {
            id
          }, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$emit('update-data', true)
              this.getData()
              this.$toast.success('laporan mingguan batal diverifikasi')
            }
          })
      },
      async verifikasiTahap3(id, status) {
          let formData = new FormData()
          formData.append('id', id)
          formData.append('status', status)
          formData.append('peran', this.peran)
  
          await axios.post(`laporan/mahasiswa/verifikasi`, formData, {
              headers: {
                'Authorization': `Bearer ${this.token}`
              }
            })
            .then(res => {
              if (res.data.status) {
                this.$emit('update-data', true)
                this.getData()
                this.$toast.success('laporan mingguan berhasil diverifikasi')
              }
            })
        },
    },
  }
</script>

<style>

</style>