<template>
    
</template>

<script>
    export default {
        name: 'BukuCatatan'
    }
</script>

<style>

</style>