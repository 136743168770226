<template>
   <div class="card">
      <div class="card-body">
         <select class="form-select w-auto" v-model="filter" @change="getData">
            <option v-for="(filter, index) in lis_filter" :key="index">{{filter}}</option>
         </select>
      </div>

      <table-body>
         <template #thead>
            <tr class="text-center">
               <th class="px-2" style="width: 40px">No</th>
               <th>Nama</th>
               <th>Username</th>
               <th class="px-2" style="width: 100px">Aksi</th>
            </tr>
         </template>

         <template #tbody>
            <tr v-for="(data, index) in lis_data" :key="index">
               <td>{{ index + 1}}</td>
               <td>{{ data.nama }}
                  <span class="text-muted">
                     <br>{{ data.no_identitas }}
                     <br>{{ data.lembaga }}
                  </span>
               </td>
               <td>{{ data.username }}</td>
               <td class="text-center px-2">
                  <button class="btn btn-secondary" @click="resetPassword(data.id)">Reset Password</button>
               </td>
            </tr>
         </template>
      </table-body>
   </div>
</template>

<script>
   import axios from 'axios'
   export default {
      name: 'User',
      data() {
         return {
            lis_filter: ['Dosen', 'Mahasiswa', 'LPPM'],
            filter: 'Dosen',
            token: sessionStorage.getItem('token'),
            lis_data: {}
         }
      },
      beforeMount() {
         this.getData()
      },
      methods: {
         getData() {
            axios.get(`user/list?peran=${this.filter}`, {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  this.lis_data = res.data.data.sort((a, b) => {
                     return a.nama.localeCompare(b.nama)
                  })
               })
         },
         resetPassword(id) {
            Swal.fire({
                  icon: 'question',
                  text: 'Reset password ?',
                  showCancelButton: true,
                  cancelButtonText: 'Batal'
               })
               .then(res => {
                  if (res.isConfirmed) {
                     let formData = new FormData()
                     formData.append('id', id)
                     axios.post(`user/reset`, formData, {
                           headers: {
                              'Authorization': `Bearer ${this.token}`
                           }
                        })
                        .then(res => {
                           if (res.data.status) {
                              this.$toast.success('Password berhasil direset menjadi itg@garut')
                           }
                        })
                  }
               })
         }
      }
   }
</script>