<template>
   <Alert status="alert-secondary mt-3" v-if="loading">
      Tunggu sampai data berhasil diload
   </Alert>

   <div v-else>
      <div class="row">
         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-blue" judul="Formulir, Proker & Survei" :jumlah="jumlah_1"
               :total="total_peserta" :persen="(jumlah_1 / total_peserta) * 100" :data="lis_data1">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail1">Detail</a>
            </infografis-rekapan>
         </div>

         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-purple" judul="Laporan Mingguan" :jumlah="jumlah_2" :total="total_peserta"
               :persen="(jumlah_2 / total_peserta) * 100" :data="lis_data2">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail2">Detail</a>
            </infografis-rekapan>
         </div>

         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-pink" judul="Laporan Akhir" :jumlah="jumlah_3" :total="total_peserta"
               :persen="(jumlah_3 / total_peserta) * 100" :data="lis_data3">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail3">Detail</a>
            </infografis-rekapan>
         </div>

         <div class="col-lg-3 col-md-4">
            <infografis-rekapan warna="bg-red" judul="Penilaian" :jumlah="jumlah_4" :total="total_peserta"
               :persen="(jumlah_4 / total_peserta) * 100" :data="lis_data4">
               <a href="#" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#detail4">Detail</a>
            </infografis-rekapan>
         </div>
      </div>

      <div class="row">
         <Alert2 v-for="(pengumuman, index) in lis_pengumuman" :key="index" :data="pengumuman" />
      </div>
   </div>

   <ShowModal modal_id="detail1" modal_name="Detail Formulir, Proker & Survei"
      modal_size="modal-lg modal-dialog-scrollable">
      <DasborDetail1 :lis_data="lis_data" />
   </ShowModal>

   <ShowModal modal_id="detail2" modal_name="Detail Laporan Mingguan" modal_size="modal-lg modal-dialog-scrollable">
      <DasborDetail2 :lis_data="lis_data" />
   </ShowModal>

   <ShowModal modal_id="detail3" modal_name="Detail Laporan Akhir" modal_size="modal-lg modal-dialog-scrollable">
      <DasborDetail3 :lis_data="lis_data" />
   </ShowModal>

   <ShowModal modal_id="detail4" modal_name="Detail Penilaian" modal_size="modal-full-width modal-dialog-scrollable">
      <DasborDetail4 :lis_data="lis_data" />
   </ShowModal>
</template>

<script>
   import axios from 'axios'
   import Alert from '@/components/alert/Alert.vue'
   import Alert2 from '@/components/alert/Alert2.vue'
   import ShowModal from '@/components/modal/ShowModal.vue'
   import DasborDetail1 from '@/views/dosen/dashbor/Detail1View.vue'
   import DasborDetail2 from '@/views/dosen/dashbor/Detail2View.vue'
   import DasborDetail3 from '@/views/dosen/dashbor/Detail3View.vue'
   import DasborDetail4 from '@/views/dosen/dashbor/Detail4View.vue'

   export default {
      name: 'DosenDasbor',
      components: {
         Alert,
         Alert2,
         ShowModal,
         DasborDetail1,
         DasborDetail2,
         DasborDetail3,
         DasborDetail4
      },
      data() {
         return {
            token: sessionStorage.getItem('token'),
            foto: sessionStorage.getItem('foto'),
            lis_pengumuman: {},
            lis_data: {},
            total_peserta: 0,
            jumlah_1: 0,
            jumlah_2: 0,
            jumlah_3: 0,
            jumlah_4: 0,
            loading: true
         }
      },
      mounted() {
         this.getData()
         this.getPengumuman()
      },
      methods: {
         async getData() {
            await axios.get('kelompok/list', {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  this.lis_data = res.data.anggota
                  this.total_peserta = this.lis_data.length

                  this.lis_data.forEach(e => {
                     // jumlah_1
                     if (e.formulir == 'TERVERIFIKASI') {
                        this.jumlah_1 += (1 / 3);
                     }

                     if (e.proker == 'TERVERIFIKASI') {
                        this.jumlah_1 += (1 / 3);
                     }

                     if (e.survei == 'TERVERIFIKASI') {
                        this.jumlah_1 += (1 / 3);
                     }

                     // jumlah_2
                     if (e.logbook[0] == 'TERVERIFIKASI') {
                        this.jumlah_2 += (1 / 4);
                     }

                     if (e.logbook[1] == 'TERVERIFIKASI') {
                        this.jumlah_2 += (1 / 4);
                     }

                     if (e.logbook[2] == 'TERVERIFIKASI') {
                        this.jumlah_2 += (1 / 4);
                     }

                     if (e.logbook[3] == 'TERVERIFIKASI') {
                        this.jumlah_2 += (1 / 4);
                     }

                     // jumlah_3
                     if (e.laporan_akhir == 'TERVERIFIKASI') {
                        this.jumlah_3 += (1 / 3)
                     }

                     if (e.jurnal == 'TERVERIFIKASI') {
                        this.jumlah_3 += (1 / 3)
                     }

                     if (e.artikel_berita == 'TERVERIFIKASI') {
                        this.jumlah_3 += (1 / 3)
                     }

                     // jumlah_4
                     if (e.penilaian_status == 'TERVERIFIKASI') {
                        this.jumlah_4 += 1
                     }
                  });

                  this.loading = false
               })
         },
         async getPengumuman() {
            await axios.get('pengumuman/list', {
               headers: {
                  'Authorization': `Bearer ${this.token}`
               }
            }).then(res => {
               this.lis_pengumuman = res.data.data
            })
         }
      }
   }
</script>

<style>

</style>