<template>
  <Alert status="alert-secondary" v-if="loading">
    Tunggu sampai data berhasil diload
  </Alert>

  <div v-else>
    <Alert status="alert-danger" v-if="!status_validasi">
      Administrasi keuangan belum divalidasi, pastikan telah tervalidasi untuk melanjutkan ke tahap selanjutnya
    </Alert>

    <Alert status="alert-danger" v-else-if="!status_kelompok">
      Klik menu kelompok untuk memilih kelompok
    </Alert>

    <div class="row">
      <Alert2 v-for="(pengumuman, index) in lis_pengumuman" :key="index" :data="pengumuman" />
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Alert from '@/components/alert/Alert.vue'
  import Alert2 from '@/components/alert/Alert2.vue'

  export default {
    name: 'DosenDasbor',
    components: {
      Alert,
      Alert2
    },
    data() {
      return {
        token         : sessionStorage.getItem('token'),
        lis_pengumuman: {},
        loading       : false,
        status_validasi: false,
        status_kelompok: false,
      }
    },
    mounted() {
      this.getPengumuman()
      this.getStatusValidasi()
    },
    methods: {
      async getPengumuman() {
        this.loading = true
        await axios.get('pengumuman/list', {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).then(res => {
          this.lis_pengumuman = res.data.data
        })
      },

      async getStatusValidasi() {
        await axios.get('validasiBak/statusValidasi', {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).then( res => {
          this.status_validasi = res.data.status
          this.status_kelompok = res.data.kelompok

          setTimeout(() => {
            this.loading = false
          }, 1000);
        })
      }
    }
  }
</script>

<style>

</style>