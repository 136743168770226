<template>
  <div class="alert" :class="status">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: ['status']
}
</script>

<style>

</style>