<template>
  <div
    style="
      position: fixed;
      bottom: 0;
      text-align: center;
      width: 100vw;
      background-color: rgba(129, 236, 236, 1);
      padding: 10px 0;
      left: 0;
      right: 0;
      box-shadow: rgb(30 41 59 / 10%) 2px 0 8px 0;
      z-index: 9999;
    "
    
    class="d-block d-md-none">Untuk Melihat Semua Data Geser Kesamping Kanan</div>

  <div class="table-responsive">
    <table class="table card-table table-bordered table-hover table-vcenter text-nowrap">
      <thead>
          <slot name="thead"></slot>
      </thead>

      <thead>
          <slot name="tbody"></slot>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  name: 'BodyTable'
}
</script>

<style>

</style>