<template>
  <AdminPenilaian v-if="peran == 'LPPM' || peran == 'ADMIN'" />
  <DosenPenilaian v-else-if="peran == 'DOSEN'" />
</template>

<script>
  import DosenPenilaian from '@/views/dosen/PenilaianView.vue'
  import AdminPenilaian from '@/views/admin/PenilaianView.vue'

  export default {
    name: 'Penilaian',
    components: {
      AdminPenilaian,
      DosenPenilaian
    },
    data() {
      return {
        peran: sessionStorage.getItem('peran')
      }
    }
  }
</script>