<template>
  <div class="card">
    <Header judul="Formulir" deskripsi="Formulir KKN Tematik" warna="bg-green" />

    <div class="card-body">

      <div class="row">
        <div class="col-md-5">
          <div class="table-responsive">
            <table class="table card-table table-bordered table-hover table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>validator</th>
                  <th>status</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Program Studi</td>
                  <td>
                    <span v-if="mahasiswa.ajukan == null || mahasiswa.file_1 == '-' || mahasiswa.file_2 == '-' || mahasiswa.file_3 == '-'">
                      -
                    </span>
                    <span v-else-if="mahasiswa.verifikasi_prodi == null">
                      Menunggu Validasi
                    </span>
                    <span v-else>
                      {{ mahasiswa.verifikasi_prodi }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>LPPM</td>
                  <td>
                    <span v-if="mahasiswa.ajukan == null || mahasiswa.file_1 == '-' || mahasiswa.file_2 == '-' || mahasiswa.file_3 == '-'">
                      -
                    </span>
                    <span v-else-if="mahasiswa.verifikasi_lppm == null">
                      Menunggu Validasi
                    </span>
                    <span v-else>
                      {{ mahasiswa.verifikasi_lppm }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-7">
          <div class="alert alert-warning" v-if="mahasiswa.ajukan == null">
            Unduh dokumen berikut untuk selanjutnya diisi dan ditandatangani
          </div>

          <a target="_blank" href="https://apikkn.itg.ac.id/public/formulir_mahasiswa.pdf" class="btn btn-secondary"
            v-if="mahasiswa.ajukan == null" download>Unduh Dokumen</a>

          <div class="alert alert-warning mt-3" v-if="mahasiswa.ajukan == null">
            Ungah dokumen yang telah ditandatangani
          </div>

          <div class="alert alert-success mt-3" v-if="mahasiswa.status == 'TERVERIFIKASI'">
            Formulir KKN Tematik Telah Diverifikasi
          </div>

          <form v-if="mahasiswa.status != ''">
            <div class="mb-1">
              <label class="form-label" for="file_1">Dokumen 1 : Formulir Pendaftaran</label>
              <div class="input-group" v-if="mahasiswa.ajukan == null">
                <input type="file" id="file_1" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_1')">Unggah</button>
              </div>
            </div>

            <div v-if="mahasiswa.file_1 != '-'" class="mb-3">
              <a :href="mahasiswa.file_1" target="_blank" class="btn btn-secondary">Lihat Dokumen</a>
            </div>

            <div class="mb-1 mt-3">
              <label class="form-label" for="file_2">Dokumen 2 : Surat Izin Keluarga</label>
              <div class="input-group" v-if="mahasiswa.ajukan == null">
                <input type="file" id="file_2" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_2')">Unggah</button>
              </div>
            </div>

            <div v-if="mahasiswa.file_2 != '-'" class="mb-3">
              <a :href="mahasiswa.file_2" target="_blank" class="btn btn-secondary">Lihat Dokumen</a>
            </div>

            <div class="mb-1 mt-3">
              <label class="form-label" for="file_3">Dokumen 3 : Surat Pernyataan</label>
              <div class="input-group" v-if="mahasiswa.ajukan == null">
                <input type="file" id="file_3" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_3')">Unggah</button>
              </div>
            </div>

            <div v-if="mahasiswa.file_3 != '-'" class="mb-3">
              <a :href="mahasiswa.file_3" target="_blank" class="btn btn-secondary">Lihat Dokumen</a>
            </div>

            <p class="text-danger mt-3" style="font-size: 11px">Format File (PDF), Ukuran Maksimal 1 MB</p>
          </form>

          <button 
            class="btn btn-primary w-100" 
            v-if="mahasiswa.ajukan == null && mahasiswa.file_1 != '-' && mahasiswa.file_2 != '-' && mahasiswa.file_3 != '-'"
            @click="postAjukan"
            >Ajukan</button>
          </div>
      </div>

    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Header from '@/components/menu/Header.vue'
import Title from '@/components/Title.vue'

  export default {
    name: 'MahasiswaFormulirFormulir',
    components: {
      Header
    },
    data() {
      return {
        token: sessionStorage.getItem('token'),
        mahasiswa: {
          status: '',
          file_1: '-',
          file_2: '-',
          file_3: '-',
          file_4: '',
          file_5: '',
        },
      }
    },
    mounted() {
      this.getProfile()
    },
    methods: {
      async getProfile() {
        await axios.get('mahasiswa/profile', {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {

            setTimeout(() => {
              this.mahasiswa = res.data.data
              this.message =
                'Semua isian profil akan dijadikan acuan dalam pengumpulan data, pastikan semua data telah benar'
            }, 1000);

          })
      },
      unggah(dokumen) {
        let formData = new FormData()
        let file = document.querySelector(`#${dokumen}`)

        formData.append('dokumen', dokumen)
        formData.append('file', file.files[0])

        axios.post('register/mahasiswa', formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              Swal.fire({
                icon: 'success',
                text: 'Dokumen berhasil diunggah',
                timer: 5000
              })

              file.value = ''

              this.getProfile()
            }
          })
      },
      batalUpload(dokumen) {
        Swal.fire({
          icon: 'question',
          text: 'Hapus Formulir KKN ?',
          showCancelButton: true,
          cancelButtonText: 'Batal'
        }).then(res => {
          if (res.isConfirmed) {
            let formData = new FormData()

            formData.append('dokumen', dokumen)

            axios.post('register/mahasiswa/batalUpload', formData, {
                headers: {
                  'Authorization': `Bearer ${this.token}`
                }
              })
              .then(res => {
                if (res.data.status) {
                  this.$toast.success('Formulir berhasil dihapus')
                  this.getProfile()
                }
              })
          }
        })
      },

      postAjukan() {
        Swal.fire({
          icon: 'question',
          title: 'Ajukan Formulir KKN',
          text: 'Klik tombol ajukan untuk melanjutkan proses pengajuan formulir KKN',
          showCancelButton: true,
          cancelButtonText: 'Batal',
          confirmButtonText: 'Ajukan'
        }).then( e => {
          if( e.isConfirmed ) {
            axios.post('register/mahasiswa/ajukan', {
              id: this.mahasiswa.id
            }, {
                headers: {
                  'Authorization': `Bearer ${this.token}`
                }
              })
              .then(res => {
                if (res.data.status) {
                  this.$toast.success('Formulir berhasil diajukan')
                  this.getProfile()
                }
              })
          }
        })
      }

      
    }
  }
</script>

<style>

</style>