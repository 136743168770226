<template>
  <div class="card card-sm mb-2 cursor-pointer">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-auto">
          <div class="text-white avatar" :class="warna">
            <slot></slot>
          </div>
        </div>
        <div class="col">
          <small>{{ judul }}</small>
          <div>
            <b>{{ deskripsi }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Menu',
    props: ['judul', 'deskripsi', 'warna', 'active']
  }
</script>

<style>
  .cursor-pointer {
    cursor: pointer;
  }

  .cursor-pointer:hover {
    background-color: rgba(98, 105, 118, .05);
  }
</style>