<template>
  <menu-header :warna="warna" :judul="judul" :deskripsi="deskripsi" />
  
  <div class="card">
    <BodyTable>
      <template v-slot:thead>
        <tr class="text-center">
          <th>Jenis File</th>
          <th>Bukti Upload</th>
          <th>Status</th>
          <th style="width: 200px">Aksi</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr>
          <td>Laporan Akhir</td>
          <td class="text-center">
            <a :href="data.laporan_file" target="_blank" class="btn btn-secondary" v-if="data.laporan_file != '-'">Lihat
              File</a>
            <span v-else >-</span>
          </td>
          <td class="text-center">
            <SuccessVue v-if="status_validasi(data, 'laporan')" />
            <WarningVue v-else-if="data.laporan_file != '-'" />
            <MutedVue v-else />
          </td>
          <td class="text-center">
            <div class="d-flex gap-2" v-if="data.laporan_file != '-' && !status_validasi(data, 'laporan')">
              <button class="btn btn-success" @click="verifikasiTahap4(data.laporan_id, 1)">Terima</button>
              <button class="btn btn-danger" @click="verifikasiTahap4(data.laporan_id, 0)">Tolak</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>Jurnal</td>
          <td class="text-center">
            <a :href="data.jurnal_file" target="_blank" class="btn btn-secondary" v-if="data.jurnal_file != '-'">Lihat File</a>
            <span v-else >-</span>
          </td>
          <td class="text-center">
            <SuccessVue v-if="status_validasi(data, 'jurnal')" />
            <WarningVue v-else-if="data.jurnal_file != '-'" />
            <MutedVue v-else />
          </td>
          <td class="text-center">
            <div class="d-flex gap-2" v-if="data.jurnal_file != '-' && !status_validasi(data, 'jurnal')">
              <button class="btn btn-success" @click="verifikasiTahap4(data.jurnal_id, 1)">Terima</button>
              <button class="btn btn-danger" @click="verifikasiTahap4(data.jurnal_id, 0)">Tolak</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>Artikel Berita</td>
          <td class="text-center">
            <a :href="data.artikel_file" target="_blank" class="btn btn-secondary" v-if="data.artikel_file != '-'">Lihat
              File</a>
            <span v-else >-</span>
          </td>
          <td class="text-center">
            <SuccessVue v-if="status_validasi(data, 'artikel')" />
            <WarningVue v-else-if="data.artikel_file != '-'" />
            <MutedVue v-else />
          </td>
          <td class="text-center">
            <div class="d-flex gap-2" v-if="data.artikel_file != '-' && !status_validasi(data, 'artikel')">
              <button class="btn btn-success" @click="verifikasiTahap4(data.artikel_id, 1)">Terima</button>
              <button class="btn btn-danger" @click="verifikasiTahap4(data.artikel_id, 0)">Tolak</button>
            </div>
          </td>
        </tr>
      </template>
    </BodyTable>
  </div>
</template>

<script>
  import axios from 'axios'
  import Alert from '@/components/alert/Alert.vue'
  import BodyTable from '@/components/table/BodyTable.vue'
  import SuccessVue from '@/components/status/Success.vue'
  import WarningVue from '@/components/status/Warning.vue'
  import MutedVue from '@/components/status/Muted.vue'

  export default {
    name: 'PesertaLaporanAkhir',
    props: ['judul', 'deskripsi', 'mahasiswa', 'warna'],
    components: {
      Alert,
      BodyTable,
      SuccessVue,
      WarningVue,
      MutedVue
    },
    data() {
      return {
        token: sessionStorage.getItem('token'),
        data: {
          laporan_file: '-',
          jurnal_file: '-',
          artikel_file: '-',
        },
        peran: sessionStorage.getItem('peran')
      }
    },
    created() {
      this.getData()
    },
    methods: {
      async getData() {
        await axios.get(`laporan/akhir/mhs/${this.mahasiswa['nim']}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.data = res.data.data
          })
      },
      status_validasi(data, jenis) {
        let status = false;
        switch (this.peran) {
          case 'DOSEN':
            if (jenis == 'laporan') {
              status = data.laporan_verifikasi_dosen != null;
            } else if (jenis == 'jurnal') {
              status = data.jurnal_verifikasi_dosen != null;
            } else {
              status = data.artikel_verifikasi_dosen != null;
            }
            break;
          case 'PRODI':
            if (jenis == 'laporan') {
              status = data.laporan_verifikasi_prodi != null;
            } else if (jenis == 'jurnal') {
              status = data.jurnal_verifikasi_prodi != null;
            } else {
              status = data.artikel_verifikasi_prodi != null;
            }
            break;
          case 'LPPM':
            if (jenis == 'laporan') {
              status = data.laporan_verifikasi_lppm != null;
            } else if (jenis == 'jurnal') {
              status = data.jurnal_verifikasi_lppm != null;
            } else {
              status = data.artikel_verifikasi_lppm != null;
            }
            break;
        }

        return status;
      },
      async verifikasiTahap4(id, status) {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('peran', this.peran)
        formData.append('status', status)

        await axios.post(`laporan/akhir/verifikasi`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Laporan akhir berhasil diverifikasi')
              this.$emit('update-data', true)
              this.getData()
            }
          })
      },
      async verifikasiBatal(id) {
        let formData = new FormData()
        formData.append('id', id)

        await axios.post(`laporan/akhir/verifikasi/batal`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Laporan akhir batal diverifikasi')
              this.$emit('update-data', true)
              this.getData()
            }
          })
      },
    }
  }
</script>

<style>

</style>