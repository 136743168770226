<template>
   <div class="alert alert-info mb-3">
      Pengecekan dan validasi dilakukan pada halaman Dosen
   </div>

   <div class="card">
      <table-body>
         <template #thead>
            <tr class="text-center">
               <th rowspan="2" style="width: 40px" class="px-2">No.</th>
               <th rowspan="2">Dosen</th>
               <th colspan="6">Kunjungan</th>
            </tr>

            <tr class="text-center">
               <th style="width: 70px" class="px-2">1</th>
               <th style="width: 70px" class="px-2">2</th>
               <th style="width: 70px" class="px-2">3</th>
               <th style="width: 70px" class="px-2">4</th>
               <th style="width: 70px" class="px-2">5</th>
               <th style="width: 70px" class="px-2">6</th>
            </tr>
         </template>

         <template #tbody>
            <tr v-for="(d, index) in data" :key="index">
               <td>{{ index + 1}}</td>
               <td>{{ d.nama }}</td>
               <td class="text-center px-2">
                  <status-success v-if="d.kunjungan[0] == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.kunjungan[0] == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
               <td class="text-center px-2">
                  <status-success v-if="d.kunjungan[1] == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.kunjungan[1] == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
               <td class="text-center px-2">
                  <status-success v-if="d.kunjungan[2] == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.kunjungan[2] == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
               <td class="text-center px-2">
                  <status-success v-if="d.kunjungan[3] == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.kunjungan[3] == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
               <td class="text-center px-2">
                  <status-success v-if="d.kunjungan[4] == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.kunjungan[4] == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
               <td class="text-center px-2">
                  <status-success v-if="d.kunjungan[5] == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.kunjungan[5] == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
            </tr>
         </template>
      </table-body>
   </div>
</template>

<script>
   export default {
      name: 'DasborDetail2',
      props: ['data']
   }
</script>

<style>

</style>