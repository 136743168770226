<template>
    <div class="card">

        <div class="card-body">
            <h4 class="mb-2">{{ kelompok.nama }}</h4>
            <h4 class="mb-0">{{ kelompok.desa }}</h4>
            <p class="text-muted">{{ kelompok.alamat }}</p>
        </div>

        <BodyTable>
            <template v-slot:thead>
                <tr class="text-center text-wrap">
                    <th rowspan="2" style="width: 40px" class="px-0">No</th>
                    <th rowspan="2">Mahasiswa</th>
                    <th rowspan="2">Pembekalan</th>
                    <th rowspan="2">Formulir</th>
                    <th rowspan="2">Penyusunan Program Kerja</th>
                    <th rowspan="2">Survei</th>
                    <th rowspan="2">Laporan Harian</th>
                    <th colspan="4">Laporan Mingguan</th>
                    <th colspan="3">Laporan Akhir</th>
                    <th rowspan="2" class="px-0" style="width: 80px">Aksi</th>
                </tr>

                <tr class="text-center text-wrap">
                    <th style="width: 50px" class="px-2">1</th>
                    <th style="width: 50px" class="px-2">2</th>
                    <th style="width: 50px" class="px-2">3</th>
                    <th style="width: 50px" class="px-2">4</th>
                    <th style="width: 70px" class="px-2">Laporan KKN</th>
                    <th style="width: 70px" class="px-2">Jurnal</th>
                    <th style="width: 70px" class="px-2">Artikel Berita</th>
                </tr>

            </template>

            <template v-slot:tbody>
                <tr v-for="(anggota, index) in lis_anggota" :key="index" class="text-center">
                    <td style="text-align: right">{{ index + 1 }}</td>
                    <td style="text-align: left;">
                        <div>
                            <span class="font-weight-medium">
                                {{ anggota.nama }}
                            </span>
                            <span class="text-muted"><br>{{ anggota.nim }}<br>{{ anggota.prodi }}</span>
                        </div>
                    </td>
                    <td>
                        <StatusSuccess v-if="anggota.penilaian_pembekalan == 100" />
                        <StatusMuted v-else/>
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.file_1 == '-' || anggota.file_2 == '-' || anggota.file_3 == '-'" />
                        <StatusWarning v-else-if="anggota.verifikasi_prodi == NULL" />
                        <StatusSuccess v-else />
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.proker == '-'" />
                        <StatusWarning v-else-if="!anggota.proker" />
                        <StatusSuccess v-else />
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.survei == '-'" />
                        <StatusWarning v-else-if="!anggota.survei" />
                        <StatusSuccess v-else />
                    </td>
                    <td>
                        <span v-if="anggota.laporan_harian > 0"
                            :class="anggota.laporan_harian_menunggu > 0 ? 'text-muted' : 'text-success'">
                            {{ anggota.laporan_harian }}
                        </span>
                        <span class="text-danger" v-else>0</span>
                        / 31
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.logbook[0] == '-'" />
                        <StatusWarning v-else-if="anggota.logbook[0] == null" />
                        <StatusSuccess v-else-if="anggota.logbook[0] != null" />
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.logbook[1] == '-'" />
                        <StatusWarning v-else-if="anggota.logbook[1] == null" />
                        <StatusSuccess v-else-if="anggota.logbook[1] != null" />
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.logbook[2] == '-'" />
                        <StatusWarning v-else-if="anggota.logbook[2] == null" />
                        <StatusSuccess v-else-if="anggota.logbook[2] != null" />
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.logbook[3] == '-'" />
                        <StatusWarning v-else-if="anggota.logbook[3] == null" />
                        <StatusSuccess v-else-if="anggota.logbook[3] != null" />
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.laporan_akhir == '-'" />
                        <StatusWarning v-else-if="anggota.laporan_akhir == null" />
                        <StatusSuccess v-else />
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.jurnal == '-'" />
                        <StatusWarning v-else-if="anggota.jurnal == null" />
                        <StatusSuccess v-else />
                    </td>
                    <td>
                        <StatusMuted v-if="anggota.artikel_berita == '-'" />
                        <StatusWarning v-else-if="anggota.artikel_berita == null" />
                        <StatusSuccess v-else />
                    </td>
                    <td class="px-2">
                        <ButtonModal modal_target="#modalPesertaDetail" modal_name="Detail"
                            @click="showMahasiswa(anggota.nim); loading = true; halaman = 'Profil'" />
                    </td>
                </tr>
            </template>
        </BodyTable>
    </div>

    <ShowModal modal_id="modalPesertaDetail" modal_name="Detail Peserta KKN"
        modal_size="modal-full-width modal-dialog-scrollable">
        <div class="row">
            <div class="col-xl-3 col-md-4">
                <Menu judul="Profil & Formulir" deskripsi="Data Dasar & Formulir KKN" warna="bg-blue"
                    @click="halaman = 'Profil'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    </svg>
                </Menu>

                <Menu judul="Tahap 1" deskripsi="Program Kerja & Survei" warna="bg-azure" @click="halaman = 'Tahap1'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-time"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
                        <circle cx="18" cy="18" r="4"></circle>
                        <path d="M15 3v4"></path>
                        <path d="M7 3v4"></path>
                        <path d="M3 11h16"></path>
                        <path d="M18 16.496v1.504l1 1"></path>
                    </svg>
                </Menu>

                <Menu judul="Tahap 2" deskripsi="Laporan Harian" warna="bg-indigo" @click="halaman = 'Tahap2'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-history-toggle"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 8v4l3 3"></path>
                        <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
                        <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
                        <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
                        <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
                        <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
                        <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
                        <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
                        <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
                    </svg>
                </Menu>

                <Menu judul="Tahap 3" deskripsi="Laporan Mingguan" warna="bg-purple" @click="halaman = 'Tahap3'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-history-toggle"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 8v4l3 3"></path>
                        <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
                        <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
                        <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
                        <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
                        <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
                        <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
                        <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
                        <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
                    </svg>
                </Menu>

                <Menu judul="Tahap 4" deskripsi="Laporan Akhir KKN Tematik" warna="bg-pink" @click="halaman = 'Tahap4'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-report" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697"></path>
                        <path d="M18 14v4h4"></path>
                        <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2"></path>
                        <rect x="8" y="3" width="6" height="4" rx="2"></rect>
                        <circle cx="18" cy="18" r="4"></circle>
                        <path d="M8 11h4"></path>
                        <path d="M8 15h3"></path>
                    </svg>
                </Menu>
            </div>

            <div class="col-xl-9 col-md-8">
                <div class="card mb-1">
                    <div class="card-body bg-cyan d-flex justify-content-between">
                        <div>
                            {{ mahasiswa.nama }} ({{ mahasiswa.nim }})
                        </div>
                        <div>
                            {{ mahasiswa.prodi }}
                        </div>
                    </div>
                </div>

                <PesertaProfil v-if="halaman == 'Profil'" warna="bg-blue" judul="Profil & Formulir"
                    deskripsi="Data Dasar & Formulir KKN" :mahasiswa="mahasiswa" @update-data="getUpdate($event)" />

                <PesertaProker v-else-if="halaman == 'Tahap1'" warna="bg-azure" judul="Tahap 1"
                    deskripsi="Program Kerja & Survei" :mahasiswa="mahasiswa" :token="token"
                    @update-data="getUpdate($event)" />

                <LaporanHarian v-else-if="halaman == 'Tahap2'" warna="bg-indigo" judul="Tahap 2"
                    deskripsi="Laporan Harian" :mahasiswa="mahasiswa" :token="token" @update-data="getUpdate($event)" />

                <LaporanMingguan v-else-if="halaman == 'Tahap3'" warna="bg-purple" judul="Tahap 3"
                    deskripsi="Laporan Mingguan" :mahasiswa="mahasiswa" :token="token"
                    @update-data="getUpdate($event)" />

                <LaporanAkhir v-else-if="halaman == 'Tahap4'" warna="bg-pink" judul="Tahap 4" deskripsi="Laporan Akhir"
                    :mahasiswa="mahasiswa" :token="token" @update-data="getUpdate($event)" />
            </div>
        </div>
    </ShowModal>

</template>

<script>
    import axios from 'axios'
    import ButtonModal from '@/components/modal/ButtonModal.vue'
    import ShowModal from '@/components/modal/ShowModal.vue'
    import BodyTable from '@/components/table/BodyTable.vue'
    import Alert from '../../components/alert/Alert.vue'
    import StatusSuccess from '@/components/status/Success.vue'
    import StatusWarning from '@/components/status/Warning.vue'
    import StatusMuted from '@/components/status/Muted.vue'
    import Menu from '@/components/menu/Menu.vue'
    import PesertaProfil from './peserta/ProfilView.vue'
    import PesertaProker from './peserta/ProkerView.vue'
    import LaporanHarian from './peserta/LaporanHarianView.vue'
    import LaporanMingguan from './peserta/LaporanMingguanView.vue'
    import LaporanAkhir from './peserta/LaporanAkhirView.vue'

    export default {
        name: 'DosenPeserta',
        components: {
            ButtonModal,
            ShowModal,
            BodyTable,
            Alert,
            StatusSuccess,
            StatusWarning,
            StatusMuted,
            Menu,
            PesertaProfil,
            PesertaProker,
            LaporanHarian,
            LaporanMingguan,
            LaporanAkhir,
        },
        data() {
            return {
                token: sessionStorage.getItem('token'),
                peran: sessionStorage.getItem('peran'),
                lis_agama: ['Islam', 'Kristen'],
                lis_kelamin: ['L', 'P'],
                lis_ukuran: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
                loading: false,
                lis_anggota: {},
                lis_laporan_mingguan: {},
                kelompok: {},
                halaman: 'Profil',
                mahasiswa: {},
                sisa: 4,
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            async getData() {
                await axios.get('kelompok/list', {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        const data = res.data

                        this.kelompok.id = data.id
                        this.kelompok.nama = data.nama
                        this.kelompok.desa = data.desa
                        this.kelompok.alamat = data.alamat
                        this.lis_anggota = data.anggota

                        console.log( this.lis_anggota )
                    })
            },
            async showMahasiswa(nim) {
                await axios.get(`mahasiswa/show/${nim}`, {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        this.mahasiswa = res.data.data

                        setTimeout(() => {
                            this.loading = false
                        }, 1000);
                    })
            },
            getUpdate(event) {
                if (event) {
                    this.getData()
                    this.showMahasiswa(this.mahasiswa.nim)
                }
            },
        }
    }
</script>

<style>

</style>