<template>
  <div class="modal modal-blur fade" :id="modal_id" tabindex="-1" :aria-labelledby="modal_id" aria-hidden="true">
    <div class="modal-dialog" :class="modal_size">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ modal_name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          
          <slot> </slot>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShowModal',
  props: ['modal_id', 'modal_size', 'modal_name']
}
</script>

<style>

</style>