<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex flex-column flex-md-row justify-content-between">
                <div>
                    <h4 class="mb-2">{{ kelompok.nama }}</h4>
                    <h4 class="mb-0">{{ kelompok.desa }}</h4>
                    <p class="text-muted">{{ kelompok.alamat }}</p>
                </div>
                <div v-if="peran == 'ADMIN' || peran == 'LPPM'">
                    <modal-button modal_name="Pembekalan" modal_target="#modalPembekalan" @click="getDataAnggota" />
                </div>
            </div>
        </div>

        <BodyTableVue>
            <template v-slot:thead>
                <tr class="text-center">
                    <th rowspan="2" style="width: 50px">No.</th>
                    <th rowspan="2">Mahasiswa</th>
                    <th colspan="5">Komponen Penilaian</th>
                    <th colspan="2">Nilai Akhir</th>
                    <th rowspan="2">Status</th>
                    <th rowspan="2" style="width: 90px" class="px-0">Aksi</th>
                </tr>

                <tr class="text-center">
                    <th class="px-3" style="width: 70px">Pembekalan<br>(10%)</th>
                    <th class="px-3" style="width: 70px">Program Kerja<br>(5%)</th>
                    <th class="px-3" style="width: 70px">Pelaksanaan<br>(10%)</th>
                    <th class="px-3" style="width: 70px">Kinerja<br>(70%)</th>
                    <th class="px-3" style="width: 70px">Laporan<br>(5%)</th>
                    <th class="px-3" style="width: 70px">Angka</th>
                    <th class="px-3" style="width: 70px">Huruf</th>
                </tr>
            </template>

            <template v-slot:tbody>
                <tr v-for="(data, index) in lis_data" :key="index">
                    <td>{{ index + 1}}</td>
                    <td>{{ data.nama }}<span class="text-muted"><br>{{ data.nim }}<br>{{ data.prodi }}</span></td>
                    <td class="text-center">{{ data.pembekalan }}</td>
                    <td class="text-center">{{ data.proker }}</td>
                    <td class="text-center">{{ data.pelaksanaan }}</td>
                    <td class="text-center">{{ data.kinerja }}</td>
                    <td class="text-center">{{ data.laporan }}</td>
                    <td class="text-center">{{ data.angka }}</td>
                    <td class="text-center">{{ data.huruf }}</td>
                    <td class="text-center">
                        <StatusMuted v-if="data.status == '-'" />
                        <StatusWarning v-else-if="data.status == 'MENUNGGU'" />
                        <StatusSuccess v-else-if="data.status == 'TERVERIFIKASI'" />
                    </td>
                    <td class="text-center px-2">
                        <ButtonModal modal_name="Berikan Penilaian" modal_target="#modalPenilaian"
                            @click="showData(data.nim)" />
                    </td>
                </tr>
            </template>
        </BodyTableVue>
    </div>

    <modal-show modal_id="modalPembekalan" modal_name="Pembekalan KKN ITG">
        <div class="table-responsive">
            <table class="table table-bordered table-hover table-vcenter">
                <thead>
                    <tr class="text-center">
                        <th>No</th>
                        <th>Mahasiswa</th>
                        <th>Status</th>
                        <th style="width: 100px">Aksi</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(data, index) in lis_data" :key="index">
                        <td class="text-right">{{ index + 1}}</td>
                        <td>{{ data.nama }}<span class="text-muted"><br>{{ data.nim }}<br>{{ data.prodi }}</span>
                        </td>
                        <td class="text-center">
                            <status-muted v-if="data.pembekalan == '-'" />
                            <status-success v-else />
                        </td>
                        <td class="text-center px-2">
                            <button class="btn btn-success" v-if="data.pembekalan == '-'" @click="hadirPembekalan(data.nim)">Hadir</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </modal-show>

    <modal-show modal_id="modalPenilaian" modal_name="Detail Penilaian" modal_size="modal-full-width">

        <div class="card mb-3">

            <div class="card-body bg-cyan">
                <div class="d-flex flex-column flex-md-row justify-content-between">
                    <div>
                        {{ mahasiswa.nama }} ( {{ mahasiswa.nim }} )
                    </div>
                    <div>
                        {{ mahasiswa.program_studi }}
                    </div>
                </div>
            </div>

            <div class="card-body">
                <h3 class="mb-0">Penilaian KKN Tematik ITG</h3>
                <hr class="m-0 my-1">
            </div>

            <BodyTableVue>
                <template v-slot:thead>
                    <tr class="text-center">
                        <th colspan="5">Komponen Penilaian</th>
                        <th colspan="2">Nilai Akhir</th>
                        <th rowspan="2">Aksi</th>
                    </tr>

                    <tr class="text-center">
                        <th class="px-3">Pembekalan<br>10%</th>
                        <th class="px-3">Program Kerja<br>5%</th>
                        <th class="px-3">Pelaksanaan<br>10%</th>
                        <th class="px-3">Kinerja<br>70%</th>
                        <th class="px-3">Laporan<br>5%</th>
                        <th class="px-3">Angka</th>
                        <th class="px-3">Huruf</th>
                    </tr>
                </template>

                <template v-slot:tbody>
                    <tr>
                        <td class="text-center">
                            {{ penilaian.pembekalan }}
                        </td>
                        <td>
                            <input type="number" class="form-control" v-model="penilaian.proker" @change="hitung" />
                        </td>
                        <td>
                            <input type="number" class="form-control" v-model="penilaian.pelaksanaan" @change="hitung" />
                        </td>
                        <td>
                            <input type="number" class="form-control" v-model="penilaian.kinerja" @change="hitung" />
                        </td>
                        <td>
                            <input type="number" class="form-control" v-model="penilaian.laporan" @change="hitung" />
                        </td>
                        <td class="text-center">
                            {{ penilaian.angka }}
                        </td>
                        <td class="text-center">
                            {{ penilaian.huruf }}
                        </td>
                        <td class="text-center px-2">
                            <button class="btn btn-success w-100" @click="simpan">Simpan</button>
                        </td>
                    </tr>
                </template>
            </BodyTableVue>
        </div>

        <div class="card mb-3">
            <div class="card-body">
                <h3 class="mb-0">Riwayat Kegiatan</h3>
                <hr class="m-0 my-1">
            </div>
            <BodyTableVue>
                <template v-slot:thead>
                    <tr class="text-center text-wrap">
                        <th rowspan="2">Pembekalan</th>
                        <th rowspan="2">Formulir</th>
                        <th rowspan="2">Penyusunan Program Kerja</th>
                        <th rowspan="2">Survei</th>
                        <th rowspan="2">Laporan Harian</th>
                        <th colspan="4">Laporan Mingguan</th>
                        <th colspan="3">Laporan Akhir</th>
                    </tr>

                    <tr class="text-center text-wrap">
                        <th style="width: 50px" class="px-2">1</th>
                        <th style="width: 50px" class="px-2">2</th>
                        <th style="width: 50px" class="px-2">3</th>
                        <th style="width: 50px" class="px-2">4</th>
                        <th style="width: 70px" class="px-2">Laporan KKN</th>
                        <th style="width: 70px" class="px-2">Jurnal</th>
                        <th style="width: 70px" class="px-2">Artikel Berita</th>
                    </tr>
                </template>

                <template v-slot:tbody>
                    <tr class="text-center">
                        <td>
                            <StatusMuted />
                        </td>
                        <td>
                            <StatusMuted
                                v-if="rekapan.file_1 == '-' || rekapan.file_2 == '-' || rekapan.file_3 == '-'" />
                            <StatusWarning v-else-if="rekapan.formulir == 'MENUNGGU'" />
                            <StatusSuccess v-else-if="rekapan.formulir == 'TERVERIFIKASI'" />
                        </td>
                        <td>
                            <StatusMuted v-if="rekapan.proker == '-'" />
                            <StatusWarning v-else-if="rekapan.proker == 'MENUNGGU'" />
                            <StatusSuccess v-else-if="rekapan.proker == 'TERVERIFIKASI'" />
                        </td>
                        <td>
                            <StatusMuted v-if="rekapan.survei == '-'" />
                            <StatusWarning v-else-if="rekapan.survei == 'MENUNGGU'" />
                            <StatusSuccess v-else-if="rekapan.survei == 'TERVERIFIKASI'" />
                        </td>
                        <td>
                            <span v-if="rekapan.laporan_harian > 0"
                                :class="rekapan.laporan_harian_menunggu > 0 ? 'text-muted' : 'text-success'">
                                {{ rekapan.laporan_harian }}
                            </span>
                            <span class="text-danger" v-else>0</span>
                            / 31
                        </td>
                        <td>
                            <StatusMuted />
                        </td>
                        <td>
                            <StatusMuted />
                        </td>
                        <td>
                            <StatusMuted />
                        </td>
                        <td>
                            <StatusMuted />
                        </td>
                        <td>
                            <StatusMuted />
                        </td>
                        <td>
                            <StatusMuted />
                        </td>
                        <td>
                            <StatusMuted />
                        </td>
                    </tr>
                </template>
            </BodyTableVue>
        </div>

        <div class="row">
            <div class="col-xl-3 col-md-4">
                <Menu judul="Tahap 1" deskripsi="Program Kerja & Survei" warna="bg-azure" @click="halaman = 'Tahap1'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-time"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
                        <circle cx="18" cy="18" r="4"></circle>
                        <path d="M15 3v4"></path>
                        <path d="M7 3v4"></path>
                        <path d="M3 11h16"></path>
                        <path d="M18 16.496v1.504l1 1"></path>
                    </svg>
                </Menu>

                <Menu judul="Tahap 2" deskripsi="Laporan Harian" warna="bg-indigo" @click="halaman = 'Tahap2'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-history-toggle"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 8v4l3 3"></path>
                        <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
                        <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
                        <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
                        <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
                        <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
                        <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
                        <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
                        <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
                    </svg>
                </Menu>

                <Menu judul="Tahap 3" deskripsi="Laporan Mingguan" warna="bg-purple" @click="halaman = 'Tahap3'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-history-toggle"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 8v4l3 3"></path>
                        <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
                        <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
                        <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
                        <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
                        <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
                        <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
                        <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
                        <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
                    </svg>
                </Menu>

                <Menu judul="Tahap 4" deskripsi="Laporan Akhir KKN Tematik" warna="bg-pink" @click="halaman = 'Tahap4'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-report" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697"></path>
                        <path d="M18 14v4h4"></path>
                        <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2"></path>
                        <rect x="8" y="3" width="6" height="4" rx="2"></rect>
                        <circle cx="18" cy="18" r="4"></circle>
                        <path d="M8 11h4"></path>
                        <path d="M8 15h3"></path>
                    </svg>
                </Menu>
            </div>

            <div class="col-xl-9 col-md-8">
                <ProgramKerja v-if="halaman == 'Tahap1'" :load="load1" :mahasiswa="mahasiswa" :nim="nim" :token="token"
                    warna="bg-azure" />
                <LaporanHarian v-else-if="halaman == 'Tahap2'" :mahasiswa="mahasiswa" :token="token"
                    warna="bg-indigo" />
                <LaporanMingguan v-else-if="halaman == 'Tahap3'" :mahasiswa="mahasiswa" :token="token"
                    warna="bg-purple" />
                <LaporanAkhir v-else-if="halaman == 'Tahap4'" :mahasiswa="mahasiswa" :token="token" warna="bg-pink" />
            </div>
        </div>
    </modal-show>
</template>

<script>
    import axios from 'axios'
    import BodyTableVue from '@/components/table/BodyTable.vue'
    import ButtonModal from '@/components/modal/ButtonModal.vue'
    import ShowModal from '@/components/modal/ShowModal.vue'
    import Menu from '@/components/menu/Menu.vue'
    import Alert from '@/components/alert/Alert.vue'
    import ProgramKerja from '@/views/dosen/penilaian/ProgramKerjaView.vue'
    import LaporanHarian from '@/views/dosen/penilaian/LaporanHarianView.vue'
    import LaporanMingguan from '@/views/dosen/penilaian/LaporanMingguanView.vue'
    import LaporanAkhir from '@/views/dosen/penilaian/LaporanAkhirView.vue'
    import StatusSuccess from '@/components/status/Success.vue'
    import StatusWarning from '@/components/status/Warning.vue'
    import StatusMuted from '@/components/status/Muted.vue'

    export default {
        name: 'PesertaPenilaian',
        components: {
            BodyTableVue,
            ButtonModal,
            ShowModal,
            Menu,
            Alert,
            ProgramKerja,
            LaporanHarian,
            LaporanMingguan,
            LaporanAkhir,
            StatusSuccess,
            StatusWarning,
            StatusMuted,
        },
        data() {
            return {
                token: sessionStorage.getItem('token'),
                peran: sessionStorage.getItem('peran'),
                id_kelompok: sessionStorage.getItem('id_kelompok'),
                halaman: 'Tahap1',
                lis_data: {},
                kelompok: {},
                mahasiswa: {},
                nim: '1606099',
                rekapan: {},
                penilaian: {
                    pembekalan: '',
                    proker: '',
                    pelaksanaan: '',
                    kinerja: '',
                    laporan: '',
                    angka: 0,
                    huruf: '-',
                },
                load1: false
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            async getData() {
                await axios.get(`penilaian/list?id_kelompok=${this.id_kelompok}`, {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        const data = res.data

                        this.kelompok.id = data.id
                        this.kelompok.nama = data.nama
                        this.kelompok.desa = data.desa
                        this.kelompok.alamat = data.alamat

                        this.lis_data = data.anggota.sort((a, b)=> {
                            return a.nama.localeCompare(b.nama)
                        })
                        
                        this.nim = this.lis_data[0].nim

                    })
            },
            async showData(nim) {
                this.nim = nim
                await axios.get(`mahasiswa/show/${nim}`, {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        this.mahasiswa = res.data.data
                        this.penilaian = this.mahasiswa.penilaian
                    })

                await axios.get(`penilaian/show/${nim}`, {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        this.rekapan = res.data.data
                    })

                this.load1 = true
            },
            hitung() {
                this.penilaian.angka = (this.penilaian.pembekalan * (10 / 100)) + (this.penilaian.proker * (5 / 100)) +
                    (this.penilaian.pelaksanaan * (10 / 100)) + (this.penilaian.kinerja * (70 / 100)) + (this.penilaian.laporan * (5 / 100))

                    if (this.penilaian.angka >= 80) {
                        this.penilaian.huruf = 'A'
                    } else if (this.penilaian.angka < 80 && this.penilaian.angka >= 75) {
                        this.penilaian.huruf = 'AB'
                    } else if (this.penilaian.angka < 75 && this.penilaian.angka >= 70) {
                        this.penilaian.huruf = 'B'
                    } else if (this.penilaian.angka < 70 && this.penilaian.angka >= 65) {
                        this.penilaian.huruf = 'BC'
                    } else if (this.penilaian.angka < 65 && this.penilaian.angka >= 60) {
                        this.penilaian.huruf = 'C'
                    } else if (this.penilaian.angka < 60 && this.penilaian.angka >= 55) {
                        this.penilaian.huruf = 'D'
                    } else {
                        this.penilaian.huruf = 'E'
                    }
            },
            async simpan() {
                let formData = new FormData();

                formData.append('nim', this.mahasiswa.nim)
                formData.append('proker', this.penilaian.proker)
                formData.append('pelaksanaan', this.penilaian.pelaksanaan)
                formData.append('kinerja', this.penilaian.kinerja)
                formData.append('laporan', this.penilaian.laporan)
                formData.append('angka', this.penilaian.angka)
                formData.append('huruf', this.penilaian.huruf)

                await axios.post('penilaian/store', formData, {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        if (res.data.status) {
                            this.$toast.success('Penilaian Berhasil Disimpan')

                            this.getData()
                        }
                    })
                    .catch(err => {
                        this.$toast.error('Penilaian Gagal Disimpan')
                    })
            },
            async hadirPembekalan(nim) {
                let formData = new FormData()
                formData.append('nim', nim)

                await axios.post(`penilaian/pembekalan`, formData, {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        if (res.data.status) {
                            this.$toast.success('Mahasiswa berhasil dihadirkan')

                            this.getData()
                        }
                    })
                    .catch(err => {
                        this.$toast.error('Penilaian Gagal Disimpan')
                    })
            }
        }
    }
</script>