import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// base url
import axios from 'axios'
axios.defaults.baseURL = 'https://apikkn.itg.ac.id/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/';

// sweetaler2
import swal from 'sweetalert2';
window.Swal = swal;

// tabler
import '@tabler/core/dist/css/tabler.css'
import '@tabler/core/dist/js/tabler.js'

// toastr
import Toaster from "@meforma/vue-toaster";

// component
import Header from '@/components/menu/Header.vue'
import Menu from '@/components/menu/Menu.vue'
import StatusSuccess from '@/components/status/Success.vue'
import StatusWarning from '@/components/status/Warning.vue'
import StatusMuted from '@/components/status/Muted.vue'
import InfografisRekapan from '@/components/infografis/Rekapan.vue'
import BodyTable from '@/components/table/BodyTable.vue'
import ModalButton from '@/components/modal/ButtonModal.vue'
import ModalShow from '@/components/modal/ShowModal.vue'

const app = createApp(App)

// component
app.component('menu-header', Header)
app.component('menu-button', Menu)

app.component('status-success', StatusSuccess)
app.component('status-warning', StatusWarning)
app.component('status-muted', StatusMuted)

app.component('infografis-rekapan', InfografisRekapan)
app.component('table-body', BodyTable)
app.component('modal-button', ModalButton)
app.component('modal-show', ModalShow)

app.use(Toaster, { position: 'top'})
app.use(router).mount('#app')
