<template>
  <div class="card-body">
      <div class="input-icon">
          <input type="text" class="form-control" :placeholder="placeholder" :value="search">

          <span class="input-icon-addon">
              <svg xmlns="http://www.w3.org/2000/svg" v-if="!searching" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <circle cx="10" cy="10" r="7"></circle>
                  <line x1="21" y1="21" x2="15" y2="15"></line>
              </svg>

              <div class="spinner-border spinner-border-sm text-muted" role="status" v-if="searching"></div>
          </span>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'SearchTable',
    props: ['placeholder', 'search', 'searching'],
  }
</script>