<template>
  <footer class="footer footer-transparent d-print-none">
    <div class="container-xl">
      <div class="d-flex justify-content-between flex-column flex-md-row text-center">
        <span class="mb-2 mb-md-0">Copyright &copy; 2022 Institut Teknologi Garut</span>
        <span>
          Dibuat dengan 
          <span class="text-red">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-heart icon-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M19.5 12.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572"></path>
            </svg> 
          </span>
          <br>Lembaga Sistem Informasi dan Pangkalan Data
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>