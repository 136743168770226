<template>
  <div class="card">
    <Header judul="Profil" deskripsi="Data Dasar Mahasiswa" warna="bg-blue" />

    <div class="card-body">

      <form @submit.prevent="postProfile">
        <div class="row mb-1">
          <label for="nim" class="col-form-label col-xl-3 col-md-4">NIM</label>
          <div class="col-xl-9 col-md-8">
            <input type="text" name="nim" id="nim" class="form-control" readonly v-model="mahasiswa.nim">
          </div>
        </div>

        <div class="row mb-1">
          <label for="nama" class="col-form-label col-xl-3 col-md-4">Nama Lengkap</label>
          <div class="col-xl-9 col-md-8">
            <input type="text" name="nama" id="nama" class="form-control" readonly v-model="mahasiswa.nama">
          </div>
        </div>

        <div class="row mb-1">
          <label for="tempat_lahir" class="col-form-label col-xl-3 col-md-4">Tempat Tanggal Lahir</label>
          <div class="col-xl-9 col-md-8">
            <input type="text" name="tempat_lahir" id="tempat_lahir" class="form-control" readonly v-model="mahasiswa.ttl">
          </div>
        </div>

        <div class="row mb-1">
          <label for="jenis_kelamin" class="col-form-label col-xl-3 col-md-4">Jenis Kelamin</label>
          <div class="col-xl-9 col-md-8">
            <input type="text" id="prodi" class="form-control" readonly v-model="mahasiswa.jenis_kelamin">
          </div>
        </div>

        <div class="row mb-1">
          <label for="prodi" class="col-form-label col-xl-3 col-md-4">Program Studi</label>
          <div class="col-xl-9 col-md-8">
            <input type="text" id="prodi" class="form-control" readonly v-model="mahasiswa.prodi">
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Alert from '@/components/alert/Alert.vue'
  import Header from '@/components/menu/Header.vue'

  export default {
    name: 'MahasiswaFormulirProfil',
    components: {
      Alert,
      Header
    },
    data() {
      return {
        token      : sessionStorage.getItem('token'),
        lis_agama  : ['Islam', 'Kristen'],
        lis_kelamin: ['L', 'P'],
        lis_ukuran : ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
        message    : 'Tunggu sampai semua data berhasil diambil',
        mahasiswa  : {
          nim          : '',
          nama         : '',
          tempat_lahir : '',
          tanggal_lahir: '',
          agama        : '',
          jenis_kelamin: '',
          program_studi: '',
          semester     : '',
          ipk          : '',
          no_hp        : '',
          email        : '',
          alamat       : '',
          ukuran_kameja: '',
          sks          : '',
        },
      }
    },
    mounted() {
      this.getProfile()
    },
    methods: {
      async getProfile() {
        await axios.get('mahasiswa/profile', {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {

            setTimeout(() => {
              this.mahasiswa = res.data.data
              this.message =
                'Semua isian profil akan dijadikan acuan dalam pengumpulan data, pastikan semua data telah benar'
            }, 1000);

          })
      },
      async postProfile() {
        let formData = new FormData()

        formData.append('tempat_lahir', this.mahasiswa.tempat_lahir)
        formData.append('tanggal_lahir', this.mahasiswa.tanggal_lahir)
        formData.append('agama', this.mahasiswa.agama)
        formData.append('jenis_kelamin', this.mahasiswa.jenis_kelamin)
        formData.append('no_hp', this.mahasiswa.no_hp)
        formData.append('alamat', this.mahasiswa.alamat)
        formData.append('ukuran_kameja', this.mahasiswa.ukuran_kameja)

        axios.post('mahasiswa/updateProfile', formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success(`Perubahan profil berhasil disimpan`);
            }
          })
          .catch(res => {
            console.log( res )
            this.$toast.error(`Perubahan profil gagal disimpan`);
          })
      }
    }
  }
</script>

<style>

</style>