<template>
  <button class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="modal_target">{{ modal_name }}</button>
</template>

<script>
export default {
  name: 'ButtonModal',
  props: ['modal_target', 'modal_name']
}
</script>

<style>

</style>