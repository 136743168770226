<template>
  <div class="card">
    <Header warna="bg-indigo" judul="Tahap 2" deskripsi="Laporan Harian" />

    <div class="card-body">
      
      <Alert status="alert-secondadry" v-if="loading">
        Tunggu sampai semua data berhasil diambil
      </Alert>

      <div class="d-flex gap-2" v-else>
        <ButtonModal modal_name="Tambah" modal_target="#modalLaporan" v-if="!sudah_lapor" />
        <ButtonModal modal_name="Laporan Hari ke-27	" modal_target="#modalLaporanCustom" />
      </div>

      <p class="mt-2 text-danger">Batas pengisian laporan sampai jam 21.00</p>
    </div>

    <BodyTable>
      <template v-slot:thead>
        <tr class="text-center">
          <th rowspan="2">Laporan</th>
          <th rowspan="2">Tanggal</th>
          <th rowspan="2">Bukti Kegiatan</th>
          <th rowspan="2">Deskripsi Kegiatan</th>
          <th colspan="3">Validasi</th>
          <th rowspan="2">Aksi</th>
        </tr>

        <tr class="text-center">
          <th>Pembimbing</th>
          <th>Prodi</th>
          <th>LPPM</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="(data, index) in lis_data" :key="index">
          <td class="align-top">{{ data.judul }}</td>
          <td class="align-top">{{ data.tanggal }}</td>
          <td class="align-top text-center">
            <span v-if="data.catatan != null">
              <a :href="data.file" class="btn btn-secondary" target="_blank">Lihat</a>
            </span>
          </td>
          <td class="align-top text-wrap"><span v-if="data.catatan != null">{{ data.catatan }}</span></td>
          <td class="align-top text-center">
            <StatusSuccess v-if="data.verifikasi_dosen != null" />
            <status-warning v-else-if="data.catatan != null" />
            <StatusMuted v-else />
          </td>
          <td class="align-top text-center">
            <StatusSuccess v-if="data.verifikasi_prodi != null" />
            <status-warning v-else-if="data.catatan != null" />
            <StatusMuted v-else />
          </td>
          <td class="align-top text-center">
            <StatusSuccess v-if="data.verifikasi_lppm != null" />
            <status-warning v-else-if="data.catatan != null" />
            <StatusMuted v-else />
          </td>
          <td class="align-top text-center px-2">
            <span v-if="data.catatan != null && (data.verifikasi_dosen == null && data.verifikasi_prodi == null && data.verifikasi_lppm == null)">
              <button class="btn btn-danger" type="button" @click.prevent="deleteData(data.id)">Hapus</button>
            </span>
          </td>
        </tr>
      </template>
    </BodyTable>
  </div>

  <ShowModal modal_id="modalLaporan" modal_name="Tambah Laporan Harian">
    <form @submit.prevent="formLogBook">

      <div class="row mb-2">
        <label class="col-md-4 col-form-label">Bukti Kegiatan</label>
        <div class="col-md-8">
          <input type="file" id="file" class="form-control" accept=".jpg, .jpeg, .png">
          <small class="text-danger font-size-1">Format File (JPG, JPEG, PNG), Ukuran Maksimal 1 MB</small>
        </div>
      </div>

      <div class="row mb-1">
        <label class="col-md-4 col-form-label">Deskripsi Kegiatan</label>
        <div class="col-md-8">
          <textarea rows="5" class="form-control" v-model="logbook.catatan" @input="limitCharacter" required></textarea>
          <small class="text-danger font-size-1">Maksimum Karakter {{ 500 - logbook.catatan.length }} / 500</small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </div>
    </form>
  </ShowModal>

  <ShowModal modal_id="modalLaporanCustom" modal_name="Tambah Laporan Hari ke-27">
    <form @submit.prevent="formLogBookCustom">

      <div class="row mb-2">
        <label class="col-md-4 col-form-label">Bukti Kegiatan</label>
        <div class="col-md-8">
          <input type="file" id="fileCustom" class="form-control" accept=".jpg, .jpeg, .png">
          <small class="text-danger font-size-1">Format File (JPG, JPEG, PNG), Ukuran Maksimal 1 MB</small>
        </div>
      </div>

      <div class="row mb-1">
        <label class="col-md-4 col-form-label">Deskripsi Kegiatan</label>
        <div class="col-md-8">
          <textarea rows="5" class="form-control" v-model="logbook.catatan" @input="limitCharacter" required></textarea>
          <small class="text-danger font-size-1">Maksimum Karakter {{ 500 - logbook.catatan.length }} / 500</small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </div>
    </form>
  </ShowModal>
</template>

<script>
  import axios from 'axios'
  import Alert from '@/components/alert/Alert.vue'
  import BodyTable from '@/components/table/BodyTable.vue'
  import ButtonModal from '@/components/modal/ButtonModal.vue'
  import ShowModal from '@/components/modal/ShowModal.vue'
  import StatusSuccess from '@/components/status/Success.vue'
  import StatusWarning from '@/components/status/Warning.vue'
  import StatusMuted from '@/components/status/Muted.vue'
  import Header from '@/components/menu/Header.vue'

  export default {
    name: 'LaporanHarian',
    components: {
      Alert,
      BodyTable,
      ButtonModal,
      ShowModal,
      StatusSuccess,
      StatusWarning,
      StatusMuted,
      Header
    },
    data() {
      return {
        token: sessionStorage.getItem('token'),
        lis_data: {},
        logbook: {
          catatan: ''
        },
        loading: true,
        sudah_lapor: true,
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      async getData() {
        this.loading = true
        await axios.get('laporan/harian/showMHS', {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.lis_data = res.data.data
            this.loading = false

            this.lis_data.forEach(e => {
              const sekarang = new Date();
              const tahun = sekarang.getFullYear();
              const bulan = String(sekarang.getMonth() + 1).padStart(2, '0'); // Penambahan +1 karena bulan dimulai dari 0
              const tanggal = String(sekarang.getDate()).padStart(2, '0');
              const tanggal_sekarang = `${tahun}-${bulan}-${tanggal}`;

              if( tanggal_sekarang == e.tanggal ) {
                if( e.catatan == null ) {
                  this.sudah_lapor = false
                }
              }
            });

            if( this.lis_data.length == 0 ) {
              this.sudah_lapor = false
            }
          })
      },
      limitCharacter() {
        this.logbook.catatan = this.logbook.catatan.substr(0, 500)
      },
      async formLogBook() {
        const file = document.querySelector('#file')

        const ukuran = file.files[0].size < 1048576
        if(! ukuran ) {
          this.$toast.error('Ukuran file melebihi batas ketentuan')
          file.value = ''
        } else {
          let formData = new FormData()
          const judul = this.lis_data.length ? this.lis_data.length + 1 : 1;
          
          formData.append('file', file.files[0])
          formData.append('catatan', this.logbook.catatan)
          formData.append('judul', `Hari ke-${judul}`)

          await axios.post(`laporan/harian/store`, formData, {
            headers: {
              'Authorization' : `Bearer ${this.token}`
            }
          })
          .then( res => {
            this.sudah_lapor = true
            this.getData()
            if( res.data.status == true ) {
              this.$toast.success('Laporan harian berhasil ditambahkan')
              this.logbook.catatan = ''
              file.value = ''
            } else {
              this.$toast.error('Laporan harian gagal ditambahkan')
            }
          })
        }
      },
      async formLogBookCustom() {
        const file = document.querySelector('#fileCustom')

        const ukuran = file.files[0].size < 1048576
        if(! ukuran ) {
          this.$toast.error('Ukuran file melebihi batas ketentuan')
          file.value = ''
        } else {
          let formData = new FormData()
          const judul = this.lis_data.length ? this.lis_data.length + 1 : 1;
          
          formData.append('file', file.files[0])
          formData.append('catatan', this.logbook.catatan)
          formData.append('judul', `Hari ke-${judul}`)

          await axios.post(`laporan/harian/storeCustom`, formData, {
            headers: {
              'Authorization' : `Bearer ${this.token}`
            }
          })
          .then( res => {
            this.sudah_lapor = true
            this.getData()
            if( res.data.status == true ) {
              this.$toast.success('Laporan harian berhasil ditambahkan')
              this.logbook.catatan = ''
              file.value = ''
            } else {
              this.$toast.error('Laporan harian gagal ditambahkan')
            }
          })
        }
      },
      async deleteData(id) {
        let formData = new FormData()
        formData.append('id', id)

        axios.post(`laporan/harian/delete`, formData, {
            headers: {
              'Authorization' : `Bearer ${this.token}`
            }
          })
          .then( res => {
            if( res.status == 200 ) {
              this.$toast.success('Laporan Harian Berhasil Dihapus')

              this.getData()
            }
          })
      }
    }
  }
</script>

<style>
  .font-size-1 {
    font-size: 11px;
  }
</style>