<template>
	<div class="card">
		<div class="card-body" v-if="peran == 'ADMIN' || peran == 'LPPM'">
			<ButtonModal modal_target="#modalKelompok" modal_name="Tambah" @click="addKelompok" />
		</div>

		<BodyTable>
			<template v-slot:thead>
				<tr class="text-center">
					<th rowspan="3" style="width: 40px" class="px-0">No</th>
					<th rowspan="3">Kelompok</th>
					<th rowspan="3">Dosen Pembimbing</th>
					<th colspan="12">Jumlah Peserta</th>
					<th rowspan="3" class="px-0" style="width: 100px">Aksi</th>
				</tr>
				<tr class="text-center">
					<th colspan="2" style="width: 85px; padding: 0">T. INFORMATIKA</th>
					<th colspan="2" style="width: 85px; padding: 0">T. INDUSTRI</th>
					<th colspan="2" style="width: 85px; padding: 0">T. SIPIL</th>
					<th colspan="2" style="width: 85px; padding: 0">SISTEM INFORMASI</th>
					<th colspan="2" style="width: 85px; padding: 0">ARSITEKTUR</th>
					<th colspan="2" style="width: 85px; padding: 0">TOTAL</th>
				</tr>
				<tr class="text-center">
					<th style="padding: 0">L</th>
					<th style="padding: 0">P</th>
					<th style="padding: 0">L</th>
					<th style="padding: 0">P</th>
					<th style="padding: 0">L</th>
					<th style="padding: 0">P</th>
					<th style="padding: 0">L</th>
					<th style="padding: 0">P</th>
					<th style="padding: 0">L</th>
					<th style="padding: 0">P</th>
					<th style="padding: 0">L</th>
					<th style="padding: 0">P</th>
				</tr>
			</template>

			<template v-slot:tbody>
				<tr v-for="(data, index) in lis_data" :key="index">
					<td>{{ index + 1}}</td>
					<td>{{ data.nama }}<span class="text-muted d-block">{{ data.desa }}</span></td>
					<td>{{ data.nama_dosen }}<span class="text-muted d-block">NIDN</span></td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_t_informatika_l }}</span> / {{ data.t_informatika_l }}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_t_informatika_p }}</span> / {{ data.t_informatika_p }}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_t_industri_l }}</span> / {{ data.t_industri_l }}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_t_industri_p }}</span> / {{ data.t_industri_p }}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_t_sipil_l }}</span> / {{ data.t_sipil_l }}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_t_sipil_p }}</span> / {{ data.t_sipil_p }}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_sistem_informasi_l }}</span> / {{ data.sistem_informasi_l }}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_sistem_informasi_p }}</span> / {{ data.sistem_informasi_p }}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_arsitektur_l }}</span> / {{ data.arsitektur_l}}</td>
					<td class="text-center"><span class="text-danger">{{ data.anggota_arsitektur_p }}</span> / {{ data.arsitektur_p }}</td>
					<td class="text-center bg-muted"><span class="text-warning">{{ data.jumlah_anggota_l }}</span> / {{ data.kapasitas_anggota_l }}</td>
					<td class="text-center bg-muted"><span class="text-warning">{{ data.jumlah_anggota_p }}</span> / {{ data.kapasitas_anggota_p }}</td>
					<td class="px-2">
						<ButtonModal modal_target="#modalAnggotaKelompok" modal_name="Ubah" @click="showKelompok(data.id); loading = true"  v-if="peran == 'ADMIN' || peran == 'LPPM'" />
						<router-link :to="{name: 'peserta-detail'}" class="btn btn-secondary ms-2" @click="setIdKelompok(data.id)">Detail</router-link>
						<router-link :to="{name: 'peserta-penilaian'}" class="btn btn-secondary ms-2" @click="setIdKelompok(data.id)">Penilaian</router-link>
					</td>
				</tr>
			</template>
		</BodyTable>
	</div>

	<ShowModal modal_id="modalKelompok" modal_name="Tambah Kelompok">
		<form @submit.prevent="formKelompok">
			<div class="row mb-1">
				<label class="col-md-4 col-form-label">Nama Kelompok</label>
				<div class="col-md-8">
					<input type="text" class="form-control" v-model="kelompok.nama" required>
				</div>
			</div>

			<div class="hr-text hr-text-left hr-text-spaceless my-3">
				Jumlah Peserta
			</div>

			<div class="row mb-2">
				<label class="col-md-4 col-form-label">Teknik Informatika</label>
				<div class="col-md-8">
					<div class="d-flex gap-3">
						<div class="d-flex gap-2 align-items-center">
							<span>L</span>
							<input type="number" class="form-control" v-model="kelompok.t_informatika_l" required>
						</div>
						<div class="d-flex gap-2 align-items-center">
							<span>P</span>
							<input type="number" class="form-control" v-model="kelompok.t_informatika_p" required>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-2">
				<label class="col-md-4 col-form-label">Teknik Industri</label>
				<div class="col-md-8">
					<div class="d-flex gap-3">
						<div class="d-flex gap-2 align-items-center">
							<span>L</span>
							<input type="number" class="form-control" v-model="kelompok.t_industri_l" required>
						</div>
						<div class="d-flex gap-2 align-items-center">
							<span>P</span>
							<input type="number" class="form-control" v-model="kelompok.t_industri_p" required>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-2">
				<label class="col-md-4 col-form-label">Teknik Sipil</label>
				<div class="col-md-8">
					<div class="d-flex gap-3">
						<div class="d-flex gap-2 align-items-center">
							<span>L</span>
							<input type="number" class="form-control" v-model="kelompok.t_sipil_l" required>
						</div>
						<div class="d-flex gap-2 align-items-center">
							<span>P</span>
							<input type="number" class="form-control" v-model="kelompok.t_sipil_p" required>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-2">
				<label class="col-md-4 col-form-label">Sistem Informasi</label>
				<div class="col-md-8">
					<div class="d-flex gap-3">
						<div class="d-flex gap-2 align-items-center">
							<span>L</span>
							<input type="number" class="form-control" v-model="kelompok.sistem_informasi_l" required>
						</div>
						<div class="d-flex gap-2 align-items-center">
							<span>P</span>
							<input type="number" class="form-control" v-model="kelompok.sistem_informasi_p" required>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-2">
				<label class="col-md-4 col-form-label">Arsitektur</label>
				<div class="col-md-8">
					<div class="d-flex gap-3">
						<div class="d-flex gap-2 align-items-center">
							<span>L</span>
							<input type="number" class="form-control" v-model="kelompok.arsitektur_l" required>
						</div>
						<div class="d-flex gap-2 align-items-center">
							<span>P</span>
							<input type="number" class="form-control" v-model="kelompok.arsitektur_p" required>
						</div>
					</div>
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-md-4"></div>
				<div class="col-md-8">
					<input type="submit" value="Tambah" class="btn btn-primary">
				</div>
			</div>
		</form>
	</ShowModal>

	<ShowModal modal_id="modalAnggotaKelompok" modal_name="Ubah Kelompok" modal_size="modal-lg">
		<Alert status="alert-secondary" v-if="loading">
			Tunggu sampai data berhasil di load.
		</Alert>

		<div v-else>
			<form @submit.prevent="updateKelompok">
				<div class="row mb-1">
					<label class="col-md-3 col-form-label">Nama Kelompok</label>
					<div class="col-md-9">
						<input type="text" class="form-control" v-model="kelompok.nama">
					</div>
				</div>

				<div class="row mb-1">
					<label class="col-md-3 col-form-label">Dosen Pembimbing</label>
					<div class="col-md-9">
						<select class="form-select" v-model="kelompok.id_dosen">
							<option v-for="dosen in lis_dosen" :key="dosen.id" :value="dosen.id">
								{{ dosen.nama }}
							</option>
						</select>
					</div>
				</div>

				<div class="row mb-1">
					<label class="col-md-3 col-form-label">Nama Desa</label>
					<div class="col-md-9">
						<input type="text" class="form-control" v-model="kelompok.desa">
					</div>
				</div>

				<div class="row mb-1">
					<label class="col-md-3 col-form-label">Alamat Desa</label>
					<div class="col-md-9">
						<textarea rows="3" class="form-control" v-model="kelompok.alamat"></textarea>
					</div>
				</div>

				<div class="hr-text hr-text-left hr-text-spaceless my-3">
					Jumlah Peserta
				</div>

				<div class="row mb-2">
					<label class="col-md-3 col-form-label">Teknik Informatika</label>
					<div class="col-md-9">
						<div class="d-flex gap-3">
							<div class="d-flex gap-2 align-items-center">
								<span>L</span>
								<input type="number" class="form-control" v-model="kelompok.t_informatika_l" required>
							</div>
							<div class="d-flex gap-2 align-items-center">
								<span>P</span>
								<input type="number" class="form-control" v-model="kelompok.t_informatika_p" required>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-2">
					<label class="col-md-3 col-form-label">Teknik Industri</label>
					<div class="col-md-9">
						<div class="d-flex gap-3">
							<div class="d-flex gap-2 align-items-center">
								<span>L</span>
								<input type="number" class="form-control" v-model="kelompok.t_industri_l" required>
							</div>
							<div class="d-flex gap-2 align-items-center">
								<span>P</span>
								<input type="number" class="form-control" v-model="kelompok.t_industri_p" required>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-2">
					<label class="col-md-3 col-form-label">Teknik Sipil</label>
					<div class="col-md-9">
						<div class="d-flex gap-3">
							<div class="d-flex gap-2 align-items-center">
								<span>L</span>
								<input type="number" class="form-control" v-model="kelompok.t_sipil_l" required>
							</div>
							<div class="d-flex gap-2 align-items-center">
								<span>P</span>
								<input type="number" class="form-control" v-model="kelompok.t_sipil_p" required>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-2">
					<label class="col-md-3 col-form-label">Sistem Informasi</label>
					<div class="col-md-9">
						<div class="d-flex gap-3">
							<div class="d-flex gap-2 align-items-center">
								<span>L</span>
								<input type="number" class="form-control" v-model="kelompok.sistem_informasi_l" required>
							</div>
							<div class="d-flex gap-2 align-items-center">
								<span>P</span>
								<input type="number" class="form-control" v-model="kelompok.sistem_informasi_p" required>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-2">
					<label class="col-md-3 col-form-label">Arsitektur</label>
					<div class="col-md-9">
						<div class="d-flex gap-3">
							<div class="d-flex gap-2 align-items-center">
								<span>L</span>
								<input type="number" class="form-control" v-model="kelompok.arsitektur_l" required>
							</div>
							<div class="d-flex gap-2 align-items-center">
								<span>P</span>
								<input type="number" class="form-control" v-model="kelompok.arsitektur_p" required>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-3"></div>
					<div class="col-md-9">
						<input type="submit" value="Simpan" class="btn btn-primary">
					</div>
				</div>
			</form>

			<div class="table-responsive">
				<table class="table table-vcenter table-bordered table-hover text-nowrap mt-3 mb-0">
					<thead>
						<tr class="text-center">
							<th>No.</th>
							<th>Mahasiswa</th>
							<th>Program Studi</th>
							<th class="px-0" style="width: 80px">Aksi</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="(anggota_kelompok, index) in kelompok.data_anggota" :key="index">
							<td class="text-right" style="width: 30px">{{ index + 1}}</td>
							<td>{{ anggota_kelompok.nama }}<br><span
									class="text-muted">{{ anggota_kelompok.nim }}</span></td>
							<td>{{ anggota_kelompok.prodi }}</td>
							<td>
								<button class="btn btn-danger"
									@click="deletePeserta(anggota_kelompok.id, anggota_kelompok.nim)">Hapus</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

	</ShowModal>

</template>

<script>
	import axios from 'axios'
	import ButtonModal from '@/components/modal/ButtonModal.vue'
	import ShowModal from '@/components/modal/ShowModal.vue'
	import BodyTable from '@/components/table/BodyTable.vue'
	import Alert from '../../components/alert/Alert.vue'
	import Swal from 'sweetalert2'

	export default {
		name: 'AdminPeserta',
		components: {
			ButtonModal,
			ShowModal,
			BodyTable,
			Alert
		},
		data() {
			return {
				token: sessionStorage.getItem('token'),
				peran: sessionStorage.getItem('peran'),
				lis_data: [],
				lis_dosen: [],
				lis_mahasiswa: [],
				kelompok: {},
				id_kelompok: '-',
				loading: false
			}
		},
		mounted() {
			this.getData()
			this.getDosen()
			this.getMahasiswa()
		},
		methods: {
			async getData() {
				await axios.get('kelompok', {
						headers: {
							'Authorization': `Bearer ${this.token}`
						}})
					.then(res => {
						this.lis_data = res.data.data.sort((a, b)=> {
							let reA = /[^a-zA-Z]/g;
							let reN = /[^0-9]/g;

							let aA = a.nama.replace(reA, '');
							let bA = b.nama.replace(reA, '');

							if( aA === bA ) {
								let aN = parseInt( a.nama.replace(reN, ''), 10);
								let bN = parseInt( b.nama.replace(reN, ''), 10);

								return aN === bN ? 0 : aN > bN ? 1 : -1;
							} else {
								return aA . bA ? 1 : -1;
							}
						})
					})
			},
			async getDosen() {
				await axios.get('dosen/list', {
						headers: {
							'Authorization': `Bearer ${this.token}`
						}
					})
					.then(res => {
						this.lis_dosen = res.data.data
					})
			},
			async getMahasiswa() {
				await axios.get('mahasiswa', {
						headers: {
							'Authorization': `Bearer ${this.token}`
						}
					})
					.then(res => {
						this.lis_mahasiswa = res.data.data
					})
			},
			addKelompok() {
				this.kelompok = {}
			},
			async formKelompok() {
				await axios.post('kelompok/store', this.kelompok, {
						headers: {
							'Authorization': `Bearer ${this.token}`
						}
					})
					.then(res => {
						this.getData()

						this.kelompok = {}

						this.$toast.success('Kelompok berhasil ditambahkan')
					})
			},
			async showKelompok(id) {
				await axios.get(`kelompok/show/${id}`, {
						headers: {
							'Authorization': `Bearer ${this.token}`
						}
					})
					.then(res => {
						this.kelompok = res.data

						setTimeout(() => {
							this.loading = false
						}, 1000);
					})
			},

			async updateKelompok() {
				await axios.post(`kelompok/update/${this.kelompok.id}`, this.kelompok, {
						headers: {
							'Authorization': `Bearer ${this.token}`
						}
					})
					.then(res => {
						if( res.status == 200 ) {
							this.$toast.success('Data berhasil disimpan')
						} else {
							this.$toast.error('Data gagal disimpan')
						}

						this.getData()
					})
			},

			async formUploadKelompok() {
				let formData = new FormData()
				let file = document.querySelector(`#file_import_anggota`)

				formData.append('id', this.id_kelompok)
				formData.append('file', file.files[0])

				axios.post('anggota/import', formData, {
						headers: {
							'Authorization': `Bearer ${this.token}`
						}
					})
					.then(res => {
						if (res.data.status) {
							this.$toast.success('Anggota kelompok berhasil diimport')

							file.value = ''

							this.getData()
							this.showKelompok(this.id_kelompok)
						}
					})
			},
			async deletePeserta(id, nim) {
				Swal.fire({
					icon: 'question',
					text: `Hapus peserta dengan nim ${nim} ?`,
					showCancelButton: true,
					cancelButtonText: 'Batal'
				}).then(res => {
					if (res.isConfirmed) {
						let formData = new FormData()
						formData.append('id', id)

						axios.post(`anggota/delete`, formData, {
								headers: {
									'Authorization': `Bearer ${this.token}`
								}
							})
							.then(res => {
								this.showKelompok(id)
								this.getData()
								if (res.status == 200) {
									Swal.fire({
										icon: 'success',
										text: 'Peserta tersebut berhasil dihapus',
										timer: 1500,
										showConfirmButton: false,
									});
								}
							})
					}
				})
			},
			setIdKelompok(id) {
				sessionStorage.setItem('id_kelompok', id)
			}
		}
	}
</script>

<style>

</style>