<template>
   <div class="alert alert-info mb-3">
      Pengecekan dan validasi dilakukan pada halaman Pesreta KKN
   </div>

   <div class="card">
      <table-body>
         <template #thead>
            <tr class="text-center">
               <th rowspan="2" style="width: 30px">No</th>
               <th rowspan="2">Mahasiswa</th>
               <th colspan="4">Laporan Akhir</th>
            </tr>

            <tr class="text-center">
               <th>Laporan KKN</th>
               <th>Jurnal</th>
               <th>Artikel Berita</th>
            </tr>
         </template>

         <template #tbody>
            <tr v-for="(d, index) in data" :key="index">
               <td class="text-right">{{ index + 1 }}</td>
               <td>{{ d.nama }}<span class="text-muted"><br>{{ d.nim }}<br>{{ d.prodi }}<br>{{ d.kelompok }}</span></td>
               <td class="text-center">
                  <status-success v-if="d.laporan_akhir == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.laporan_akhir == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
               <td class="text-center">
                  <status-success v-if="d.jurnal == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.jurnal == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
               <td class="text-center">
                  <status-success v-if="d.artikel_berita == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.artikel_berita == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
            </tr>
         </template>
      </table-body>
   </div>
</template>

<script>
   export default {
      name: 'DasborDetail5',
      props: ['data']
   }
</script>

<style>

</style>