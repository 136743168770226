<template>
    <div class="card">
        <Header :warna="warna" :judul="judul" :deskripsi="deskripsi" />

        <div class="card-body">

            <Alert status="alert-scondary" v-if="loading">
                Tunggu sampai data berhasil diload
            </Alert>

            <div v-else>
                <div v-if="peran != 'DOSEN'">
                    <Alert status="alert-success" v-if="statusVerifikasi">
                        Formulir KKN Sudah Diverifikasi
                    </Alert>
    
                    <Alert status="alert-danger"
                        v-else-if="mahasiswa.ajukan == null">
                        Mahasiswa Belum Mengunggah Formulir
                    </Alert>
    
                    <Alert status="alert-warning" v-else>
                        Formulir KKN Belum Diverifikasi
                    </Alert>
                </div>

                <form>
                    <div class="row mb-1">
                        <label for="nim" class="col-form-label col-xl-3 col-md-4">NIM</label>
                        <div class="col-xl-9 col-md-8">
                            <input type="text" name="nim" id="nim" class="form-control" readonly
                                v-model="mahasiswa.nim">
                        </div>
                    </div>

                    <div class="row mb-1">
                        <label for="nama" class="col-form-label col-xl-3 col-md-4">Nama Lengkap</label>
                        <div class="col-xl-9 col-md-8">
                            <input type="text" name="nama" id="nama" class="form-control" readonly
                                v-model="mahasiswa.nama">
                        </div>
                    </div>

                    <div class="row mb-1">
                        <label for="tempat_lahir" class="col-form-label col-xl-3 col-md-4">Tempat Tanggal Lahir</label>
                        <div class="col-xl-9 col-md-8">
                            <input type="text" name="tempat_lahir" id="tempat_lahir" class="form-control" readonly
                                v-model="mahasiswa.ttl">
                        </div>
                    </div>

                    <div class="row mb-1">
                        <label for="jenis_kelamin" class="col-form-label col-xl-3 col-md-4">Jenis Kelamin</label>
                        <div class="col-xl-9 col-md-8">
                            <input type="text" id="prodi" class="form-control" readonly
                                v-model="mahasiswa.jenis_kelamin">
                        </div>
                    </div>

                    <div class="row mb-1">
                        <label for="prodi" class="col-form-label col-xl-3 col-md-4">Program Studi</label>
                        <div class="col-xl-9 col-md-8">
                            <input type="text" id="prodi" class="form-control" readonly
                                v-model="mahasiswa.prodi">
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label class="col-form-label col-xl-3 col-md-4">Formulir</label>
                        <div class="col-xl-9 col-md-8">
                            <div class="mb-2" v-if="mahasiswa.file_1 != '-'">
                                Dokumen 1 : Formulir Pendaftaran
                                <br>
                                <a :href="mahasiswa.file_1" target="_blank" class="btn btn-secondary"
                                    v-if="mahasiswa.ajukan != null">Lihat</a>
                            </div>
                            <div class="mb-2" v-if="mahasiswa.file_2 != '-'">
                                Dokumen 2 : Surat Izin Keluarga
                                <br>
                                <a :href="mahasiswa.file_2" target="_blank" class="btn btn-secondary"
                                    v-if="mahasiswa.ajukan != null">Lihat</a>
                            </div>
                            <div class="mb-2" v-if="mahasiswa.file_3 != '-'">
                                Dokumen 3 : Surat Pernyataan
                                <br>
                                <a :href="mahasiswa.file_3" target="_blank" class="btn btn-secondary"
                                    v-if="mahasiswa.ajukan != null">Lihat</a>
                            </div>
                        </div>
                    </div>

                    <div v-if="peran != 'DOSEN'">
                        <div class="row mb-1"
                            v-if="mahasiswa.file_1 != '-' || mahasiswa.file_2 != '-' || mahasiswa.file_3 != '-' ">
                            <div class="col-xl-3 col-md-4"></div>
                            <div class="col-xl-9 col-md-8">
                                <div class="d-flex gap-2" v-if="mahasiswa.ajukan != null">
                                    <input type="button" value="VERIFIKASI" class="btn btn-success d-block mb-1"
                                        @click="verifikasiFormulir(mahasiswa.id)"
                                        v-if="tombolVerifikasi">
                                    <button type="button" class="btn btn-danger d-block mb-1"
                                        @click="batalVerifikasiFormulir(mahasiswa.id)" >
                                        TOLAK VERIFIKASI
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import Alert from '@/components/alert/Alert.vue'
    import Header from '@/components/menu/Header.vue'
    import axios from 'axios'

    export default {
        name: 'PesertaProfil',
        props: [
            'warna',
            'judul',
            'deskripsi',
            'mahasiswa'
        ],
        components: {
            Alert,
            Header
        },
        data() {
            return {
                loading: false,
                token: sessionStorage.getItem('token'),
                peran: sessionStorage.getItem('peran'),
                lis_agama: ['Islam', 'Kristen'],
                lis_kelamin: ['L', 'P'],
                lis_ukuran: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
            }
        },
        computed: {
            tombolVerifikasi() {
                if( this.mahasiswa.ajukan != null ) {
                    if( this.peran == "PRODI" ) {
                        return this.mahasiswa.verifikasi_prodi == null
                    } else if ( this.peran == "LPPM" ) {
                        return this.mahasiswa.verifikasi_lppm == null
                    }
                } else {
                    return false
                }
            },

            tombolBatalVerifikasi() {
                if( this.mahasiswa.ajukan != null ) {
                    if( this.peran == "PRODI" ) {
                        return this.mahasiswa.verifikasi_prodi != null && this.mahasiswa.verifikasi_lppm == null
                    } else if ( this.peran == "LPPM" ) {
                        return this.mahasiswa.verifikasi_lppm != null
                    }
                } else {
                    return false
                }
            },

            statusVerifikasi() {
                if( this.mahasiswa.ajukan != null ) {
                    if( this.peran == "PRODI" ) {
                        return this.mahasiswa.verifikasi_prodi != null
                    } else if ( this.peran == "LPPM" ) {
                        return this.mahasiswa.verifikasi_lppm != null
                    }
                } else {
                    return false
                }
            }
        },
        methods: {
            async verifikasiFormulir(id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Verifikasi Formulir KKN ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal'
                }).then(res => {
                    if (res.isConfirmed) {
                        let formData = new FormData()
                        formData.append('peran', this.peran)

                        axios.post(`verifikasi/mahasiswa/${id}`, formData, {
                                headers: {
                                    'Authorization': `Bearer ${this.token}`
                                }
                            })
                            .then(res => {
                                if (res.data.status) {
                                    this.$toast.success('Formulir KKN berhasil diverifikasi')
                                    this.$emit('update-data', true)
                                }
                            })
                    }
                })
            },
            async batalVerifikasiFormulir(id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Tolak verifikasi Formulir KKN ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal'
                }).then(res => {
                    if (res.isConfirmed) {
                        let formData = new FormData()
                        formData.append('peran', this.peran)

                        axios.post(`verifikasi/mahasiswa/${id}/batal`, formData, {
                                headers: {
                                    'Authorization': `Bearer ${this.token}`
                                }
                            })
                            .then(res => {
                                if (res.data.status) {
                                    this.$toast.success('Formulir KKN berhasil ditolak')
                                    this.$emit('update-data', true)
                                    setTimeout(() => {
                                        window.location.reload();
                                    }, 1500);
                                }
                            })
                    }
                })
            },
        }
    }
</script>

<style>

</style>