<template>
   <div class="alert alert-info mb-3">
      Pengecekan dan validasi dilakukan pada halaman Dosen
   </div>

   <div class="card">
      <table-body>
         <template #thead>
            <tr class="text-center">
               <th rowspan="2" style="width: 40px" class="px-2">No.</th>
               <th rowspan="2">Dosen</th>
               <th colspan="4">Formulir</th>
            </tr>

            <tr class="text-center">
               <th style="width: 70px" class="px-2">1</th>
               <th style="width: 70px" class="px-2">2</th>
               <th style="width: 70px" class="px-2">3</th>
               <th style="width: 70px" class="px-2">4</th>
            </tr>
         </template>

         <template #tbody>
            <tr v-for="(d, index) in data" :key="index">
               <td>{{ index + 1}}</td>
               <td>{{ d.nama }}</td>
               <td class="text-center px-2">
                  <status-success v-if="d.formulir_status == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.file_1 != '-'" />
                  <status-muted v-if="d.file_1 == '-'" />
               </td>
               <td class="text-center px-2">
                  <status-success v-if="d.formulir_status == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.file_2 != '-'" />
                  <status-muted v-if="d.file_2 == '-'" />
               </td>
               <td class="text-center px-2">
                  <status-success v-if="d.formulir_status == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.file_3 != '-'" />
                  <status-muted v-if="d.file_3 == '-'" />
               </td>
               <td class="text-center px-2">
                  <status-success v-if="d.formulir_status == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="d.file_4 != '-'" />
                  <status-muted v-if="d.file_4 == '-'" />
               </td>
            </tr>
         </template>
      </table-body>
   </div>
</template>

<script>
   export default {
      name: 'DasborDetail1',
      props: ['data']
   }
</script>

<style>

</style>