<template>
  <div class="card">
    <menu-header warna="bg-green" judul="Formulir" deskripsi="Formulir KKN Tematik" />

    <div class="card-body">
      <div class="row">
        <div class="col-md-5">
          <div class="table-responsive">
            <table class="table card-table table-bordered table-hover table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>validator</th>
                  <th>status</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Program Studi</td>
                  <td>
                    <span v-if="dosen.ajukan == null || dosen.file_1 == '-' || dosen.file_2 == '-' || dosen.file_3 == '-'">
                      -
                    </span>
                    <span v-else-if="dosen.verifikasi_prodi == null">
                      Menunggu Validasi
                    </span>
                    <span v-else>
                      {{ dosen.verifikasi_prodi }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>LPPM</td>
                  <td>
                    <span v-if="dosen.ajukan == null || dosen.file_1 == '-' || dosen.file_2 == '-' || dosen.file_3 == '-'">
                      -
                    </span>
                    <span v-else-if="dosen.verifikasi_lppm == null">
                      Menunggu Validasi
                    </span>
                    <span v-else>
                      {{ dosen.verifikasi_lppm }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-7">
          <div class="alert alert-warning" v-if="dosen.ajukan == null">
        Unduh dokumen berikut untuk selanjutnya diisi dan ditandatangani
      </div>

      <a target="_blank" href="https://apikkn.itg.ac.id/public/formulir_dosen.pdf" class="btn btn-secondary mb-3" v-if="dosen.ajukan == null" download>Unduh Template Formulir</a>

      <div class="alert alert-warning" v-if="dosen.ajukan == null">
        Ungah dokumen yang telah ditandatangani
      </div>

      <div class="alert alert-success" v-if="dosen.status == 'TERVERIFIKASI'">
        Formulir KKN Tematik Telah Diverifikasi
      </div>

      <form>
        <div class="mb-3">
          <label class="form-label mb-1" for="file_1">Dokumen 1 : Formulir Pendaftaran</label>
          <div class="input-group" v-if="dosen.ajukan == null">
            <input type="file" id="file_1" class="form-control" accept=".pdf" required>
            <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_1')">Unggah</button>
          </div>
          <div class="mt-2" v-if="dosen.file_1 != '-'">
            <a :href="dosen.file_1" target="_blank" class="btn btn-secondary">Lihat Formulir</a>
            <button type="button" class="btn btn-danger ms-2" @click="batalUpload(dosen.id_kkn, 'file_1')">Hapus</button>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label mb-1" for="file_3">Dokumen 2 : Surat pernyataan kesanggupan mengikuti kegiatan sampai tuntas</label>
          <div class="input-group" v-if="dosen.ajukan == null">
            <input type="file" id="file_3" class="form-control" accept=".pdf" required>
            <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_3')">Unggah</button>
          </div>
          <div class="mt-2" v-if="dosen.file_3 != '-'">
            <a :href="dosen.file_3" target="_blank" class="btn btn-secondary">Lihat Formulir</a>
            <button type="button" class="btn btn-danger ms-2" @click="batalUpload(dosen.id_kkn, 'file_3')">Hapus</button>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label mb-1" for="file_4">Dokumen 3 : Surat pernyataan tidak akan melakukan kegiatan politik praktis di lokasi</label>
              <div class="input-group" v-if="dosen.ajukan == null">
                <input type="file" id="file_4" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_4')">Unggah</button>
              </div>
              <div class="mt-2" v-if="dosen.file_4 != '-'">
                <a :href="dosen.file_4" target="_blank" class="btn btn-secondary">Lihat Formulir</a>
                <button type="button" class="btn btn-danger ms-2" @click="batalUpload(dosen.id_kkn, 'file_4')">Hapus</button>
              </div>
            </div>
          </form>
          <p class="text-danger mt-3" style="font-size: 11px">Format File (PDF), Ukuran Maksimal 1 MB</p>
          <button 
            class="btn btn-primary w-100" 
            v-if="dosen.ajukan == null && dosen.file_1 != '-' && dosen.file_3 != '-' && dosen.file_4 != '-'"
            @click="postAjukan"
            >Ajukan</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'DosenFormulirFormulir',
    data() {
      return {
        token: sessionStorage.getItem('token'),
        dosen: {
          status: '-',
          file_1: '-',
          file_2: '-',
          file_3: '-',
          file_4: '-',
          file_5: '-',
        },
      }
    },
    mounted() {
      this.getProfile()
    },
    methods: {
      async getProfile() {
        await axios.get('dosen/profile', {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {

            setTimeout(() => {
              this.dosen = res.data.data
              this.message =
                'Semua isian profil akan dijadikan acuan dalam pengumpulan data, pastikan semua data telah benar'
            }, 1000);

          })
      },
      unggah(dokumen) {
        let formData = new FormData()
        let file = document.querySelector(`#${dokumen}`)

        formData.append('dokumen', dokumen)
        formData.append('file', file.files[0])

        axios.post('register/dosen', formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Formulir berhasil diunduh')

              file.value = ''

              this.getProfile()
            }
          })
      },
      batalUpload(id, dokumen) {
        Swal.fire({
          icon: 'question',
          text: 'Hapus Formulir KKN ?',
          showCancelButton: true,
          cancelButtonText: 'Batal'
        }).then( res => {
          if( res.isConfirmed ) {
            let formData = new FormData()
    
            formData.append('id', id)
            formData.append('dokumen', dokumen)
    
            axios.post('register/dosen/batalUpload', formData, {
                headers: {
                  'Authorization': `Bearer ${this.token}`
                }
              })
              .then(res => {
                if (res.data.status) {
                  this.$toast.success('Formulir berhasil dihapus')
                  this.getProfile()
                }
              })
          }
        })
      },

      postAjukan() {
        Swal.fire({
          icon: 'question',
          title: 'Ajukan Formulir KKN',
          text: 'Klik tombol ajukan untuk melanjutkan proses pengajuan formulir KKN',
          showCancelButton: true,
          cancelButtonText: 'Batal',
          confirmButtonText: 'Ajukan'
        }).then( e => {
          if( e.isConfirmed ) {
            axios.post('register/dosen/ajukan', {
              id: this.dosen.id
            }, {
                headers: {
                  'Authorization': `Bearer ${this.token}`
                }
              })
              .then(res => {
                if (res.data.status) {
                  this.$toast.success('Formulir berhasil diajukan')
                  this.getProfile()
                }
              })
          }
        })
      },


    }
  }
</script>

<style>

</style>