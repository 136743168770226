<template>
   <div class="card border-0 shadow-sm">
      <div class="card-body">
         <table class="table table-bordered table-hover table-responsive">
            <thead>
               <tr>
                  <th style="width: 50px">No.</th>
                  <th>Tanggal</th>
                  <th>URL Butki Kegiatan</th>
                  <th>Catatan</th>
                  <th>Status</th>
                  <th>Verifikasi</th>
               </tr>
            </thead>

            <tbody>
               <tr v-for="(d, index) in data" :key="index">
                  <td>{{ index + 1}}</td>
                  <td>{{ d.tanggal }}</td>
                  <td>
                     <a :href="d.url" target="_blank" class="btn btn-secondary">Lihat</a>
                  </td>
                  <td>{{ d.catatan }}</td>
                  <td>{{ d.status }}</td>
                  <td>
                     <button class="btn btn-primary" @click="verifikasi(d.id)" v-if="d.status == 'MENUNGGU'">Verifikasi</button>
                     <button class="btn btn-danger" @click="verifikasiBatal(d.id)" v-else>BatalkanVerifikasi</button>
                  </td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</template>

<script>
   import axios from 'axios'
   
   export default {
      name: 'CekMahasiswaView',
      data() {
         return {
            data   : {},
            laporan: {},
            token  : sessionStorage.getItem('token'),
            id_laporan: '-'
         }
      }, 
      mounted() {
         this.getData()
      },
      methods: {
         async getData() {
            await axios.get('laporan/mahasiswa', {headers: {'Authorization' : `Bearer ${this.token}`}})
               .then( res => {
                  this.data = res.data.data
               })
         },
         async verifikasi(id) {
            let formData = new FormData()
            formData.append('id', id)

            await axios.post(`laporan/mahasiswa/verifikasi`, formData, { headers: {'Authorization': `Bearer ${this.token}`} })
               .then( res => {
                  this.getData()
                  
                  Swal.fire({
                     icon: 'success',
                     text: 'Kegiatan mahasiswa berhasil diverifikasi',
                     timer: 5000
                  })
               })
               .catch(err=> {
                  console.log( err )
               })
         },
         async verifikasiBatal(id) {
            let formData = new FormData()
            formData.append('id', id)

            await axios.post(`laporan/mahasiswa/verifikasi/batal`, formData, { headers: {'Authorization': `Bearer ${this.token}`} })
               .then( res => {
                  this.getData()
                  
                  Swal.fire({
                     icon: 'success',
                     text: 'Kegiatan mahasiswa berhasil diverifikasi',
                     timer: 5000
                  })
               })
               .catch(err=> {
                  console.log( err )
               })
         }
      }
   }
</script>

<style>

</style>