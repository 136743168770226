<template>
  <Alert status="alert-secondary" v-if="loading">
    Tunggu sampai data berhasil diload
  </Alert>
  
  <div v-else>
    <Alert status="alert-danger" v-if="!status_validasi">
      Administrasi keuangan belum divalidasi, pastikan telah tervalidasi untuk melanjutkan ke tahap selanjutnya.
    </Alert>

    <Alert status="alert-danger" v-else-if="!status_kelompok">
      Untuk membuka halaman ini pastikan telah memilih kelompok pada menu kelompok.
    </Alert>

    
    <Alert status="alert-danger" v-else-if="!status_dosen">
      Halaman belum dapat diakses.
    </Alert>

    <div class="row" v-else>
      <div class="col-xl-3 col-md-4">
        <Menu judul="Tahap 1" deskripsi="Program Kerja & Survei" warna="bg-azure" @click="halaman = 'Tahap1'">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-time" width="24"
            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
            <circle cx="18" cy="18" r="4"></circle>
            <path d="M15 3v4"></path>
            <path d="M7 3v4"></path>
            <path d="M3 11h16"></path>
            <path d="M18 16.496v1.504l1 1"></path>
          </svg>
        </Menu>
  
        <Menu judul="Tahap 2" deskripsi="Laporan Harian" warna="bg-indigo" @click="halaman = 'Tahap2'">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-history-toggle" width="24"
            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 8v4l3 3"></path>
            <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
            <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
            <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
            <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
            <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
            <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
            <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
            <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
          </svg>
        </Menu>
  
        <Menu judul="Tahap 3" deskripsi="Laporan Mingguan" warna="bg-purple" @click="halaman = 'Tahap3'">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-history-toggle" width="24"
            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 8v4l3 3"></path>
            <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
            <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
            <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
            <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
            <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
            <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
            <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
            <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
          </svg>
        </Menu>
  
        <Menu judul="Tahap 4" deskripsi="Laporan Akhir" warna="bg-pink" @click="halaman = 'Tahap4'">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-report" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697"></path>
            <path d="M18 14v4h4"></path>
            <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2"></path>
            <rect x="8" y="3" width="6" height="4" rx="2"></rect>
            <circle cx="18" cy="18" r="4"></circle>
            <path d="M8 11h4"></path>
            <path d="M8 15h3"></path>
          </svg>
        </Menu>
      </div>
  
      <div class="col-xl-9 col-md-8">
        <Proker v-if="halaman == 'Tahap1'" />
  
        <LaporanHarian v-else-if="halaman == 'Tahap2'" />
  
        <LaporanMinggun v-else-if="halaman == 'Tahap3'" />
  
        <LaporanAkhir v-else-if="halaman == 'Tahap4'" />
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Menu from '@/components/menu/Menu.vue'
  import Alert from '@/components/alert/Alert.vue'
  import ButtonModal from '@/components/modal/ButtonModal.vue'
  import ShowModal from '@/components/modal/ShowModal.vue'
  import BodyTable from '@/components/table/BodyTable.vue'

  import Proker from '@/views/mahasiswa/logbook/ProkerView.vue'
  import LaporanHarian from '@/views/mahasiswa/logbook/LaporanHarianView.vue'
  import LaporanMinggun from '@/views/mahasiswa/logbook/LaporanMinggunView.vue'
  import LaporanAkhir from '@/views/mahasiswa/logbook/LaporanAkhirView.vue'

  export default {
    name: 'MahasiswaLogBook',
    components: {
      Menu,
      Alert,
      ButtonModal,
      ShowModal,
      BodyTable,
      Proker,
      LaporanHarian,
      LaporanMinggun,
      LaporanAkhir,
    },
    data() {
      return {
        halaman: 'Tahap1',
        lis_laporan_mingguan: {},
        sisa: 0,
        logbook: {catatan: ''},
        token: sessionStorage.getItem('token'),
        proker: {
          status: 'TERVERIFIKASI'
        },
        survei: {
          status: 'TERVERIFIKASI'
        },
        loading: true,
        status_validasi: false,
        status_kelompok: false,
        status_dosen: false,
      }
    },

    mounted() {
      this.getStatusValidasi()
    },

    methods: {
      async getStatusValidasi() {
        this.loading = true
        await axios.get('validasiBak/statusValidasi', {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).then( res => {
          this.status_validasi = res.data.status
          this.status_kelompok = res.data.kelompok
          this.status_dosen = res.data.dosen

          setTimeout(() => {
            this.loading = false
          }, 1000);
        })
      }
    }
  }
</script>

<style>
  .font-size-1 {
    font-size: 11px;
  }
</style>