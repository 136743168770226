<template>
	<div class="card border-0 shadow-sm">
		<div class="card-body">
			<div class="d-flex justify-content-between mb-3">
				<ButtonModal modal_target="#modalPengumuman" modal_name="Tambah" />
			</div>

			<ShowModal modal_id="modalPengumuman" modal_name="Tambah Pengumuman">
				<form @submit.prevent="formPengumuman">

					<div class="row mb-1">
						<label class="col-md-4 col-form-label">Tanggal</label>
						<div class="col-md-8">
							<input type="date" class="form-control" v-model="pengumuman.tanggal">
						</div>
					</div>

					<div class="row mb-1">
						<label class="col-md-4 col-form-label">Judul</label>
						<div class="col-md-8">
							<input type="text" class="form-control" v-model="pengumuman.judul">
						</div>
					</div>

					<div class="row mb-1">
						<label class="col-md-4 col-form-label">Jenis Pengumuman</label>
						<div class="col-md-8">
							<select class="form-select" v-model="pengumuman.jenis_file_pendukung">
								<option v-for="(jenis_file, index) in lis_jenis_file" :key="index">{{ jenis_file }}</option>
							</select>
						</div>
					</div>

					<div class="row mb-1" v-if="pengumuman.jenis_file_pendukung == 'FILE'">
						<label class="col-md-4 col-form-label">File Pengumuman</label>
						<div class="col-md-8">
							<input type="file" class="form-control" accept=".pdf, .png, .jpg, .jpeg" id="filePengumuman">
						</div>
					</div>
					
					<div class="row mb-1" v-else>
						<label class="col-md-4 col-form-label">URL Pengumuman</label>
						<div class="col-md-8">
							<input type="url" class="form-control" v-model="pengumuman.url"/>
						</div>
					</div>

					<div class="row mb-1">
						<label class="col-md-4 col-form-label">Isi</label>
						<div class="col-md-8">
							<textarea rows="5" class="form-control" v-model="pengumuman.isi"></textarea>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col-md-4"></div>
						<div class="col-md-8">
							<button class="btn btn-primary">Tambah</button>
						</div>
					</div>
				</form>
			</ShowModal>

			<div v-if="lis_data.length">
				<div class="row">
					<Alert2 v-for="(data, index) in lis_data" :data="data" :key="index" />
				</div>
			</div>

			<div v-else>
				<div class="alert alert-warning">
					Tidak ada pengumuman untuk ditampilkan
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	import ButtonModal from '@/components/modal/ButtonModal.vue'
	import ShowModal from '@/components/modal/ShowModal.vue'
	import Alert2 from '@/components/alert/Alert2.vue'

	export default {
		name: 'Pengumuman',
		components: {
			ButtonModal,
			ShowModal,
			Alert2
		},
		data() {
			return {
				token: sessionStorage.getItem('token'),
				lis_data: [],
				lis_jenis_file: ['FILE', 'URL'],
				pengumuman: {
					tanggal: '',
					judul: '',
					jenis_file_pendukung: 'FILE',
					isi: '',
					url: ''
				}
			}
		},
		created() {
			this.getData()
		},
		methods: {
			formReset() {
				this.pengumuman = {
					tanggal: '',
					judul: '',
					jenis_file_pendukung: 'FILE',
					isi: '',
					url: ''
				}
			},
			async getData() {
				await axios.get('pengumuman', {
						headers: {
							"Authorization": 'Bearer ' + this.token
						}
					})
					.then(res => {
						this.lis_data = res.data.data
					})
			},
			async formPengumuman() {
				
				let formData = new FormData()
				formData.append('tanggal', this.pengumuman.tanggal)
				formData.append('judul', this.pengumuman.judul)
				formData.append('jenis_file_pendukung', this.pengumuman.jenis_file_pendukung)
				formData.append('isi', this.pengumuman.isi)

				switch (this.pengumuman.jenis_file_pendukung) {
					case 'FILE':
						let file = document.querySelector('#filePengumuman')
						formData.append('file', file.files[0])
						break;
				
					default:
						formData.append('url', this.pengumuman.url)
						break;
				}

				axios.post('pengumuman/store', formData, {
						headers: {
							'Authorization': `Bearer ${this.token}`
						}
					})
					.then(res => {
						if (res.data.status) {
							Swal.fire({
								icon: 'success',
								text: 'Pengumuman berhasil ditambahkan',
								timer: 5000
							})

							this.formReset()
							this.getData()
							file.value = ''
						}
					})
			}
		}
	}
</script>

<style>

</style>