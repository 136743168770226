<template>

   <!-- Jika Seudah Login -->
   <div v-if="isLogin == 'true'">
      <!-- navbar -->
      <Navbar :peran="peran" />

      <!-- content -->
      <div class="page-wrapper">
         <div class="page-body">
            <div class="container-xl">
               <router-view />
            </div>
         </div>
      </div>

      <!-- footer -->
      <Footer />
   </div>

   <!-- Jika Belum Login -->
   <div v-else>
      <Login />
   </div>
</template>

<script>
   import Navbar from '@/components/Navbar.vue'
   import Footer from '@/components/Footer.vue'
   import Login from '@/views/LoginView.vue'

   export default {
      name: 'App',
      components: {
         Navbar,
         Footer,
         Login
      },
      data() {
         return {
            isLogin: sessionStorage.getItem('isLogin'),
            peran: sessionStorage.getItem('peran')
         }
      },
      beforeMount() {
         // document.addEventListener('contextmenu', event => {
         //    event.preventDefault()
         // })
      }
   }
</script>