<template>
  <div class="card">
    <SearchTableVue placeholder="Cari data mahasiswa ..." />

    <BodyTableVue>
      <template v-slot:thead>
        <tr class="text-center">
          <th rowspan="2" style="width: 50px">No.</th>
          <th rowspan="2">Mahasiswa</th>
          <th rowspan="2">Kelompok</th>
          <th colspan="4">Kegiatan</th>
          <th colspan="3">Laporan</th>
          <th rowspan="2" style="width: 90px" class="px-0">Aksi</th>
        </tr>

        <tr class="text-center">
          <th class="px-3" style="width: 50px">1</th>
          <th class="px-3" style="width: 50px">2</th>
          <th class="px-3" style="width: 50px">3</th>
          <th class="px-3" style="width: 50px">4</th>
          <th class="px-3" style="width: 50px">File 1</th>
          <th class="px-3" style="width: 50px">File 2</th>
          <th class="px-3" style="width: 50px">FIle 3</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr>
          <td>1</td>
          <td>Nama Mahasiswa<br><span class="text-muted">NIM</span></td>
          <td>Nama Kelompok<br><span class="text-muted">Nama Desa</span></td>
          <td class="text-center p-0">
            <span class="text-success">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-checks" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M7 12l5 5l10 -10"></path>
                <path d="M2 12l5 5m5 -5l5 -5"></path>
              </svg>
            </span>
          </td>
          <td class="text-center p-0">
            <span class="text-warning">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
            </span>
          </td>
          <td class="text-center p-0">
            <span class="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-question-mark" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                <line x1="12" y1="19" x2="12" y2="19.01"></line>
              </svg>
            </span>
          </td>
          <td class="text-center p-0">
            <span class="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-question-mark" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                <line x1="12" y1="19" x2="12" y2="19.01"></line>
              </svg>
            </span>
          </td>
          <td class="text-center p-0">
            <span class="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-question-mark" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                <line x1="12" y1="19" x2="12" y2="19.01"></line>
              </svg>
            </span>
          </td>
          <td class="text-center p-0">
            <span class="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-question-mark" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                <line x1="12" y1="19" x2="12" y2="19.01"></line>
              </svg>
            </span>
          </td>
          <td class="text-center p-0">
            <span class="text-muted">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-question-mark" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
                <line x1="12" y1="19" x2="12" y2="19.01"></line>
              </svg>
            </span>
          </td>
          <td class="text-center px-2">
            <ButtonModal modal_name="Detail" modal_target="#modalDetailLogBook" />
          </td>
        </tr>
      </template>
    </BodyTableVue>
  </div>

  <ShowModal modal_id="modalDetailLogBook" modal_name="Detail Log Book Mahasiswa" modal_size="modal-dialog modal-full-width modal-dialog-scrollable">
    
  </ShowModal>
</template>
  
<script>
import SearchTableVue from '@/components/table/SearchTable.vue'
import BodyTableVue from '@/components/table/BodyTable.vue'
import ButtonModal from '@/components/modal/ButtonModal.vue'
import ShowModal from '@/components/modal/ShowModal.vue'

export default {
  name: 'LogBookAdminMahasiswaView',
  components: {
    SearchTableVue,
    BodyTableVue,
    ButtonModal,
    ShowModal
}
}
</script>

<style>

</style>