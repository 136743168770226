<template>
  <DosenProfil v-if="peran == 'DOSEN'" />
  <MahasiswaProfil v-else-if="peran == 'MAHASISWA'" />
</template>

<script>
  import DosenProfil from '@/views/dosen/ProfilView.vue'
  import MahasiswaProfil from '@/views/mahasiswa/ProfilView.vue'

  export default {
    name: 'Profil',
    components: {
      DosenProfil,
      MahasiswaProfil
    },
    data() {
      return {
        peran: sessionStorage.getItem('peran')
      }
    }
  }
</script>

<style>

</style>