<template>
   <div class="card">
      <div class="card-body">
         <div class="d-flex" v-if="sisa > 0">
            <ButtonModal modal_name="Tambah" modal_target="#modalLogBook"/>
         </div>

         <Alert status="alert-secondary mt-3" v-if="loading">
            Tunggu sampai data berhasil diload
         </Alert>
      </div>

      <BodyTable v-if="! loading">
         <template v-slot:thead>
            <tr class="text-center">
               <th style="width: 150px">Lob Book</th>
               <th style="width: 150px">Tanngal</th>
               <th>Bukti Kunjungan</th>
               <th>Catatan</th>
               <th style="width: 150px">Status</th>
               <th style="width: 150px">Aksi</th>
            </tr>
         </template>

         <template v-slot:tbody>
            <tr v-for="(d, index) in data" :key="index">
               <td>{{ d.judul }}</td>
               <td class="text-center">{{ d.tanggal }}</td>
               <td class="text-center">
                  <a :href="d.file" target="_blank" class="btn btn-secondary">Lihat</a>
               </td>
               <td>
                  {{ d.catatan }}
               </td>
               <td class="text-center">
                  <status-success v-if="d.status == 'TERVERIFIKASI'" />
                  <status-warning v-else />
               </td>
               <td class="text-center">
                  <button type="button" class="btn btn-danger" v-if="d.status == 'MENUNGGU'" @click="deleteData(d.id)">Hapus</button>
               </td>
            </tr>

            <tr v-for="i in sisa" :key="i">
               <td>Kunjungan ke-{{ data.length + i }}</td>
               <td class="text-center">-</td>
               <td class="text-center">-</td>
               <td class="text-center">-</td>
               <td class="text-center">
                  <status-muted />
               </td>
               <td class="text-center">-</td>
            </tr>
         </template>
      </BodyTable>
   </div>

   <ShowModal modal_id="modalLogBook" modal_name="Tambah Log Book Baru">
      <form @submit.prevent="formLaporan">

         <div class="row mb-1">
            <label class="col-md-4 col-form-label">Bukti Kunjungan</label>
            <div class="col-md-8">
               <input type="file" id="file" class="form-control" accept=".jpg, .jpeg, .png">
               <small class="text-danger font-size-1">Format File (JPG, JPEG, PNG), Ukuran Maksimal 1 MB</small>
            </div>
         </div>

         <div class="row mb-1">
            <label class="col-md-4 col-form-label">Catatan</label>
            <div class="col-md-8">
               <textarea rows="5" class="form-control" v-model="laporan.catatan"></textarea>
               <small class="text-danger font-size-1">Maksimum Karakter {{ 500 - laporan.catatan.length }} / 500</small>
            </div>
         </div>

         <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-8">
               <button type="submit" class="btn btn-primary">Tambah</button>
            </div>
         </div>
      </form>
   </ShowModal>
</template>

<script>
   import axios from 'axios'
   import ButtonModal from '@/components/modal/ButtonModal.vue'
   import ShowModal from '@/components/modal/ShowModal.vue'
   import Alert from '@/components/alert/Alert.vue'
   import BodyTable from '@/components/table/BodyTable.vue'

   export default {
      name: 'DosenLogBook',
      components: {
         ButtonModal,
         ShowModal,
         Alert,
         BodyTable
      },
      data() {
         return {
            data   : {},
            laporan: {catatan: '-'},
            token  : sessionStorage.getItem('token'),
            sisa   : 0,
            loading: false
         }
      },
      mounted() {
         this.loading = true
         this.getData()
      },
      methods: {
         async getData() {
            await axios.get('laporan/showDPL', {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  this.data = res.data.data
                  this.sisa = 6 - this.data.length

                  setTimeout(() => {
                     this.loading = false
                  }, 1000);
               })
         },
         async formLaporan() {
            let formData = new FormData()
            let file = document.querySelector(`#file`)

            formData.append('judul', `Kunjungan ke-${this.data.length + 1}`)
            formData.append('catatan', this.laporan.catatan)
            formData.append('file', file.files[0])

            axios.post('laporan/dosen/store', formData, {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  if (res.data.status) {
                     this.getData()
                     this.$toast.success('Laporan berhasil dihapus')
                     file.value   = ''
                     this.laporan = {catatan: '-'}
                  }
               })
         },
         async deleteData(id) {
            let formData = new FormData()
            
            formData.append('id', id)
            axios.post(`laporan/dosen/delete`, formData, {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  if (res.data.status) {
                     this.getData()
                     this.$toast.success('Laporan berhasil dihapus')
                  }
               })
         }
      }
   }
</script>

<style>
  .font-size-1 {
    font-size: 11px;
  }
</style>