<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="card-title mb-1">
            <span class="text-reset">{{ judul }}</span>
          </h3>
          <div class="mt-3">
            <div class="row g-2 align-items-center">
              <div class="col-auto">
                {{ persen.toFixed(2) }}%
              </div>
              <div class="col">
                <div class="progress progress-sm">
                <div class="progress-bar" v-bind:style="{'width': persen+'%'}" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <span class="visually-hidden">{{ total.toFixed }}% Selesai</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="dropdown">
            <a href="#" class="card-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <!-- Download SVG icon from http://tabler-icons.io/i/dots-vertical -->
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="12" cy="12" r="1" />
                <circle cx="12" cy="19" r="1" />
                <circle cx="12" cy="5" r="1" /></svg>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InfografisRekapan',
    props: ['warna', 'jumlah', 'judul', 'total', 'jumlah', 'persen'],
  }
</script>