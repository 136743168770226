<template>
  <div class="alert alert-info mb-3">
    Pengecekan dan validasi dilakukan pada halaman Pesreta KKN
  </div>

  <div class="table-responsive">
    <table class="table table-bordered table-hover table-vcenter">
      <thead>
        <tr class="text-center">
          <th rowspan="2" style="width: 30px">No</th>
          <th rowspan="2">Mahasiswa</th>
          <th colspan="4">Laporan Akhir</th>
        </tr>

        <tr class="text-center">
          <th>Laporan KKN</th>
          <th>Jurnal</th>
          <th>Artikel Berita</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(data, index) in lis_data" :key="index">
          <td class="text-right">{{ index + 1 }}</td>
          <td>{{ data.nama }}<span class="text-muted"><br>{{ data.nim }}<br>{{ data.prodi }}</span></td>
          <td class="text-center">
            <status-success v-if="data.laporan_akhir == 'TERVERIFIKASI'" />
            <status-warning v-else-if="data.laporan_akhir == 'MENUNGGU'" />
            <status-muted v-else />
          </td>
          <td class="text-center">
            <status-success v-if="data.jurnal == 'TERVERIFIKASI'" />
            <status-warning v-else-if="data.jurnal == 'MENUNGGU'" />
            <status-muted v-else />
          </td>
          <td class="text-center">
            <status-success v-if="data.artikel_berita == 'TERVERIFIKASI'" />
            <status-warning v-else-if="data.artikel_berita == 'MENUNGGU'" />
            <status-muted v-else />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'DasborDetail3',
    props: ['lis_data'],
  }
</script>