<template>
  <div class="card">
    <MenuHeader judul="Tahap 1" deskripsi="Program Kerja & Survei" :mahasiswa="mahasiswa" :warna="warna" />

    <div class="card-body">

      <div class="row mb-1">
        <label class="col-form-label col-xl-3 col-md-4" for="file_1">Susunan Program Kerja</label>
        <div class="col-xl-9 col-md-8 d-flex justify-content-between">
          <a :href="proker.file" target="_blank" type="button" class="btn btn-secondary" v-if="proker.file != '-'">Lihat
            Dokumen Susunan Program Kerja</a>
        </div>
      </div>

      <div class="row mb-1">
        <label class="col-form-label col-xl-3 col-md-4" for="file_2">Survei Lokasi KKN</label>
        <div class="col-xl-9 col-md-8 d-flex justify-content-between">
          <a :href="survei.file" target="_blank" type="button" class="btn btn-secondary" v-if="survei.file != '-'">Lihat
            Bukti Survei</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import MenuHeader from '@/components/menu/Header.vue'
  import Alert from '@/components/alert/Alert.vue'

  export default {
    name: 'ProgramKerja',
    props: ['mahasiswa', 'nim', 'warna', 'token', 'load'],
    components: {
      MenuHeader,
      Alert
    },
    data() {
      return {
        proker: {
          file: '-',
          status: '-'
        },
        survei: {
          file: '-',
          status: '-'
        },
      }
    },
    created() {
      this.getData()
    },
    methods: {
      async getData() {
        await axios.get(`proker/show/${this.nim}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.proker = res.data.data
          })

        await axios.get(`survei/show/${this.nim}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.survei = res.data.data
          })
      }
    },
  }
</script>

<style>

</style>