<template>
   <div class="card">
      <div class="card-body">
         <modal-button modal_name="Tambah" modal_target="#modalTambahJurusan" />
      </div>
      <table-body>
         <template #thead>
            <tr class="text-center">
               <th class="px-2" style="width: 20px">No</th>
               <th>Jurusan</th>
               <th>Email</th>
               <th>Program Studi</th>
               <th>Aksi</th>
            </tr>
         </template>

         <template #tbody>
            <tr v-for="(data, index) in lis_data" :key="index">
               <td>{{ index + 1}}</td>
               <td>{{ data.nama }}</td>
               <td>{{ data.email }}</td>
               <td>
                  <ol class="m-0">
                     <li v-for="(prodi, index) in data.lis_prodi" :key="index">
                        {{ prodi.nama }}
                     </li>
                  </ol>
               </td>
               <td>
                  <button class="btn btn-secondary" @click="resetPassword(data.id_user)">Reset Password</button>
               </td>
            </tr>
         </template>
      </table-body>
   </div>

   <modal-show modal_id="modalTambahJurusan" modal_name="Tambah Jurusan">
      <form @submit.prevent="tambahJurusan">
         <div class="row mb-1">
            <label for="nama" class="col-form-label col-md-4">Nama Jurusan</label>
            <div class="col-md-8">
               <input type="text" id="nama" class="form-control" v-model="nama">
            </div>
         </div>

         <div class="row mb-1">
            <label for="email" class="col-form-label col-md-4">Email</label>
            <div class="col-md-8">
               <input type="email" id="email" class="form-control" v-model="email">
            </div>
         </div>

         <div class="row mb-1">
            <label class="col-form-label col-md-4">Program Studi</label>
            <div class="col-md-8">

               <label class="form-check" v-for="(data, index) in lis_prodi" :key="index">
                  <input class="form-check-input" type="checkbox" :value="data.kode" v-model="kode_prodi">
                  <span class="form-check-label">{{ data.nama }}</span>
               </label>

            </div>
         </div>

         <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-8">
               <input type="submit" value="Tambah" class="btn btn-primary">
            </div>
         </div>
      </form>
   </modal-show>
</template>

<script>
   import axios from 'axios'
   export default {
      name: 'Jurusan',
      data() {
         return {
            token: sessionStorage.getItem('token'),
            lis_prodi: [{
                  kode: '23201',
                  nama: 'Arsitektur (S1)'
               },
               {
                  kode: '57201',
                  nama: 'Sistem Informasi (S1)'
               },
               {
                  kode: '26201',
                  nama: 'Teknik Industri (S1)'
               },
               {
                  kode: '55201',
                  nama: 'Teknik Informatika (S1)'
               },
               {
                  kode: '22201',
                  nama: 'Teknik Sipil (S1)'
               },
            ],
            nama: '',
            email: '',
            kode_prodi: [],
            lis_data: {}
         }
      },
      mounted() {
         this.getData()
      },
      methods: {
         getData() {
            axios.get('jurusan', {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  this.lis_data = res.data.data
               })
         },
         tambahJurusan() {
            let formData = new FormData
            formData.append('nama', this.nama)
            formData.append('email', this.email)
            formData.append('kode_prodi', this.kode_prodi)

            axios.post('jurusan/store', formData, {
                  headers: {
                     'Authorization': `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  if (res.data.status) {
                     this.$toast.success('Jurusan berhasil ditambahkan')
                     this.getData();
                  }
               })
         },
         resetPassword(id) {
            Swal.fire({
               icon: 'question',
               text: 'Reset password ?',
               showCancelButton: true,
               cancelButtonText: 'Batal'
            })
            .then( res => {
               if( res.isConfirmed ) {
                  let formData = new FormData()
                  formData.append('id', id)
                  axios.post(`user/reset`, formData, {
                     headers: {
                        'Authorization': `Bearer ${this.token}`
                     }
                  })
                  .then( res => {
                     if( res.data.status ) {
                        this.$toast.success('Password berhasil direset menjadi itg@garut')
                     }
                  })
               }
            })
         }
      }
   }
</script>