<template>
    <DosenLogBook v-if="peran == 'DOSEN'"/>
    <MahasiswaLogBook v-if="peran == 'MAHASISWA'"/>
</template>

<script>
import DosenLogBook from '@/views/dosen/LogBookView.vue'
import MahasiswaLogBook from '@/views/mahasiswa/LogBookView.vue'

export default {
  name: 'LogBook',
  components: {
    DosenLogBook,
    MahasiswaLogBook
  },
  data() {
    return {
      peran: sessionStorage.getItem('peran')
    }
  }
}
</script>

<style>

</style>