<template>
  <div class="card">
    <menu-header :warna="warna" :judul="judul" :deskripsi="deskripsi" />

    <div class="card-body">
      <Alert status="alert-secondary">
        <span class="text-muted">{{ mahasiswa.nim }}</span> / {{ mahasiswa.nama }}
      </Alert>

      <Alert status="alert-danger" v-if="proker.file == '-' || survei.file == '-'">
        Mahasiswa Belum Mengunggah Susunan Program Kerja dan/atau Bukti Surveri Tempat KKN
      </Alert>

      <Alert status="alert-warning" v-else-if="!status_validasi">
        Program kerja dan survei belum di verifikasi
      </Alert>

      <div class="row mb-1">
        <label class="col-form-label col-xl-3 col-md-4" for="file_1">Susunan Program Kerja</label>
        <div class="col-xl-9 col-md-8 d-flex justify-content-between">
          <a :href="proker.file" target="_blank" type="button" class="btn btn-secondary" v-if="proker.file != '-'">Lihat
            Dokumen Susunan Program Kerja</a>
        </div>
      </div>

      <div class="row mb-1">
        <label class="col-form-label col-xl-3 col-md-4" for="file_2">Survei Lokasi KKN</label>
        <div class="col-xl-9 col-md-8 d-flex justify-content-between">
          <a :href="survei.file" target="_blank" type="button" class="btn btn-secondary" v-if="survei.file != '-'">Lihat
            Bukti Survei</a>
        </div>
      </div>

      <div v-if="!status_validasi" class="row mt-2">
        <div class="col-xl-3 col-md-4"></div>
        <div class="col-xl-9 col-md-8">
          <div class="d-flex gap-2">
            <button class="btn btn-primary" @click="verifikasiTahap1(1)">Verifikasi</button>
            <button class="btn btn-danger" @click="verifikasiTahap1(0)">Tolak</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Alert from '@/components/alert/Alert.vue'
  import axios from 'axios'

  export default {
    name: 'PesertaProker',
    props: ['warna', 'judul', 'deskripsi', 'mahasiswa', 'token'],
    components: {
      Alert
    },
    data() {
      return {
        proker: {
          status: 'TERVERIFIKASI',
          file: '-'
        },
        survei: {
          status: 'TERVERIFIKASI',
          file: '-'
        },
        peran: sessionStorage.getItem('peran')
      }
    },

    computed: {
      status_validasi() {
        let status = false;
        switch (this.peran) {
          case 'DOSEN':
            status = this.proker.verifikasi_dosen != null;
            break;
          case 'PRODI':
            status = this.proker.verifikasi_prodi != null;
            break;
          case 'LPPM':
            status = this.proker.verifikasi_lppm != null;
            break;
        }

        return status;
      }
    },  

    mounted() {
      this.showProker()
      this.showSurvei()
    },
    methods: {
      async showProker() {
        await axios.get(`proker/show/${this.mahasiswa['nim']}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.proker = res.data.data
          })
      },
      async showSurvei() {
        await axios.get(`survei/show/${this.mahasiswa['nim']}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.survei = res.data.data
          })
      },
      async verifikasiProker(id) {
        let formData = new FormData()
        formData.append('id', id)

        await axios.post(`proker/verifikasi`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.showProker()
              this.$emit('update-date', true)
              this.$toast.success('Program Kerja Berhasil Diverifikasi')
            }
          })
      },
      async verifikasiSurvei(id) {
        let formData = new FormData()
        formData.append('id', id)

        await axios.post(`survei/verifikasi`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.showSurvei()
              this.$emit('update-data', true)
              this.$toast.success('Survei Lokasi KKN Berhasil Diverifikasi')
            }
          })
      },
      async verifikasiTahap1(status) {
        let formData = new FormData()
        formData.append('proker_id', this.proker.id)
        formData.append('survei_id', this.survei.id)
        formData.append('peran', this.peran)
        formData.append('status', status)

        await axios.post(`proker/verifikasi`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.showProker()
              this.showProker()
              this.$emit('update-date', true)
              this.$toast.success('Berhasil Diverifikasi')
            }
          })
      }
    },
  }
</script>

<style>

</style>