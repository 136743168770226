<template>
   <div class="alert alert-info mb-3">
      Pengecekan dan validasi dilakukan pada halaman Pesreta KKN
   </div>

   <div class="card">
      <table-body>
         <template #thead>
            <tr class="text-center">
               <th rowspan="2" style="width: 30px">No</th>
               <th rowspan="2">Mahasiswa</th>
               <th colspan="5">Komponen Penilaian</th>
               <th colspan="2">Nilai Akhir</th>
            </tr>

            <tr class="text-center">
               <th>Pembekalan<br>(10%)</th>
               <th>Program Kerja<br>(5%)</th>
               <th>Pelaksanaan<br>(10%)</th>
               <th>Kinerja<br>(70%)</th>
               <th>Laporan<br>(5%)</th>
               <th>Angka</th>
               <th>Huruf</th>
            </tr>
         </template>

         <template #tbody>
            <tr v-for="(d, index) in data" :key="index">
               <td class="text-right">{{ index + 1 }}</td>
               <td>{{ d.nama }}<span class="text-muted"><br>{{ d.nim }}<br>{{ d.prodi }}<br>{{ d.kelompok }}</span></td>
               <td class="text-center">
                  {{ d.penilaian_pembekalan }}
               </td>
               <td class="text-center">
                  {{ d.penilaian_proker }}
               </td>
               <td class="text-center">
                  {{ d.penilaian_pelaksanaan }}
               </td>
               <td class="text-center">
                  {{ d.penilaian_kinerja }}
               </td>
               <td class="text-center">
                  {{ d.penilaian_laporan }}
               </td>
               <td class="text-center">
                  {{ d.penilaian_angka }}
               </td>
               <td class="text-center">
                  {{ d.penilaian_huruf }}
               </td>
            </tr>
         </template>
      </table-body>
   </div>
</template>

<script>
   export default {
      name: 'DasborDetail6',
      props: ['data']
   }
</script>

<style>

</style>