<template>
  <div class="card">
    <div class="card-body">
      1
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'FormulirProfil',
    data() {
      return {
        lis_agama: ['Islam', 'Kristen'],
        lis_kelamin: ['Laki-laki', 'Perempuan'],
        lis_ukuran: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
        token: sessionStorage.getItem('token'),
        message: 'Tunggu sampai semua data berhasil diambil',
        dosen: {
          nidn: '',
          nama: '-',
          tempat_lahir: '-',
          tanggal_lahir: '-',
          jenis_kelamin: '-',
          program_studi: '-',
          no_hp: '-',
          email: '-',
          jabatan_fungsional: '',
          jabatan_struktural: '',
          alamat: '',
          kode_prodi: '',
          status: '',
        },
      }
    },
    mounted() {
      this.getProfile()
    },
    methods: {
      async getProfile() {
        await axios.get('dosen/profile', {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {

            setTimeout(() => {
              this.dosen = res.data.data
              this.message =
                'Semua isian profil akan dijadikan acuan dalam pengumpulan data, pastikan semua data telah benar'
            }, 1000);

          })
      },
      postProfile() {
        let formData = new FormData()

        formData.append('nama', this.dosen.nama)
        formData.append('jenis_kelamin', this.dosen.jenis_kelamin)
        formData.append('tempat_lahir', this.dosen.tempat_lahir)
        formData.append('tanggal_lahir', this.dosen.tanggal_lahir)
        formData.append('alamat', this.dosen.alamat)
        formData.append('no_hp', this.dosen.no_hp)
        formData.append('jabatan_fungsional', this.dosen.jabatan_fungsional)
        formData.append('jabatan_struktural', this.dosen.jabatan_struktural)

        axios.post('dosen/profile/update', formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              Swal.fire({
                icon: 'success',
                text: 'Profil dosen berhasil disimpan',
                timer: 5000
              })
            }
          })
      }
    }
  }
</script>

<style>

</style>