<template>
    <div class="row">
        <!-- kiri -->
        <div class="col-xl-3 col-md-4 mb-3 mb-md-0">
            <div class="card border-0 shadow-sm">
                <div class="card-body">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button class="nav-link active" id="v-pills-profil-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profil" type="button" role="tab" aria-controls="v-pills-profil" aria-selected="true">Dosen</button>
                        <button class="nav-link" id="v-pills-dokumen-tab" data-bs-toggle="pill" data-bs-target="#v-pills-dokumen" type="button" role="tab" aria-controls="v-pills-dokumen" aria-selected="false">Mahasiswa</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- kanan -->
        <div class="col-xl-9 col-md-8">
            <div class="card shadow-sm border-0">
                <div class="card-body">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-profil" role="tabpanel" aria-labelledby="v-pills-profil-tab">
                            <FormulirAdminDosen />
                        </div>
        
                        <div class="tab-pane fade" id="v-pills-dokumen" role="tabpanel" aria-labelledby="v-pills-dokumen-tab">
                            <FormulirAdminMahasiswa />                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormulirAdminDosen from '../formulirAdmin/DosenView.vue'
import FormulirAdminMahasiswa from '../formulirAdmin/MahasiswaView.vue'

export default {
    name: 'FormulirAdminShow',
    components: {
        FormulirAdminDosen,
        FormulirAdminMahasiswa
    },
}
</script>

<style>

</style>