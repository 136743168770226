<template>
    <div class="card">
        <div class="card-body">
            <ButtonModal modal_name="Pembekalan" modal_target="#modalPembekalan" @click="getDataAnggota" />
        </div>

        <BodyTableVue>
            <template v-slot:thead>
                <tr class="text-center">
                    <th rowspan="2" style="width: 50px">No.</th>
                    <th rowspan="2">Mahasiswa</th>
                    <th colspan="5">Komponen Penilaian</th>
                    <th rowspan="2" style="width: 90px" class="px-0">Aksi</th>
                </tr>

                <tr class="text-center">
                    <th class="px-3" style="width: 118px">Pembekalan</th>
                    <th class="px-3" style="width: 118px">Program Kerja</th>
                    <th class="px-3" style="width: 118px">Pelaksanaan</th>
                    <th class="px-3" style="width: 118px">Kinerja</th>
                    <th class="px-3" style="width: 118px">Laporan</th>
                </tr>
            </template>

            <template v-slot:tbody>
                <tr>
                    <td>1</td>
                    <td>Nama Mahasiswa<br><span class="text-muted">NIM</span></td>
                    <td class="text-center p-0">

                    </td>
                    <td class="text-center p-0">

                    </td>
                    <td class="text-center p-0">

                    </td>
                    <td class="text-center p-0">

                    </td>
                    <td class="text-center p-0">

                    </td>
                    <td class="text-center px-2">
                        <ButtonModal modal_name="Detail" modal_target="#modalDetailLogBook" />
                    </td>
                </tr>
            </template>
        </BodyTableVue>
    </div>

    <ShowModal modal_id="modalPembekalan" modal_name="Pembekalan KKN ITG">
        <div class="row">
            <div class="col-auto">
                <select class="form-select" v-model="id_kelompok">
                    <option v-for="(kelompok, index) in daftar_kelompok" :key="index" :value="kelompok.id">
                        {{ kelompok.nama }}
                    </option>
                </select>
            </div>

            <div class="col">
                <div class="input-icon">
                    <input type="text" class="form-control" v-model="search" placeholder="Cari data mahasiswa ...">

                    <span class="input-icon-addon">
                        <svg xmlns="http://www.w3.org/2000/svg" v-if="!searching"
                            class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24"
                            stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                            stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="10" cy="10" r="7"></circle>
                            <line x1="21" y1="21" x2="15" y2="15"></line>
                        </svg>
                        <div class="spinner-border spinner-border-sm text-muted" role="status" v-if="searching"></div>
                    </span>
                </div>
            </div>
        </div>

        <div class="table-responsive mt-3">
            <table class="table table-bordered table-hover table-vcenter">
                <thead>
                    <tr class="text-center">
                        <th>No</th>
                        <th>Mahasiswa</th>
                        <th>Status</th>
                        <th style="width: 100px">Aksi</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td class="text-right">1</td>
                        <td>Ilham Muhammad Yusuf<span class="text-muted"><br>1606099<br>Teknik Informatika (S1)</span>
                        </td>
                        <td class="text-center">
                            <StatysMuted />
                            <StatusSuccess />
                        </td>
                        <td class="text-center px-2">
                            <button class="btn btn-success">Hadir</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ShowModal>
</template>

<script>
    import SearchTableVue from '@/components/table/SearchTable.vue'
    import BodyTableVue from '@/components/table/BodyTable.vue'
    import ButtonModal from '@/components/modal/ButtonModal.vue'
    import ShowModal from '@/components/modal/ShowModal.vue'
    import StatusSuccess from '@/components/status/Success.vue'
    import StatysMuted from '@/components/status/Muted.vue'
    import axios from 'axios'

    export default {
        name: 'AdminPenilaian',
        components: {
            SearchTableVue,
            BodyTableVue,
            ButtonModal,
            ShowModal,
            StatusSuccess,
            StatysMuted
        },
        data() {
            return {
                search: '',
                searching: false,
                token: sessionStorage.getItem('token'),
                daftar_kelompok: {},
                id_kelompok: '',
            }
        },
        mounted() {
            this.getDataKelompok()
        },
        methods: {
            async getDataKelompok() {
                await axios.get('kelompok', {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        const getLastNum = (str) => {
                            var m = str.nama.match(/\d+$/);
                            if (m) {
                                return parseInt(m[0], 10)
                            } else {
                                return -1
                            }
                        }

                        this.daftar_kelompok = res.data.data.sort(function (a, b) {
                            return getLastNum(a) - getLastNum(b);
                        });

                        this.id_kelompok = this.daftar_kelompok[0].id
                    })
            },
        }
    }
</script>

<style>
    .text-right {
        text-align: right;
    }
</style>