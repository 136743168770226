<template>
  <Alert status="alert-secondary" v-if="loading">
    Tunggu sampai data berhasil diload
  </Alert>

  <div v-else>
    <Alert status="alert-danger" v-if="!status_validasi">
      Administrasi keuangan belum divalidasi, pastikan telah tervalidasi untuk melanjutkan ke tahap selanjutnya
    </Alert>
    
    <Alert status="alert-danger" v-else-if="!status_kelompok">
      Untuk membuka halaman ini pastikan telah memilih kelompok pada menu kelompok
    </Alert>
  
    <div class="row" v-else>
      <!-- kiri -->
      <div class="col-xl-3 col-md-4 mb-3 mb-md-0">
        <Menu judul="Profil" deskripsi="Data Dasar Mahasiswa" warna="bg-blue" @click="halaman = 'Profil'">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24" height="24"
            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="12" cy="7" r="4"></circle>
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
          </svg>
        </Menu>
  
        <Menu judul="Formulir" deskripsi="Formulir KKN Tematik" warna="bg-green" @click="halaman = 'Formulir'">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-description" width="24"
            height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
            <path d="M9 17h6"></path>
            <path d="M9 13h6"></path>
          </svg>
        </Menu>
  
      </div>
  
      <!-- kanan -->
      <div class="col-xl-9 col-md-8">
  
        <MahasiswaFormulirProfil v-if="halaman == 'Profil'" />
        <MahasiswaFormulirFormulir v-else-if="halaman == 'Formulir'" />
  
      </div>
    </div>
  </div>
</template>

<script>
  import Menu from '../../components/menu/Menu.vue'
  import MahasiswaFormulirProfil from '@/views/mahasiswa/formulir/ProfilView.vue'
  import MahasiswaFormulirFormulir from '@/views/mahasiswa/formulir/FormulirView.vue'
  import Alert from '@/components/alert/Alert.vue'
  import axios from 'axios'

  export default {
    name: 'MahasiswaProfil',
    components: {
      Menu,
      MahasiswaFormulirProfil,
      MahasiswaFormulirFormulir,
      Alert
    },

    data() {
      return {
        token : sessionStorage.getItem('token'),
        peran  : sessionStorage.getItem('peran'),
        halaman: 'Profil',
        status_validasi: false,
        status_kelompok: false,
        loading: true,
      }
    },

    mounted() {
      this.getStatusValidasi()
    },

    methods: {
      async getStatusValidasi() {
        this.loading = true
        await axios.get('validasiBak/statusValidasi', {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).then( res => {
          this.status_validasi = res.data.status
          this.status_kelompok = res.data.kelompok

          setTimeout(() => {
            this.loading = false
          }, 1000);
        })
      }
    }
  }
</script>

<style>

</style>