<template>
  <div class="card">
    <Header warna="bg-purple" judul="Tahap 3" deskripsi="Laporan Mingguan" />
    <div class="card-body">
      <ButtonModal modal_name="Tambah" modal_target="#modalLogBook" v-if="sisa > 0" />
        
      <p class="mt-2 text-danger">Batas pengisian laporan sampai jam 21.00</p>
    </div>

    <BodyTable>
      <template v-slot:thead>
        <tr class="text-center">
          <th rowspan="2" style="width: 150px">Laporan</th>
          <th rowspan="2" style="width: 150px">Tanngal</th>
          <th rowspan="2">URL Kegiatan</th>
          <th rowspan="2">Catatan</th>
          <th colspan="3">validasi</th>
          <th rowspan="2">Aksi</th>
        </tr>
      
        <tr class="text-center">
          <th>pembimbing</th>
          <th>prodi</th>
          <th>lppm</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="(d, index) in lis_laporan_mingguan" :key="index">
          <td>{{ d.judul }}</td>
          <td>{{ d.tanggal }}</td>
          <td class="text-center">
            <a :href="d.url" target="_blank" class="btn btn-secondary">Lihat</a>
          </td>
          <td class="text-wrap">
            {{ d.catatan }}
          </td>
          <td class="text-center">
            <StatusSuccess v-if="d.verifikasi_dosen != null" />
            <status-warning v-else-if="d.catatan != null" />
            <StatusMuted v-else />
          </td>
          <td class="text-center">
            <StatusSuccess v-if="d.verifikasi_prodi != null" />
            <status-warning v-else-if="d.catatan != null" />
            <StatusMuted v-else />
          </td>
          <td class="text-center">
            <StatusSuccess v-if="d.verifikasi_lppm != null" />
            <status-warning v-else-if="d.catatan != null" />
            <StatusMuted v-else />
          </td>
          <td class="text-center">
            <button class="btn btn-danger" type="button" v-if="d.catatan != null && (d.verifikasi_dosen == null && d.verifikasi_prodi == null && d.verifikasi_lppm == null)" @click="deleteData(d.id)">Hapus</button>
          </td>
        </tr>

        <tr v-for="i in sisa" :key="i">
          <td>Minggu ke-{{ lis_laporan_mingguan.length ? lis_laporan_mingguan.length + i : i }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-center"><StatusMuted /></td>
          <td class="text-center"><StatusMuted /></td>
          <td class="text-center"><StatusMuted /></td>
          <td class="text-center"></td>
        </tr>
      </template>
    </BodyTable>
  </div>

  <ShowModal modal_id="modalLogBook" modal_name="Tambah Log Book Baru">
    <form @submit.prevent="formLogBook">

      <div class="row mb-2">
        <label class="col-md-4 col-form-label">URL Kegiatan</label>
        <div class="col-md-8">
          <input type="url" class="form-control" required v-model="logbook.url">
          <small class="text-danger font-size-1">URL Video Kegiatan KKN yang di posting melalui Instagram</small>
        </div>
      </div>

      <div class="row mb-1">
        <label class="col-md-4 col-form-label">Deskripsi Kegiatan</label>
        <div class="col-md-8">
          <textarea rows="5" class="form-control" v-model="logbook.catatan"></textarea>
          <small class="text-danger font-size-1">Maksimum Karakter {{ 1000 - logbook.catatan.length }} / 1000</small>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <button type="submit" class="btn btn-primary">Tambah</button>
        </div>
      </div>
    </form>
  </ShowModal>
</template>

<script>
  import axios from 'axios'
  import ButtonModal from '@/components/modal/ButtonModal.vue'
  import ShowModal from '@/components/modal/ShowModal.vue'
  import Header from '@/components/menu/Header.vue'
  import BodyTable from '@/components/table/BodyTable.vue'
  import StatusSuccess from '@/components/status/Success.vue'
  import StatusWarning from '@/components/status/Warning.vue'
  import StatusMuted from '@/components/status/Muted.vue'

  export default {
    name: 'LaporanMingguan',
    components: {
      ButtonModal,
      ShowModal,
      Header,
      BodyTable,
      StatusSuccess,
      StatusWarning,
      StatusMuted,
    },
    data() {
      return {
        token: sessionStorage.getItem('token'),
        lis_laporan_mingguan: {},
        logbook: {
          catatan: ''
        },
        sisa: 0,
      }
    },
    mounted() {
      this.getLogBook()
    },
    methods: {
      async getLogBook() {
        await axios.get('laporan/showMHS', {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.lis_laporan_mingguan = res.data.data
            this.sisa = 4 - this.lis_laporan_mingguan.length
          })
      },
      async formLogBook() {

        let kegiatan_ke = this.lis_laporan_mingguan.length ? this.lis_laporan_mingguan.length + 1 : 1

        this.logbook.judul = `Minggu ke-${kegiatan_ke}`

        axios.post(`laporan/mahasiswa/store`, this.logbook, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).then(res => {
          if (res.data.status) {
            this.$toast.success('Laporan Mingguan Berhasil Ditambahkan')

            this.logbook = {catatan: ''}
            this.getLogBook()
          }
        })
      },
      async deleteData(id) {
        let formData = new FormData()
        formData.append('id', id)

        axios.post(`laporan/mahasiswa/delete`, formData, {
          headers: {
            'Authorization': `Bearer ${this.token}`
          }
        }).then(res => {
          if (res.data.status) {
            this.$toast.success('Laporan Mingguan Berhasil Dihapus')

            this.logbook = {catatan: ''}
            this.getLogBook()
          }
        })
      }
    }
  }
</script>

<style>

</style>