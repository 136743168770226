<template>
    <div class="card">

        <div class="card-body">
            <div class="input-icon">
                <input type="text" class="form-control" placeholder="Cari data dosen" v-model="search"
                    @keypress.enter="getData(1); searching = true">

                <span class="input-icon-addon">
                    <svg xmlns="http://www.w3.org/2000/svg" v-if="!searching"
                        class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24"
                        stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <circle cx="10" cy="10" r="7"></circle>
                        <line x1="21" y1="21" x2="15" y2="15"></line>
                    </svg>

                    <div class="spinner-border spinner-border-sm text-muted" role="status" v-if="searching"></div>
                </span>
            </div>
        </div>

        <BodyTable>
            <template v-slot:thead>
                <tr class="text-center">
                    <th rowspan="2" style="width: 20px">No.</th>
                    <th rowspan="2" class="text-nowrap">Profil Singkat</th>
                    <th rowspan="2" class="text-nowrap">Formulir</th>
                    <th colspan="6" class="text-nowrap">Kunjungan</th>
                    <th rowspan="2" style="width: 80px" class="text-nowrap">Aksi</th>
                </tr>

                <tr class="text-center">
                    <th class="w-icon px-3">1</th>
                    <th class="w-icon px-3">2</th>
                    <th class="w-icon px-3">3</th>
                    <th class="w-icon px-3">4</th>
                    <th class="w-icon px-3">5</th>
                    <th class="w-icon px-3">6</th>
                </tr>
            </template>

            <template v-slot:tbody>
                <tr v-for="(data, index) in  lis_data" :key="data.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ data.nama }}<br><span class="text-muted">{{ data.email }}<br>{{ data.jurusan }}</span></td>
                    <td class="text-center">
                        <status-muted v-if="data.ajukan == null" />
                        <status-success v-else-if="data.verifikasi_prodi != null && data.verifikasi_prodi != null" />
                        <status-warning  v-else />
                    </td>
                    <td class="text-center">
                        <StatusSuccess v-if="data.laporan[0] == 'TERVERIFIKASI'" />
                        <StatusWarning v-if="data.laporan[0] == 'MENUNGGU'" />
                        <StatusMuted v-if="data.laporan[0] == '-'" />
                    </td>
                    <td class="text-center">
                        <StatusSuccess v-if="data.laporan[1] == 'TERVERIFIKASI'" />
                        <StatusWarning v-if="data.laporan[1] == 'MENUNGGU'" />
                        <StatusMuted v-if="data.laporan[1] == '-'" />
                    </td>
                    <td class="text-center">
                        <StatusSuccess v-if="data.laporan[2] == 'TERVERIFIKASI'" />
                        <StatusWarning v-if="data.laporan[2] == 'MENUNGGU'" />
                        <StatusMuted v-if="data.laporan[2] == '-'" />
                    </td>
                    <td class="text-center">
                        <StatusSuccess v-if="data.laporan[3] == 'TERVERIFIKASI'" />
                        <StatusWarning v-if="data.laporan[3] == 'MENUNGGU'" />
                        <StatusMuted v-if="data.laporan[3] == '-'" />
                    </td>
                    <td class="text-center">
                        <StatusSuccess v-if="data.laporan[4] == 'TERVERIFIKASI'" />
                        <StatusWarning v-if="data.laporan[4] == 'MENUNGGU'" />
                        <StatusMuted v-if="data.laporan[4] == '-'" />
                    </td>
                    <td class="text-center">
                        <StatusSuccess v-if="data.laporan[5] == 'TERVERIFIKASI'" />
                        <StatusWarning v-if="data.laporan[5] == 'MENUNGGU'" />
                        <StatusMuted v-if="data.laporan[5] == '-'" />
                    </td>
                    <td class="px-2">
                        <ButtonModal modal_target="#modalDetailDosen" modal_name="Detail"
                            @click="showData(data.id); halaman = 'Profil'" />
                    </td>
                </tr>
            </template>
        </BodyTable>

        <!-- <InfoTable :info="info" /> -->

    </div>

    <ShowModal modal_id="modalDetailDosen" modal_name="Detail Dosen" modal_size="modal-full-width">
        <div class="row">
            <!-- kiri -->
            <div class="col-xl-3 col-md-4 mb-3 mb-md-0">

                <Menu judul="Profil & Formulir" deskripsi="Berisikan Profil & Formulir Dosen" warna="bg-blue"
                    @click="halaman = 'Profil'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-user" width="24"
                        height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    </svg>
                </Menu>

                <Menu judul="Log Book" deskripsi="Berisikan Log Book Dosen" warna="bg-green"
                    @click="halaman = 'LogBook'">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-history-toggle"
                        width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 8v4l3 3"></path>
                        <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95"></path>
                        <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44"></path>
                        <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92"></path>
                        <path d="M8.56 20.31a9 9 0 0 0 3.44 .69"></path>
                        <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95"></path>
                        <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44"></path>
                        <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92"></path>
                        <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69"></path>
                    </svg>
                </Menu>

            </div>

            <!-- kanan -->
            <div class="col-xl-9 col-md-8">
                <div class="card mb-1">
                    <div class="card-body bg-cyan d-flex justify-content-between">
                        <div>
                            {{ dosen.nama }}
                        </div>
                        <div>
                            {{ dosen.jurusan }}
                        </div>
                    </div>
                </div>

                <div class="card">

                    <!-- Profil -->
                    <div v-if="halaman == 'Profil'">
                        <menu-header warna="bg-blue" judul="Profil & Formulir" deskripsi="Berisikan Profil dan Formulir Dosen" />
                        <div class="card-body">
                            <Alert status="alert-secondary" v-if="loading">
                                Tunggu sampai data berhasil diload
                            </Alert>

                            <div v-else>
                                <Alert status="alert-danger" v-if="dosen.ajukan == null">
                                    Dosen Pembimbing Lapangan Belum Mengunggah Formulir
                                </Alert>

                                <Alert status="alert-success" v-else-if="statusVerifikasi">
                                    Formulir KKN Dosen Pembimbing Lapangan Telah Diperivikasi
                                </Alert>

                                <Alert status="alert-warning" v-else>
                                    Formulir KKN Dosen Pembimbing Lapangan Belum Diverifikasi
                                </Alert>

                                <form @submit.prevent="updateDosen">

                                    <div class="row mb-1">
                                        <label for="nim" class="col-form-label col-xl-3 col-md-4">NIP/NIDN</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="text" class="form-control w-auto" v-model="dosen.nidn" disabled>
                                        </div>
                                    </div>

                                    <div class="row mb-1">
                                        <label for="nama" class="col-form-label col-xl-3 col-md-4">Nama Lengkap</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="text" class="form-control" v-model="dosen.nama" required disabled>
                                        </div>
                                    </div>

                                    <div class="row mb-1">
                                        <label for="tempat_lahir" class="col-form-label col-xl-3 col-md-4">Tempat Lahir</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="text" class="form-control" required v-model="dosen.tempat_lahir" disabled>
                                        </div>
                                    </div>

                                    <div class="row mb-1">
                                        <label for="tanggal_lahir" class="col-form-label col-xl-3 col-md-4">Tanggal Lahir</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="date" class="form-control" required v-model="dosen.tanggal_lahir" disabled>
                                        </div>
                                    </div>

                                    <div class="row mb-1">
                                        <label for="jenis_kelamin" class="col-form-label col-xl-3 col-md-4">Jenis Kelamin</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="text" class="form-control" required v-model="dosen.jenis_kelamin" disabled>
                                        </div>
                                    </div>

                                    <div class="row mb-1">
                                        <label for="no_hp" class="col-form-label col-xl-3 col-md-4">No. HP / WA</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="text" class="form-control" v-model="dosen.no_hp" required disabled>
                                        </div>
                                    </div>

                                    <div class="row mb-1">
                                        <label for="email" class="col-form-label col-xl-3 col-md-4">Email</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="text" class="form-control" readonly v-model="dosen.email" disabled>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <label for="jabatan_fungsional" class="col-form-label col-xl-3 col-md-4">Jabatan Fungsional</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="text" class="form-control" readonly v-model="dosen.jabatan_akademik" disabled>
                                        </div>
                                    </div>

                                    <div class="row mb-1" v-if="dosen.file_1 != '-'">
                                        <label class="col-form-label col-xl-3 col-md-4">Formulir</label>
                                        <div class="col-xl-9 col-md-8">
                                            <div class="mb-2">
                                                Dokumen 1 : Formulir Pendaftaran
                                                <br>
                                                <a :href="dosen.file_1" target="_blank" class="btn btn-secondary"
                                                    v-if="dosen.file_1">Lihat</a>
                                            </div>
                                            <div class="mb-2">
                                                Dokumen 2 : Sertifikat kompetensi sebagai DPL
                                                <br>
                                                <a :href="dosen.file_2" target="_blank" class="btn btn-secondary"
                                                    v-if="dosen.file_2">Lihat</a>
                                            </div>
                                            <div class="mb-2">
                                                Dokumen 3 : Surat pernyataan kesanggupan mengikuti kegiatan sampai
                                                tuntas
                                                <br>
                                                <a :href="dosen.file_3" target="_blank" class="btn btn-secondary"
                                                    v-if="dosen.file_3">Lihat</a>
                                            </div>
                                            <div class="mb-2">
                                                Dokumen 4 : Surat pernyataan tidak akan melakukan kegiatan politik
                                                praktis di lokasi
                                                <br>
                                                <a :href="dosen.file_4" target="_blank" class="btn btn-secondary"
                                                    v-if="dosen.file_4">Lihat</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-if="dosen.file_1 != '-'">
                                        <label class="col-form-label col-xl-3 col-md-4">Verifikasi</label>
                                        <div class="col-xl-9 col-md-8">
                                            <input type="button" class="btn btn-success" 
                                                @click="verifikasi" 
                                                :value="'VERIFIKASI '+peran" 
                                                v-if="tombolVerifikasi">
                                            <input 
                                                type="button" 
                                                class="btn btn-danger" 
                                                @click="batalVerifikasi" :value="'BATAL VERIFIKASI '+peran" 
                                                v-if="tombolBatalVerifikasi">
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                    <!-- LogBook -->
                    <div v-else>
                        <menu-header warna="bg-green" judul="Log Book" deskripsi="Berisikan Log Book Dosen" />
                        <div class="card-body" v-if="loading">
                            <Alert status="alert-secondary">
                                Tunggu sampai data berhasil diload
                            </Alert>
                        </div>

                        <BodyTable v-if="! loading">
                            <template v-slot:thead>
                                <tr class="text-center">
                                    <th style="width: 150px">Lob Book</th>
                                    <th style="width: 150px">Tanngal</th>
                                    <th>Bukti Kunjungan</th>
                                    <th>Catatan</th>
                                    <th style="width: 150px">Status</th>
                                    <th style="width: 100px">Aksi</th>
                                </tr>
                            </template>

                            <template v-slot:tbody>
                                <tr v-for="(logbook, index) in lis_logbook" :key="index">
                                    <td>{{ logbook.judul }}</td>
                                    <td>{{ logbook.tanggal }}</td>
                                    <td>
                                        <a :href="logbook.file" target="_blank" class="btn btn-secondary">Lihat</a>
                                    </td>
                                    <td>
                                        {{ logbook.catatan }}
                                    </td>
                                    <td class="text-center">
                                        <status-success v-if="logbook.status == 'TERVERIFIKASI'" />
                                        <status-warning v-else />
                                    </td>
                                    <td class="text-center px-2">
                                        <button class="btn btn-success m-auto" v-if="logbook.status == 'MENUNGGU'" @click="verifikasiLogBook( logbook.id )">Verifikasi</button>
                                        <button class="btn btn-danger m-auto" v-else @click="batalVerifikasiLogBook( logbook.id )">Batal Verifikasi</button>
                                    </td>
                                </tr>

                                <tr v-for="i in sisa" :key="i">
                                    <td>Kunjungan ke-{{ lis_logbook.length + i }}</td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <status-muted />
                                    </td>
                                    <td class="text-center">-</td>
                                </tr>
                            </template>
                        </BodyTable>
                    </div>
                </div>
            </div>
        </div>
    </ShowModal>
</template>

<script>
    import axios from 'axios'
    import BodyTable from '@/components/table/BodyTable.vue'
    import InfoTable from '@/components/table/InfoTable.vue';
    import ButtonModal from '@/components/modal/ButtonModal.vue';
    import ShowModal from '@/components/modal/ShowModal.vue';
    import Alert from '@/components/alert/Alert.vue';
    import StatusSuccess from '@/components/status/Success.vue'
    import StatusWarning from '@/components/status/Warning.vue'
    import StatusMuted from '@/components/status/Muted.vue'
    import Menu from '@/components/menu/Menu.vue'

    export default {
        name: "Dosen",
        components: {
            BodyTable,
            InfoTable,
            ButtonModal,
            ShowModal,
            Alert,
            StatusSuccess,
            StatusWarning,
            StatusMuted,
            Menu
        },
        data() {
            return {
                token: sessionStorage.getItem("token"),
                peran: sessionStorage.getItem('peran'),
                lis_data: {},
                lis_status: ["TERVERIFIKASI", "MENUNGGU"],
                lis_kelamin: ["Laki-laki", "Perempuan"],
                lis_jurusan: ["Ilmu Komputer", "Teknik Industri", "Teknik Sipil & Perencanaan"],
                lis_jabatan: ['Asisten Ahli', 'Lektor', 'Lektor Kepala', 'Guru Besar'],
                lis_logbook: {},
                sisa: 6,
                filter_status: "",
                search: "",
                loading: false,
                searching: false,
                page: 1,
                halaman: 'Profil',
                info: {
                    total: 0,
                    currentPage: 0,
                    firstItem: 0,
                    lastItem: 0,
                    paginate: 0,
                },
                dosen: {
                    id: "",
                    id_kkn: "",
                    nidn: "",
                    nama: "-",
                    tempat_lahir: "-",
                    tanggal_lahir: "-",
                    jenis_kelamin: "-",
                    program_studi: "-",
                    no_hp: "-",
                    email: "-",
                    jabatan_fungsional: "",
                    alamat: "",
                    kode_prodi: "",
                    status: "",
                    file_1: null,
                    file_2: null,
                    file_3: null,
                    file_4: null,
                    file_5: null,
                },
            };
        },

        computed: {
            tombolVerifikasi() {
                if( this.dosen.ajukan != null ) {
                    if( this.peran == "PRODI" ) {
                        return this.dosen.verifikasi_prodi == null
                    } else if ( this.peran == "LPPM" ) {
                        return this.dosen.verifikasi_lppm == null
                    }
                } else {
                    return false
                }
            },

            tombolBatalVerifikasi() {
                if( this.dosen.ajukan != null ) {
                    if( this.peran == "PRODI" ) {
                        return this.dosen.verifikasi_prodi != null && this.dosen.verifikasi_lppm == null
                    } else if ( this.peran == "LPPM" ) {
                        return this.dosen.verifikasi_lppm != null
                    }
                } else {
                    return false
                }
            },

            statusVerifikasi() {
                if( this.dosen.ajukan != null ) {
                    if( this.peran == "PRODI" ) {
                        return this.dosen.verifikasi_prodi != null
                    } else if ( this.peran == "LPPM" ) {
                        return this.dosen.verifikasi_lppm != null
                    }
                } else {
                    return false
                }
            }
        },

        created() {
            this.resetInfo();
            this.getData(this.page);
        },

        methods: {
            resetInfo() {
                this.lis_data = {};
                this.info.total = 0;
                this.info.currentPage = 0;
                this.info.firstItem = 0;
                this.info.lastItem = 0;
                this.info.paginate = 0;
            },
            async getData(page) {
                await axios.get(`dosen?page=${page}&search=${this.search}`, {
                        headers: {
                            "Authorization": `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        this.searching = false;
                        if (res.data.total != 0) {
                            this.lis_data = res.data.data.sort((a, b)=> {
                                return a.nama.localeCompare(b.nama)
                            })

                            this.info.total = res.data.total;
                            this.info.currentPage = res.data.currentPage;
                            this.info.firstItem = res.data.firstItem;
                            this.info.lastItem = res.data.lastItem;
                            this.info.paginate = Math.ceil(res.data.total / res.data.perPage);
                        } else {
                            this.resetInfo();
                        }
                    });
            },
            async showData(id) {
                this.loading = true;
                await axios.get(`dmDosen/${id}`, {
                        headers: {
                            "Authorization": `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        this.dosen = res.data.data;
                        this.dosen.jenis_kelamin = this.dosen.jenis_kelamin == 'L' ? 'Laki-laki' : 'Perempuan'

                        console.log( this.dosen )
                    });

                await axios.get(`laporan/dosen/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    })
                    .then(res => {
                        this.lis_logbook = res.data.data
                        this.sisa = 6 - this.lis_logbook.length

                        setTimeout(() => {
                            this.loading = false
                        }, 1000);
                    })
            },
            async verifikasi() {
                Swal.fire({
                    icon: 'question',
                    text: 'Verifikasi dosen tersebut ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal'
                }).then(res => {
                    if (res.isConfirmed) {
                        let formData = new FormData()
                        formData.append("verifikasi", this.peran)
                        axios.post(`verifikasi/dosen/${this.dosen.id_kkn}`, formData, {
                                headers: {
                                    "Authorization": `Bearer ${this.token}`
                                }
                            })
                            .then(res => {
                                if (res.data.status) {
                                    this.$toast.success('Formulir KKN DPL berhasil di verifikasi')

                                    this.showData(this.dosen.id)
                                    this.getData(this.info.currentPage)
                                }
                            });
                    }
                })
            },
            async batalVerifikasi() {
                Swal.fire({
                    icon: 'question',
                    text: 'Batal verifikasi dosen tersebut ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal'
                }).then(res => {
                    if (res.isConfirmed) {
                        let formData = new FormData()
                        formData.append("verifikasi", this.peran)

                        axios.post(`verifikasi/dosen/${this.dosen.id_kkn}/batal`, formData, {
                                headers: {
                                    "Authorization": `Bearer ${this.token}`
                                }
                            })
                            .then(res => {
                                if (res.data.status) {
                                    this.$toast.success('Formulir KKN DPL batal di verifikasi')

                                    this.showData(this.dosen.id)
                                    this.getData(this.info.currentPage)
                                }
                            });
                    }
                })
            },
            async updateDosen() {
                Swal.fire({
                    icon: 'question',
                    text: 'Perbarui data dosen ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal'
                }).then(res => {
                    if (res.isConfirmed) {
                        let formData = new FormData()
                        formData.append('id', this.dosen.id)
                        formData.append('nidn', this.dosen.nidn)
                        formData.append('nama', this.dosen.nama)
                        formData.append('tempat_lahir', this.dosen.tempat_lahir)
                        formData.append('tanggal_lahir', this.dosen.tanggal_lahir)
                        formData.append('jenis_kelamin', this.dosen.jenis_kelamin)
                        formData.append('jurusan', this.dosen.jurusan)
                        formData.append('no_hp', this.dosen.no_hp)
                        formData.append('email', this.dosen.email)
                        formData.append('jabatan_fungsional', this.dosen.jabatan_fungsional)
                        formData.append('jabatan_struktural', this.dosen.jabatan_struktural)
                        formData.append('alamat', this.dosen.alamat)

                        axios.post(`dosen/update`, formData, {
                                headers: {
                                    "Authorization": `Bearer ${this.token}`
                                }
                            })
                            .then(res => {
                                if (res.data.status) {
                                    this.$toast.success('Data dosen berhasil diperbarui')

                                    this.showData(this.dosen.id)
                                    this.getData(this.currentPage);
                                }
                            });
                    }
                })
            },
            async verifikasiLogBook(id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Verifikasi Log Book Dosen ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal'
                }).then(res => {
                    if (res.isConfirmed) {
                        this.loading = true
                        let formData = new FormData()
                        formData.append('id', id)

                        axios.post(`laporan/dosen/verifikasi`, formData, {
                                headers: {
                                    'Authorization': `Bearer ${this.token}`
                                }
                            })
                            .then(res => {
                                if (res.data.status) {
                                    this.$toast.success('Log Book Dosen Berhasil Diverifikasi')

                                    this.showData(this.dosen.id)
                                    this.getData(this.info.currentPage)
                                }
                            })
                    }
                })
            },
            async batalVerifikasiLogBook(id) {
                Swal.fire({
                    icon: 'question',
                    text: 'Batalkan Verifikasi Log Book Dosen ?',
                    showCancelButton: true,
                    cancelButtonText: 'Batal'
                }).then(res => {
                    if (res.isConfirmed) {
                        this.loading = true
                        let formData = new FormData()
                        formData.append('id', id)

                        axios.post(`laporan/dosen/verifikasi/batal`, formData, {
                                headers: {
                                    'Authorization': `Bearer ${this.token}`
                                }
                            })
                            .then(res => {
                                if (res.data.status) {
                                    this.$toast.success('Verifikasi Log Book Dosen Berhasil Dibatalkan')

                                    this.showData(this.dosen.id)
                                    this.getData(this.info.currentPage)
                                }
                            })
                    }
                })
            },
        },
    }
</script>

<style>
    .w-icon {
        width: 50px;
    }
</style>