<template>
  <div class="card">
    <Header warna="bg-pink" judul="Tahap 4" deskripsi="Laporan Akhir" />
    
    <div class="card-body">
      <Alert status="alert-secondadry" v-if="loading">
        Tunggu sampai semua data berhasil diambil
      </Alert>

      <ButtonModal modal_name="Tambah" modal_target="#modalTambahLaporan" v-else-if="! loading && sisa > 0" />
    </div>

    <BodyTable>
      <template v-slot:thead>
        <tr class="text-center">
          <th rowspan="2">Jenis File</th>
          <th rowspan="2">Bukti Upload</th>
          <th colspan="3">Validasi</th>
          <th rowspan="2">Aksi</th>
        </tr>

        <tr class="text-center">
          <th>Pembimbing</th>
          <th>Prodi</th>
          <th>LPPM</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr>
          <td>Laporan Akhir</td>
          <td class="text-center">
            <a :href="data.laporan_file" target="_blank" class="btn btn-secondary" v-if="data.laporan_file != '-'">Lihat
              File</a>
            <span v-else>-</span>
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.laporan_verifikasi_dosen != null" />
            <status-warning v-else-if="data.laporan_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.laporan_verifikasi_prodi != null" />
            <status-warning v-else-if="data.laporan_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.laporan_verifikasi_lppm != null" />
            <status-warning v-else-if="data.laporan_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="text-center">
            <button class="btn btn-danger" type="button" @click="deleteData(data.laporan_id)" v-if="data.laporan_status == 'MENUNGGU'">Hapus</button>
          </td>
        </tr>
        <tr>
          <td>Jurnal</td>
          <td class="text-center">
            <a :href="data.jurnal_file" target="_blank" class="btn btn-secondary" v-if="data.jurnal_file != '-'">Lihat
              File</a>
            <span v-else>-</span>
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.jurnal_verifikasi_dosen != null" />
            <status-warning v-else-if="data.jurnal_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.jurnal_verifikasi_prodi != null" />
            <status-warning v-else-if="data.jurnal_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.jurnal_verifikasi_lppm != null" />
            <status-warning v-else-if="data.jurnal_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="text-center">
            <button class="btn btn-danger" type="button" @click="deleteData(data.jurnal_id)" v-if="data.jurnal_status == 'MENUNGGU'">Hapus</button>
          </td>
        </tr>
        <tr>
          <td>Artikel Berita</td>
          <td class="text-center">
            <a :href="data.artikel_file" target="_blank" class="btn btn-secondary" v-if="data.artikel_file != '-'">Lihat
              File</a>
            <span v-else>-</span>
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.artikel_verifikasi_dosen != null" />
            <status-warning v-else-if="data.artikel_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.artikel_verifikasi_prodi != null" />
            <status-warning v-else-if="data.artikel_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="align-top text-center">
            <status-success v-if="data.artikel_verifikasi_lppm != null" />
            <status-warning v-else-if="data.artikel_file != '-'" />
            <status-muted v-else />
          </td>
          <td class="text-center">
            <button class="btn btn-danger" type="button" @click="deleteData(data.artikel_id)" v-if="data.artikel_status == 'MENUNGGU'">Hapus</button>
          </td>
        </tr>
      </template>
    </BodyTable>
  </div>

  <ShowModal modal_id="modalTambahLaporan" modal_name="Tambah Laporan">
    <form @submit.prevent="formLaporan">
      <div class="row mb-1">
        <label for="jenis" class="col-form-label col-md-4">Jenis Laporan</label>
        <div class="col-md-8">
          <select id="jenis" class="form-select" v-model="laporan.jenis">
            <option v-for="(jenis, index) in lis_jenis" :key="index">{{ jenis }}</option>
          </select>
        </div>
      </div>

      <div class="row mb-1">
        <label for="file" class="col-form-label col-md-4">File Laporan</label>
        <div class="col-md-8">
          <input type="file" id="file" class="form-control" accept=".pdf">
          <p class="text-danger mt-1" style="font-size: 11px">Format File (.pdf), Ukuran Maksimal 20 MB</p>
        </div>
      </div>

      <div class="row mt-3" v-if="sisa > 0">
        <div class="col-md-4"></div>
        <div class="col-md-8">
          <input type="submit" value="Tambah" class="btn btn-primary">
        </div>
      </div>
    </form>
  </ShowModal>
</template>

<script>
  import axios from 'axios'
  import Alert from '@/components/alert/Alert.vue'
  import BodyTable from '@/components/table/BodyTable.vue'
  import ButtonModal from '@/components/modal/ButtonModal.vue'
  import ShowModal from '@/components/modal/ShowModal.vue'
  import SuccessVue from '@/components/status/Success.vue'
  import WarningVue from '@/components/status/Warning.vue'
  import MutedVue from '@/components/status/Muted.vue'
  import Header from '@/components/menu/Header.vue'

  export default {
    name: 'LaporanAkhir',
    components: {
      Alert,
      BodyTable,
      ButtonModal,
      ShowModal,
      SuccessVue,
      WarningVue,
      MutedVue,
      Header
    },
    data() {
      return {
        token: sessionStorage.getItem('token'),
        loading: false,
        data: {
          laporan_file: '-',
          jurnal_file: '-',
          artikel_file: '-',
        },
        lis_jenis: ['Laporan Akhir', 'Jurnal', 'Artikel Berita'],
        sisa: 0,
        laporan: {}
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      async getData() {
        this.loading = true

        await axios.get(`laporan/akhir/showMHS`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.data = res.data.data
            this.sisa = 3

            if( this.data.laporan_file != '-' ) {
              this.sisa--
            }

            if( this.data.jurnal_file != '-' ) {
              this.sisa--
            }

            if( this.data.artikel_file != '-' ) {
              this.sisa--
            }

            this.loading = false
          })
      },
      async formLaporan() {
        let formData = new FormData()
        let file = document.querySelector('#file')

        formData.append('jenis', this.laporan.jenis)
        formData.append('file', file.files[0])

        axios.post(`laporan/akhir/store`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.getData()
              this.$toast.success('Laporan Akhir Berhasil Ditambahkan')
            }
          })
          .catch(err => {
            this.$toast.error('Laporan Akhir Gagal Ditambahkan')
          })
      },
      async deleteData(id) {
        let formData = new FormData()
        formData.append('id', id)

        axios.post(`laporan/akhir/delete`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.getData()
              this.$toast.success('Laporan Akhir Berhasil Dihapus')
            }
          })
      }
    }
  }
</script>

<!-- deleteData -->

<style>

</style>