<template>
  <div class="card">
    <MenuHeader judul="Tahap 2" deskripsi="Laporan Harian" :mahasiswa="mahasiswa" :warna="warna" />

    <BodyTable>
      <template v-slot:thead>
        <tr class="text-center">
          <th>Laporan</th>
          <th>Tanggal</th>
          <th>Bukti Kegiatan</th>
          <th>Deskripsi Kegiatan</th>
          <th>Status</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="(data, index) in lis_data" :key="index">
          <td>{{ data.judul }}</td>
          <td>{{ data.tanggal }}</td>
          <td class="text-center">
            <a :href="data.file" class="btn btn-secondary" target="_blank">Lihat</a>
          </td>
          <td>{{ data.catatan }}</td>
          <td class="text-center">
            <StatusWarning v-if="data.status == 'MENUNGGU'" />
            <StatusSuccess v-else />
          </td>
        </tr>

        <tr v-for="i in sisa" :key="i">
          <td>Hari ke-{{ lis_data.length ? lis_data.length + i : i}} </td>
          <td class="text-center">-</td>
          <td class="text-center">-</td>
          <td class="text-center">-</td>
          <td class="text-center">
            <StatusMuted />
          </td>
        </tr>
      </template>
    </BodyTable>
  </div>
</template>

<script>
  import MenuHeader from '@/components/menu/Header.vue'
  import Alert from '@/components/alert/Alert.vue'
  import axios from 'axios'
  import BodyTable from '@/components/table/BodyTable.vue'
  import StatusSuccess from '@/components/status/Success.vue'
  import StatusWarning from '@/components/status/Warning.vue'
  import StatusMuted from '@/components/status/Muted.vue'

  export default {
    name: 'LaporanHarian',
    props: ['judul', 'deskripsi', 'mahasiswa', 'warna', 'token'],
    components: {
      Alert,
      BodyTable,
      StatusSuccess,
      StatusWarning,
      StatusMuted,
      MenuHeader
    },
    data() {
      return {
        lis_data: {}
      }
    },
    created() {
      this.getData()
    },  
    methods: {
      async getData() {
        await axios.get(`laporan/harian/mhs/${this.mahasiswa.nim}`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.lis_data = res.data.data
            this.sisa = this.lis_data.length ? 31 - this.lis_data.length : 31;
          })
      },
    }
  }
</script>

<style>

</style>