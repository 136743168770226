<template>
    <h4>Dokumen KKN</h4>
    <hr class="mt-0 mb-3 mb-md-4">

    <div class="alert alert-warning" v-if="mahasiswa.status == 'MENUNGGU'">
        Unduh dokumen berikut untuk selanjutnya diisi dan ditandatangani
    </div>

    <button class="btn btn-secondary" v-if="mahasiswa.status == 'MENUNGGU'">Unduh Dokumen</button>

    <div class="alert alert-warning mt-3" v-if="mahasiswa.status == 'MENUNGGU'">
        Ungah dokumen yang telah ditandatangani
    </div>

    <div class="alert alert-success mt-3" v-else>
        Dokumen KKN anda telah terverifikasi
    </div>

    <form>
        <div class="mb-1">
            <label class="form-label" for="file_1">Dokumen 1 : </label>
            <div class="input-group" v-if="mahasiswa.status == 'MENUNGGU'">
                <input type="file" id="file_1" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_1')">Unggah</button>
            </div>
        </div>
        <a :href="mahasiswa.file_1" target="_blank" class="btn btn-secondary mb-3" v-if="mahasiswa.file_1.length">Lihat Dokumen</a>

        <div class="mb-1">
            <label class="form-label" for="file_2">Dokumen 2 : </label>
            <div class="input-group" v-if="mahasiswa.status == 'MENUNGGU'">
                <input type="file" id="file_2" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_2')">Unggah</button>
            </div>
        </div>
        <a :href="mahasiswa.file_2" target="_blank" class="btn btn-secondary mb-3" v-if="mahasiswa.file_2.length">Lihat Dokumen</a>

        <div class="mb-1">
            <label class="form-label" for="file_3">Dokumen 3 : </label>
            <div class="input-group" v-if="mahasiswa.status == 'MENUNGGU'">
                <input type="file" id="file_3" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_3')">Unggah</button>
            </div>
        </div>
        <a :href="mahasiswa.file_3" target="_blank" class="btn btn-secondary mb-3" v-if="mahasiswa.file_3.length">Lihat Dokumen</a>

        <div class="mb-1">
            <label class="form-label" for="file_4">Dokumen 4 : </label>
            <div class="input-group" v-if="mahasiswa.status == 'MENUNGGU'">
                <input type="file" id="file_4" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_4')">Unggah</button>
            </div>
        </div>
        <a :href="mahasiswa.file_4" target="_blank" class="btn btn-secondary mb-3" v-if="mahasiswa.file_4.length">Lihat Dokumen</a>

        <div class="mb-1">
            <label class="form-label" for="file_5">Dokumen 5 : </label>
            <div class="input-group" v-if="mahasiswa.status == 'MENUNGGU'">
                <input type="file" id="file_5" class="form-control" accept=".pdf" required>
                <button class="btn btn-outline-secondary" type="button" @click.prevent="unggah('file_5')">Unggah</button>
            </div>
        </div>
        <a :href="mahasiswa.file_5" target="_blank" class="btn btn-secondary mb-3" v-if="mahasiswa.file_5.length">Lihat Dokumen</a>

    </form>
</template>

<script>
import axios from 'axios'

export default {
    name: 'FormulirDokumen',
    data() {
        return {
            token: sessionStorage.getItem('token'),
            mahasiswa: {
                status: '',
                file_1: '',
                file_2: '',
                file_3: '',
                file_4: '',
                file_5: '',
            },
        }
    },
    mounted() {
        this.getProfile()
    },
    methods: {
        async getProfile() {
            await axios.get('mahasiswa/profile', {headers: {'Authorization' : `Bearer ${this.token}`}})
                .then( res => {

                    setTimeout(() => {
                        this.mahasiswa = res.data.data
                        this.message = 'Semua isian profil akan dijadikan acuan dalam pengumpulan data, pastikan semua data telah benar'
                    }, 1000);

                })
        },
        unggah(dokumen) {
            let formData = new FormData()
            let file = document.querySelector(`#${dokumen}`)

            formData.append('dokumen', dokumen)
            formData.append('file', file.files[0])

            axios.post('register/mahasiswa', formData, { headers: {'Authorization': `Bearer ${this.token}`} })
                .then( res => {
                    if( res.data.status ) {
                        Swal.fire({
                            icon : 'success',
                            text : 'Dokumen berhasil diunggah',
                            timer: 5000
                        })

                        file.value = ''

                        this.getProfile()
                    }
                })
        }
    }
}
</script>

<style>

</style>