<template>
   <div class="alert alert-info mb-3">
      Pengecekan dan validasi dilakukan pada halaman Pesreta KKN
   </div>

   <div class="table-responsive">
      <table class="table table-bordered table-hover table-vcenter">
         <thead>
            <tr class="text-center">
               <th rowspan="2" style="width: 30px">No</th>
               <th rowspan="2">Mahasiswa</th>
               <th colspan="5">Komponen Penilaian</th>
               <th colspan="2">Nilai Akhir</th>
            </tr>

            <tr class="text-center">
               <th>Pembekalan<br>(10%)</th>
               <th>Program Kerja<br>(5%)</th>
               <th>Pelaksanaan<br>(10%)</th>
               <th>Kinerja<br>(70%)</th>
               <th>Laporan<br>(5%)</th>
               <th>Angka</th>
               <th>Huruf</th>
            </tr>
         </thead>

         <tbody>
            <tr v-for="(data, index) in lis_data" :key="index">
               <td class="text-right">{{ index + 1 }}</td>
               <td>{{ data.nama }}<span class="text-muted"><br>{{ data.nim }}<br>{{ data.prodi }}</span></td>
               <td class="text-center">
                  {{ data.penilaian_pembekalan }}
               </td>
               <td class="text-center">
                  {{ data.penilaian_proker }}
               </td>
               <td class="text-center">
                  {{ data.penilaian_pelaksanaan }}
               </td>
               <td class="text-center">
                  {{ data.penilaian_kinerja }}
               </td>
               <td class="text-center">
                  {{ data.penilaian_laporan }}
               </td>
               <td class="text-center">
                  {{ data.penilaian_angka }}
               </td>
               <td class="text-center">
                  {{ data.penilaian_huruf }}
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</template>

<script>
   export default {
      name: 'DasborDetail4',
      props: ['lis_data'],
   }
</script>