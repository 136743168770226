<template>
  <div v-if="peran == 'LPPM' || peran == 'JURUSAN' || peran == 'ADMIN'">
    <FormulirAdminShow />
  </div>
  <div v-else-if="peran == 'DOSEN'">
    <FormulirDosenShow />
  </div>
  <div v-else>
    <FormulirMahasiswaShow />
  </div>
</template>

<script>
import FormulirMahasiswaShow from '@/views/formulirMahasiswa/ShowView.vue'
import FormulirDosenShow from '@/views/formulirDosen/ShowView.vue'
import FormulirAdminShow from '@/views/formulirAdmin/ShowView.vue'

export default {
  name: 'Formulir',
  components: {
    FormulirMahasiswaShow,
    FormulirDosenShow,
    FormulirAdminShow
  },  
  data() {
    return {
      peran: sessionStorage.getItem('peran')
    }
  }
}
</script>

<style>

</style>