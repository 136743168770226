<template>
  <div class="card">
    <Header judul="Tahap 1" deskripsi="Program Kerja & Survei" warna="bg-azure" />

    <div class="card-body">
      <div class="row">
        <div class="col-md-5">
          <div class="table-responsive">
            <table class="table card-table table-bordered table-hover table-vcenter text-nowrap">
              <thead>
                <tr>
                  <th>validator</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pembimbing</td>
                  <td>{{  proker.verifikasi_dosen }}</td>
                </tr>
                <tr>
                  <td>Program Studi</td>
                  <td>{{  proker.verifikasi_prodi }}</td>
                </tr>
                <tr>
                  <td>LPPM</td>
                  <td>{{  proker.verifikasi_lppm }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-7">
          <form>
            <div class="mb-3">
              <label class="form-label">Penyusunan Program Kerja</label>
              <div v-if="proker.verifikasi_dosen == null">
                <div class="input-group" v-if="proker.status != 'TERVERIFIKASI'">
                  <input type="file" id="file_1" class="form-control" accept=".pdf" required>
                  <button class="btn btn-outline-secondary" type="button" @click.prevent="uploadProker">Unggah</button>
                </div>
                <p class="text-danger mt-1 mb-1" style="font-size: 11px" v-if="proker.status != 'TERVERIFIKASI'">Format File (PDF), Ukuran Maksimal 5 MB</p>
              </div>
              <a :href="proker.file" target="_blank" type="button" class="btn btn-secondary"
                v-if="proker.file != '-'">Lihat Dokumen</a>
              <button class="btn btn-danger ms-2" v-if="proker.status == 'MENUNGGU'" type="button"
                @click="deleteProker">Hapus</button>
            </div>

            <div class="mb-3">
              <label class="form-label">Survei Lokasi KKN</label>
              <div v-if="proker.verifikasi_dosen == null">
                <div class="input-group" v-if="survei.status != 'TERVERIFIKASI'">
                  <input type="file" id="file_2" class="form-control" accept=".jpg, .jpeg, .png" required>
                  <button class="btn btn-outline-secondary" type="button" @click.prevent="uploadSurvei">Unggah</button>
                </div>
                <p class="text-danger mt-1 mb-1" style="font-size: 11px" v-if="survei.status != 'TERVERIFIKASI'">Format File (JPG, JPEG, PNG), Ukuran Maksimal 1 MB</p>
              </div>
              <a :href="survei.file" target="_blank" type="button" class="btn btn-secondary"
                v-if="survei.file != '-'">Lihat Dokumen</a>
              <button class="btn btn-danger ms-2" v-if="survei.status == 'MENUNGGU'" type="button"
                @click="deleteSurvei">Hapus</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import Header from '@/components/menu/Header.vue'
  import Alert from '@/components/alert/Alert.vue'

  export default {
    name: 'Proker',
    components: {
      Header,
      Alert
    },
    data() {
      return {
        token: sessionStorage.getItem('token'),
        proker: {
          status: '-',
          file: '-'
        },
        survei: {
          status: '-',
          file: '-'
        }
      }
    },
    mounted() {
      this.getProker()
      this.getSurvei()
    },
    methods: {
      async getProker() {
        await axios.get(`proker/showMHS`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.proker = res.data.data
          })
      },
      async getSurvei() {
        await axios.get(`survei/showMHS`, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            this.survei = res.data.data
          })
      },
      async uploadProker() {
        let formData = new FormData()
        let file = document.querySelector('#file_1')

        formData.append('file', file.files[0])

        await axios.post(`proker/store`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Dokumen Program Kerja Berhasil Diunggah')
              this.getProker()

              file.value = ''
            }
          })
      },
      async uploadSurvei() {
        let formData = new FormData()
        let file = document.querySelector('#file_2')

        formData.append('file', file.files[0])

        await axios.post(`survei/store`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Bukti Survei Lokasi KKN Berhasil Diunggah')
              this.getSurvei()

              file.value = ''
            }
          })
      },
      async deleteProker() {
        let formData = new FormData()
        formData.append('id', this.proker.id)

        await axios.post(`proker/delete`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Dokumen Program Kerja Berhasil Dihapus')
              this.getProker()
            }
          })
      },
      async deleteSurvei() {
        let formData = new FormData()
        formData.append('id', this.survei.id)

        await axios.post(`survei/delete`, formData, {
            headers: {
              'Authorization': `Bearer ${this.token}`
            }
          })
          .then(res => {
            if (res.data.status) {
              this.$toast.success('Bukti Survei Lokasi KKN Berhasil Dihapus')
              this.getSurvei()
            }
          })
      }
    }
  }
</script>

<style>

</style>