<template>
   <div class="alert alert-info mb-3">
      Pengecekan dan validasi dilakukan pada halaman Pesreta KKN
   </div>

   <div class="table-responsive">
      <table class="table table-bordered table-hover table-vcenter">
         <thead>
            <tr class="text-center">
               <th rowspan="2" style="width: 30px">No</th>
               <th rowspan="2">Mahasiswa</th>
               <th colspan="3">Status</th>
            </tr>

            <tr class="text-center">
               <th style="width: 100px">Formulir</th>
               <th style="width: 100px">Proker</th>
               <th style="width: 100px">Survei</th>
            </tr>
         </thead>

         <tbody>
            <tr v-for="(data, index) in lis_data" :key="index">
               <td class="text-right">{{ index + 1 }}</td>
               <td>{{ data.nama }}<span class="text-muted"><br>{{ data.nim }}<br>{{ data.prodi }}</span></td>
               <td class="text-center">
                  <status-success v-if="data.formulir == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="data.formulir == 'MENUNGGU' && data.file_1 != '-'" />
                  <status-muted v-else />
               </td>
               <td class="text-center">
                  <status-success v-if="data.proker == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="data.proker == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
               <td class="text-center">
                  <status-success v-if="data.survei == 'TERVERIFIKASI'" />
                  <status-warning v-else-if="data.survei == 'MENUNGGU'" />
                  <status-muted v-else />
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</template>

<script>
   export default {
      name: 'DasborDetail1',
      props: ['lis_data'],
   }
</script>