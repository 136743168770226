<template>
    <h4>Profil Mahasiswa</h4>
    <hr class="mt-0 mb-3 mb-md-4">

    <div class="alert alert-warning">
        {{ message }}
    </div>

    <form @submit.prevent="postProfile">
        <div class="row mb-1">
            <label for="nim" class="col-form-label col-xl-3 col-md-4">NIM</label>
            <div class="col-xl-9 col-md-8">
                <input type="text" name="nim" id="nim" class="form-control w-auto" readonly v-model="mahasiswa.nim">
            </div>
        </div>

        <div class="row mb-1">
            <label for="nama" class="col-form-label col-xl-3 col-md-4">Nama Lengkap</label>
            <div class="col-xl-9 col-md-8">
                <input type="text" name="nama" id="nama" class="form-control" readonly v-model="mahasiswa.nama">
            </div>
        </div>

        <div class="row mb-1">
            <label for="tempat_lahir" class="col-form-label col-xl-3 col-md-4">Tempat Lahir</label>
            <div class="col-xl-9 col-md-8">
                <input type="text" name="tempat_lahir" id="tempat_lahir" class="form-control" required v-model="mahasiswa.tempat_lahir">
            </div>
        </div>

        <div class="row mb-1">
            <label for="tanggal_lahir" class="col-form-label col-xl-3 col-md-4">Tanggal Lahir</label>
            <div class="col-xl-9 col-md-8">
                <input type="date" name="tanggal_lahir" id="tanggal_lahir" class="form-control" required v-model="mahasiswa.tanggal_lahir">
            </div>
        </div>

        <div class="row mb-1">
            <label for="jenis_kelamin" class="col-form-label col-xl-3 col-md-4">Jenis Kelamin</label>
            <div class="col-xl-9 col-md-8">
                <select id="jenis_kelamin" class="form-select" v-model="mahasiswa.jenis_kelamin">
                    <option v-for="(kelamin, index) of lis_kelamin" :key="index">{{ kelamin }}</option>
                </select>
            </div>
        </div>

        <div class="row mb-1">
            <label for="prodi" class="col-form-label col-xl-3 col-md-4">Program Studi</label>
            <div class="col-xl-9 col-md-8">
                <input type="text" id="prodi" class="form-control" readonly v-model="mahasiswa.program_studi">
            </div>
        </div>
        
        <div class="row mb-1">
            <label for="ipk" class="col-form-label col-xl-3 col-md-4">IPK</label>
            <div class="col-xl-9 col-md-8">
                <input type="text" name="ipk" id="ipk" class="form-control w-auto" readonly v-model="mahasiswa.ipk">
            </div>
        </div>

        <div class="row mb-1">
            <label for="no_hp" class="col-form-label col-xl-3 col-md-4">No. HP / WA</label>
            <div class="col-xl-9 col-md-8">
                <input type="text" name="no_hp" id="no_hp" class="form-control" v-model="mahasiswa.no_hp">
            </div>
        </div>

        <div class="row mb-1">
            <label for="email" class="col-form-label col-xl-3 col-md-4">Email</label>
            <div class="col-xl-9 col-md-8">
                <input type="email" id="email" class="form-control" readonly v-model="mahasiswa.email">
            </div>
        </div>

        <div class="row mb-1">
            <label for="alamat" class="col-form-label col-xl-3 col-md-4">Alamat</label>
            <div class="col-xl-9 col-md-8">
                <textarea id="alamat" rows="3" class="form-control" v-model="mahasiswa.alamat"></textarea>
            </div>
        </div>

        <div class="row mb-1">
            <label for="ukuran_kameja" class="col-form-label col-xl-3 col-md-4">Ukuran Kameja</label>
            <div class="col-xl-9 col-md-8">
                <select name="ukuran_kameja" id="ukuran_kameja" class="form-select" v-model="mahasiswa.ukuran_kameja">
                    <option v-for="(ukuran, index) of lis_ukuran" :key="index">{{ ukuran }}</option>
                </select>
            </div>
        </div>

        <div class="row mb-3">
            <label for="sks" class="col-form-label col-xl-3 col-md-4">Jumlah SKS</label>
            <div class="col-xl-9 col-md-8">
                <input type="text" name="sks" id="sks" class="form-control w-auto" readonly v-model="mahasiswa.sks">
            </div>
        </div>

        <div class="row mb-1">
            <div class="col-xl-3 col-md-4"></div>
            <div class="col-xl-9 col-md-8">
                <input type="submit" value="Simpan" class="btn btn-primary">
            </div>
        </div>
    </form>
</template>

<script>
import axios from 'axios'

export default {
    name: 'FormulirProfil',
    data() {
        return {
            lis_agama  : ['Islam', 'Kristen'],
            lis_kelamin: ['L', 'P'],
            lis_ukuran : ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
            token      : sessionStorage.getItem('token'),
            message    : 'Tunggu sampai semua data berhasil diambil',
            mahasiswa  : {
                nim          : '',
                nama         : '',
                tempat_lahir : '',
                tanggal_lahir: '',
                agama        : '',
                jenis_kelamin: '',
                program_studi: '',
                semester     : '',
                ipk          : '',
                no_hp        : '',
                email        : '',
                alamat       : '',
                ukuran_kameja: '',
                sks          : '',
            },
        }
    },
    mounted() {
        this.getProfile()
    },
    methods: {
        async getProfile() {
            await axios.get('mahasiswa/profile', {headers: {'Authorization' : `Bearer ${this.token}`}})
                .then( res => {

                    setTimeout(() => {
                        this.mahasiswa = res.data.data
                        this.message = 'Semua isian profil akan dijadikan acuan dalam pengumpulan data, pastikan semua data telah benar'
                    }, 1000);

                })
        },
        postProfile() {
            let formData = new FormData()
            
            formData.append('tempat_lahir', this.mahasiswa.tempat_lahir)
            formData.append('tanggal_lahir', this.mahasiswa.tanggal_lahir)
            formData.append('agama', this.mahasiswa.agama)
            formData.append('jenis_kelamin', this.mahasiswa.jenis_kelamin)
            formData.append('no_hp', this.mahasiswa.no_hp)
            formData.append('alamat', this.mahasiswa.alamat)
            formData.append('ukuran_kameja', this.mahasiswa.ukuran_kameja)

            axios.post('mahasiswa/profile/update', formData, {headers: {'Authorization' : `Bearer ${this.token}`}})
                .then(res=> {
                    if( res.data.status ) {
                        Swal.fire({
                            icon: 'success',
                            text: 'Profil mahasiswa berhasil disimpan',
                            timer: 5000
                        })
                    }
                })
        }
    }
}
</script>

<style>

</style>