<template>
    <div class="page page-center">
        <div class="container-tight py-4">
            <div class="text-center">
                <img src="../assets/logo-long-dark.png" alt="KKN ITG" class="mb-4" style="height: 45px" />
            </div>

            <form class="card card-md" action="." method="get" autocomplete="off">
                <div class="card-body">
                    <h2 class="card-title text-center mb-4">Masuk Ke KKN Tematik ITG</h2>
                    <div class="mb-3">
                        <label class="form-label">Username</label>
                        <input type="email" class="form-control" placeholder="Masukan Username" required autofocus
                            v-model="email">
                    </div>
                    <div class="mb-2">
                        <label class="form-label">Password</label>
                        <div class="input-group input-group-flat">
                            <input type="password" class="form-control" placeholder="Masukan Password" required
                                v-model="password">
                        </div>
                    </div>
                    <div class="form-footer">
                        <button type="submit" class="btn btn-primary w-100" @click.prevent="login">Masuk</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'Login',
        data() {
            return {
                email: '',
                password: ''
            }
        },
        methods: {
            async login() {
                let formData = new FormData()

                formData.append('email', this.email)
                formData.append('password', this.password)

                await axios.post('login', formData)
                    .then(res => {
                        const data = res.data

                        if (data.status == 'success') {

                            sessionStorage.setItem("isLogin", 'true')
                            sessionStorage.setItem("token", data.content.access_token)

                            axios.get('auth/user', {
                                    headers: {
                                        'Authorization': `Bearer ${data.content.access_token}`
                                    }
                                })
                                .then(res => {
                                    const user = res.data
                                    sessionStorage.setItem("peran", user.peran)

                                    if (user.foto != '-') {
                                        sessionStorage.setItem("foto", `https://apikkn.itg.ac.id/${user.foto}`)
                                    } else {
                                        sessionStorage.setItem("foto", `../no-pp.png`)
                                    }

                                    if (user.peran == 'DOSEN') {
                                        axios.get('dosen/profile', {
                                                headers: {
                                                    'Authorization': `Bearer ${data.content.access_token}`
                                                }
                                            })
                                            .then(res => {
                                                sessionStorage.setItem("nama", res.data.data.nama)
                                            })
                                    } else if (user.peran == 'MAHASISWA') {
                                        axios.get('mahasiswa/profile', {
                                                headers: {
                                                    'Authorization': `Bearer ${data.content.access_token}`
                                                }
                                            })
                                            .then(res => {
                                                sessionStorage.setItem("nama", res.data.data.nama)
                                            })
                                    } else if (user.peran == 'ADMIN') {
                                        sessionStorage.setItem('nama', user.name)
                                        
                                        if( JSON.parse(user.akses).includes('ALL') ) {
                                            sessionStorage.setItem("peran", 'ADMIN')
                                        } else if( JSON.parse(user.akses).includes('KKN_BAK') ) {
                                            sessionStorage.setItem("peran", 'BAK')
                                        } else if( JSON.parse(user.akses).includes('KKN_LPPM') ) {
                                            sessionStorage.setItem("peran", 'LPPM')
                                        } else if( JSON.parse(user.akses).includes('KKN_PRODI') ) {
                                            sessionStorage.setItem("peran", 'PRODI')
                                        } else if( JSON.parse(user.akses).includes('KKN_JURUSAN') ) {
                                            sessionStorage.setItem("peran", 'JURUSAN')
                                            axios.get('jurusan/profile', {
                                                headers: {
                                                    'Authorization': `Bearer ${data.content.access_token}`
                                                }
                                            })
                                            .then(res => {
                                                sessionStorage.setItem("nama", res.data.data.nama)
                                            })
                                        } else {
                                            sessionStorage.setItem("peran", '-')
                                        }
                                    }
                                })

                            this.$toast.success('Selamat datang!')

                            setTimeout(() => {
                                window.location.reload()
                            }, 2000);
                        }
                    })
                    .catch(err => {
                        this.$toast.error('Username / Password Salah!')
                    })
            }
        }
    }
</script>

<style>
    .container-login {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 30px;
        background: rgb(45, 111, 214);
        background: linear-gradient(180deg, rgba(45, 111, 214, 1) 0%, rgba(19, 113, 210, 1) 100%);
    }

    .box-login {
        width: 100%;
        max-width: 350px;
        background-color: #fff;
        border-radius: 10px !important;
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    }

    img {
        height: 60px;
        margin-right: 10px;
    }
</style>