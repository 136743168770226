<template>
   <div class="card">
      <menu-header warna="bg-blue" judul="Profil" deskripsi="Data Dasar Dosen" />

      <div class="card-body">

         <Alert status="alert-secondary">
            {{ message }}
         </Alert>

         <form @submit.prevent="postProfile">
            <div class="row mb-1">
               <label for="nim" class="col-form-label col-xl-3 col-md-4">NIP/NIDN</label>
               <div class="col-xl-9 col-md-8">
                  <input type="text" class="form-control w-auto" v-model="dosen.nidn" disabled>
               </div>
            </div>

            <div class="row mb-1">
               <label for="nama" class="col-form-label col-xl-3 col-md-4">Nama Lengkap</label>
               <div class="col-xl-9 col-md-8">
                  <input type="text" class="form-control" v-model="dosen.nama" required disabled>
               </div>
            </div>

            <div class="row mb-1">
               <label for="tempat_lahir" class="col-form-label col-xl-3 col-md-4">Tempat Lahir</label>
               <div class="col-xl-9 col-md-8">
                  <input type="text" class="form-control" required v-model="dosen.tempat_lahir" disabled>
               </div>
            </div>

            <div class="row mb-1">
               <label for="tanggal_lahir" class="col-form-label col-xl-3 col-md-4">Tanggal Lahir</label>
               <div class="col-xl-9 col-md-8">
                  <input type="date" class="form-control" required v-model="dosen.tanggal_lahir" disabled>
               </div>
            </div>

            <div class="row mb-1">
               <label for="jenis_kelamin" class="col-form-label col-xl-3 col-md-4">Jenis Kelamin</label>
               <div class="col-xl-9 col-md-8">
                  <input type="text" class="form-control" required v-model="dosen.jenis_kelamin" disabled>
               </div>
            </div>

            <div class="row mb-1">
               <label for="jabatan_fungsional" class="col-form-label col-xl-3 col-md-4">Jabatan Fungsional</label>
               <div class="col-xl-9 col-md-8">
                  <input type="text" class="form-control" readonly v-model="dosen.jabatan_akademik" disabled>
               </div>
            </div>
         </form>
      </div>
   </div>
</template>

<script>
   import axios from 'axios'
   import Alert from '@/components/alert/Alert.vue'

   export default {
      name: "DosenFormulirProfil",
      components: {
         Alert
      },
      data() {
         return {
            lis_agama  : ["Islam", "Kristen"],
            lis_kelamin: ["Laki-laki", "Perempuan"],
            lis_ukuran : ["S", "M", "L", "XL", "XXL", "XXXL"],
            lis_jabatan: ['Asisten Ahli', 'Lektor', 'Lektor Kepala', 'Guru Besar'],
            token      : sessionStorage.getItem("token"),
            message    : "Tunggu sampai data berhasil di load",
            dosen      : {
               nidn              : "",
               nama              : "-",
               tempat_lahir      : "-",
               tanggal_lahir     : "-",
               jenis_kelamin     : "-",
               program_studi     : "-",
               no_hp             : "-",
               email             : "-",
               jabatan_fungsional: "",
               jabatan_struktural: "-",
               alamat            : "",
               kode_prodi        : "",
               status            : "",
            },
         };
      },
      mounted() {
         this.getProfile();
      },
      methods: {
         async getProfile() {
            await axios.get("dmDosen/profile", {
                  headers: {
                     "Authorization": `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  setTimeout(() => {
                     this.dosen = res.data.data
                     this.dosen.jenis_kelamin = this.dosen.jenis_kelamin == 'L' ? 'Laki-laki' : 'Perempuan'

                     this.message =
                        "Data yang ditampilkan sesuai isian profil pada aplikasi AISnet";
                  }, 1000);
               });
         },
         postProfile() {
            let formData = new FormData()

            Object.keys(this.dosen).forEach(key => {
               formData.append(key, this.dosen[key])
            })

            axios.post("dosen/profile/update", formData, {
                  headers: {
                     "Authorization": `Bearer ${this.token}`
                  }
               })
               .then(res => {
                  if (res.data.status) {
                     this.$toast.success('Perubahan profil berhasil disimpan')
                  }
               })
               .catch(err => {
                  this.$toast.error('Perubahan profil gagal disimpan')

                  console.log( err.message )
               })
         }
      },
   }
</script>

<style>

</style>