<template>
  <AdminPeserta v-if="peran == 'LPPM' || peran == 'ADMIN' || peran == 'JURUSAN' || peran == 'PRODI'" />
  <DosenPeserta v-else-if="peran == 'DOSEN'" />
</template>

<script>
import AdminPeserta from './admin/PesertaView.vue'
import DosenPeserta from './dosen/PesertaView.vue'

export default {
    name: "Peserta",
    components: { 
      AdminPeserta,
      DosenPeserta
    },
    data() {
        return {
            peran: sessionStorage.getItem("peran")
        };
    },
}
</script>

<style>

</style>